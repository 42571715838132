import React from "react";
import { useSelector } from "react-redux";
import { Empty, PageBannerMessage, PageBannerMessageContainer, PageBannerMessageProps } from "../../ui-library";
import { useTranslation } from "react-i18next";

import { selectActiveNotifications } from "../../state/tree/notification/selectors";
import {
    actions as notificationActions,
    ActiveDestinationNotification,
    CommonNotification,
    GenericNotification,
    MyItemsPlacesNotification,
    MyItemsRoutesNotification,
    PreDefinedNotification,
    ReachableRangeNotification
} from "../../state/tree/notification/reducers";
import { navigateToMyPlaces, navigateToMyRoutes } from "../../state/tree/navigation/thunks";
import {
    composeActiveDestinationHeading,
    composeActiveDestinationMessage,
    composePlaceHeading,
    composePlaceMessage,
    composeRouteHeading,
    composeRouteMessage,
    notificationImage
} from "./PageNotificationsHelper";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { useAppDispatch } from "../../state/RootReducer";
import { selectRouteSyncSupport } from "../../state/tree/global-configuration/selectors";

const createKey = (notification: CommonNotification) =>
    `notification-${notification.notificationType}-${notification.timestamp}`;

const PageNotificationsCmp = () => {
    const notifications = useSelector(selectActiveNotifications);
    const dispatch = useAppDispatch();
    const routeSync = useSelector(selectRouteSyncSupport);
    const { t } = useTranslation("PageNotifications");
    const myItemsPlacesBannerValues = (notification: MyItemsPlacesNotification): PageBannerMessageProps => {
        const bannerAsLink = ["place-add", "place-update"].includes(notification.notificationType);
        return {
            qaIdModifier: notification.notificationType,
            image: notificationImage(notification.notificationType),
            heading: composePlaceHeading(notification, t),
            message: composePlaceMessage(notification, t),
            onHidden: () => {
                dispatch(notificationActions.removeMyItemsPlacesNotification(notification));
            },
            onBannerClicked: () => {
                bannerAsLink && dispatch(navigateToMyPlaces());
            },
            hideOnClick: bannerAsLink
        };
    };
    const myItemsRoutesBannerValues = (notification: MyItemsRoutesNotification) => {
        const bannerAsLink = ["route-add", "route-synced"].includes(notification.notificationType);
        return {
            qaIdModifier: notification.notificationType,
            image: notificationImage(notification.notificationType),
            heading: composeRouteHeading(notification, t),
            message: composeRouteMessage(notification, t),
            onHidden: () => {
                dispatch(notificationActions.removeMyItemsRoutesNotification(notification));
            },
            onBannerClicked: () => {
                bannerAsLink &&
                    (notification.notificationType == "route-synced"
                        ? window.open(routeSync.helpURL, "_blank")
                        : dispatch(navigateToMyRoutes()));
            },
            hideOnClick: bannerAsLink
        };
    };
    const activeDestinationBannerValues = (notification: ActiveDestinationNotification) => {
        const bannerAsLink = ["activeDestination-add"].includes(notification.notificationType);
        return {
            qaIdModifier: notification.notificationType,
            image: notificationImage(notification.notificationType),
            heading: composeActiveDestinationHeading(notification, t),
            message: composeActiveDestinationMessage(notification, t),
            onHidden: () => {
                dispatch(notificationActions.removeActiveDestinationNotification(notification));
            },
            onBannerClicked: () => {
                bannerAsLink && window.open(routeSync.helpURL, "_blank");
            },
            hideOnClick: bannerAsLink
        };
    };
    const generalBannerValues = (notification: GenericNotification) => {
        return {
            qaIdModifier: notification.notificationType,
            image: notificationImage(notification.notificationType),
            heading: notification.heading,
            message: notification.message,
            onHidden: () => {
                dispatch(notificationActions.removeGenericNotification(notification));
            }
        };
    };

    const connectionFailureBannerValues = (notification: PreDefinedNotification) => {
        return {
            qaIdModifier: notification.notificationType,
            image: notificationImage(notification.notificationType),
            heading: t("PageNotifications:connection_failure.heading"),
            message: t("PageNotifications:connection_failure.message"),
            onHidden: () => {
                dispatch(notificationActions.removePreDefinedNotification(notification));
            }
        };
    };

    const detailedRangeUncalculableBannerValues = (notification: ReachableRangeNotification) => {
        return {
            qaIdModifier: notification.notificationType,
            image: notificationImage(notification.notificationType),
            heading: t("PageNotifications:detailed_range_uncalculable.heading"),
            message: t("PageNotifications:detailed_range_uncalculable.message"),
            onHidden: () => {
                dispatch(notificationActions.removeReachableRangeNotification(notification));
            }
        };
    };

    const unknownLocationBannerValues = (notification: PreDefinedNotification) => {
        return {
            qaIdModifier: notification.notificationType,
            image: notificationImage(notification.notificationType),
            heading: t("PageNotifications:unknown_location.heading"),
            message: t("PageNotifications:unknown_location.message"),
            onHidden: () => {
                dispatch(notificationActions.removePreDefinedNotification(notification));
            }
        };
    };

    return (
        notifications?.length > 0 && (
            <PageBannerMessageContainer>
                {notifications?.map((notification) => {
                    let pageBannerValues: PageBannerMessageProps;
                    switch (notification.notificationType) {
                        case "place-add":
                        case "place-update":
                        case "place-remove": {
                            pageBannerValues = myItemsPlacesBannerValues(notification as MyItemsPlacesNotification);
                            break;
                        }
                        case "route-add":
                        case "route-update":
                        case "route-removed":
                        case "route-published":
                        case "route-un-published":
                        case "route-synced":
                        case "route-un-synced": {
                            pageBannerValues = myItemsRoutesBannerValues(notification as MyItemsRoutesNotification);
                            break;
                        }
                        case "activeDestination-add":
                        case "activeDestination-remove":
                            pageBannerValues = activeDestinationBannerValues(
                                notification as ActiveDestinationNotification
                            );
                            break;
                        case "connection-failure":
                            pageBannerValues = connectionFailureBannerValues(notification as PreDefinedNotification);
                            break;
                        case "unknown-location":
                            pageBannerValues = unknownLocationBannerValues(notification as PreDefinedNotification);
                            break;
                        case "generic-error":
                        case "generic-message":
                            pageBannerValues = generalBannerValues(notification as GenericNotification);
                            break;
                        case "detailed-range-uncalculable":
                            pageBannerValues = detailedRangeUncalculableBannerValues(
                                notification as ReachableRangeNotification
                            );
                            break;
                    }
                    return <PageBannerMessage key={createKey(notification)} {...pageBannerValues} />;
                })}
            </PageBannerMessageContainer>
        )
    );
};

const PageNotifications = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <PageNotificationsCmp />
    </IsomorphicSuspense>
);

export default PageNotifications;
