import React from "react";
import { bem, Empty, qaId } from "../../ui-library";
import { LngLat } from "maplibre-gl";

import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import { ReactComponent as IcEVChargerW } from "../../../resources/icons/poi-category-24/ic-ev-charger-w.svg";
import { ReactComponent as IcFuelW } from "../../../resources/icons/poi-category-24/ic-fuel-w.svg";
import { ReactComponent as IcHotelMotelW } from "../../../resources/icons/poi-category-24/ic-hotel-motel-w.svg";
import { ReactComponent as IcRestaurantW } from "../../../resources/icons/poi-category-24/ic-restaurant-w.svg";
import { TTMSearchResult } from "../../utils/locationTypes";
import { ResultType } from "../../services/ngs/ngsAPITypes";

export const categoryShortcutPopup = bem("category-shortcut-popup");
const categoryShortcutQa = qaId("experiment-popup-main-container");

type Props = {
    handleMouseClickCategory: (category: TTMSearchResult, lnglat: LngLat) => void;
    handleMouseClickPopup: () => void;
    lnglat: LngLat;
};

const CategoryShortcutPopupCmp = ({ handleMouseClickCategory, handleMouseClickPopup, lnglat }: Props) => {
    const createCategorySegment = (id: string, value: string): TTMSearchResult =>
        ({
            externalID: id,
            name: value,
            ngsType: ResultType.CATEGORY
        } as TTMSearchResult);
    return (
        <div className={categoryShortcutPopup.block()} onClick={handleMouseClickPopup} {...categoryShortcutQa.block()}>
            <div
                className={categoryShortcutPopup.element("restaurant")}
                onClick={() => {
                    const categoryName = "restaurant";
                    return handleMouseClickCategory(createCategorySegment("7315", categoryName), lnglat);
                }}
            >
                <IcRestaurantW />
            </div>
            <div
                className={categoryShortcutPopup.element("gas-station")}
                onClick={() => {
                    const categoryName = "petrol station";
                    return handleMouseClickCategory(createCategorySegment("7311", categoryName), lnglat);
                }}
            >
                <IcFuelW />
            </div>
            <div
                className={categoryShortcutPopup.element("hotel")}
                onClick={() => {
                    const categoryName = "hotel/motel";
                    return handleMouseClickCategory(createCategorySegment("7314", categoryName), lnglat);
                }}
            >
                <IcHotelMotelW />
            </div>
            <div
                className={categoryShortcutPopup.element("charging-station")}
                onClick={() => {
                    const categoryName = "electric vehicle station";
                    return handleMouseClickCategory(createCategorySegment("7309", categoryName), lnglat);
                }}
            >
                <IcEVChargerW />
            </div>
        </div>
    );
};

const CategoryShortcutPopup = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <CategoryShortcutPopupCmp {...props} />
    </IsomorphicSuspense>
);

export default CategoryShortcutPopup;
