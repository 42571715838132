import React, { HTMLAttributes, useState } from "react";
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableHandle,
    SortOver,
    SortStart
} from "react-sortable-hoc";
import classNames from "classnames";
import {
    bem,
    ButtonContainer,
    Empty,
    IcDelete24,
    IcDrag16,
    IcFinish16,
    IcSearch16,
    IcStart16,
    InputControl,
    qaId
} from "../../ui-library";
import { useTranslation } from "react-i18next";

import { TTMWaypoint } from "../../utils/locationTypes";
import { buildLocationInfoTitle } from "../../utils/location";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import PositionBullet from "../map-page/PositionBullet";

import "./PlannerGeoInputs.scss";

const sortableInputs = bem("sortable-inputs");
const sortableInputsSortableElement = bem("sortable-inputs__sortable-element");
const sortableInputsDragHandleContainer = bem("sortable-inputs__drag-handle-container");
export const currentLocationText = "Current location";

type DragHandleProps = HTMLAttributes<HTMLDivElement> & {
    position: number;
    itemsLength: number;
    isBeingDragged?: boolean;
};

const DragHandle = SortableHandle<DragHandleProps>(
    ({ position, itemsLength, isBeingDragged, className, ...rest }: DragHandleProps) => {
        let positionContent = position as React.JSX.Element | number;
        if (position === 0) {
            positionContent = <IcStart16 />;
        } else if (position === itemsLength - 1) {
            positionContent = <IcFinish16 />;
        }

        return (
            <div
                className={classNames(
                    sortableInputsDragHandleContainer.block(),
                    className,
                    isBeingDragged && sortableInputsDragHandleContainer.modifier("dragging")
                )}
                {...rest}
            >
                <IcDrag16 className={classNames(sortableInputs.element("drag-icon"))} />
                <IcSearch16 className={classNames(sortableInputs.element("search-icon"))} />
                <PositionBullet>{positionContent}</PositionBullet>
            </div>
        );
    }
);

export type DraggingInput = Pick<SortStart, "node" | "index">;
export type DraggingOverInput = Pick<SortOver, "index" | "oldIndex" | "newIndex">;

type CommonProps = {
    isHovered?: boolean;
    isHighlighted?: boolean;
    isDragging?: DraggingInput;
    isDraggingOver?: DraggingOverInput;
};

type ListItemProps = CommonProps & {
    waypoint: TTMWaypoint;
    inputIndex: number;
    itemsLength: number;
    onHoverItem: (index: number, hovered: boolean) => void;
    onFocusItem: (value: string, index?: number) => void;
    nonPlaceholderItemsLength: number;
    onDeleteItem: () => void;
    onChangeItem: (value: string) => void;
};

const SortableItem = SortableElement<ListItemProps>(
    ({
        waypoint,
        onHoverItem,
        onFocusItem,
        onDeleteItem,
        onChangeItem,
        inputIndex,
        itemsLength,
        nonPlaceholderItemsLength,
        isHovered,
        isHighlighted,
        isDragging,
        isDraggingOver
    }: ListItemProps) => {
        const waypointsQa = qaId(`waypoint-${inputIndex}`);
        const [hovered, setHovered] = useState(false);
        const { t } = useTranslation("PlannerGeoInputs");
        // applying programmatic hovering if applicable:
        if (hovered !== isHovered) {
            setHovered(isHovered);
        }
        const [highlighted, setHighlighted] = useState(false);
        // applying programmatic highlighting if applicable:
        if (highlighted !== isHighlighted) {
            setHighlighted(isHighlighted);
        }
        const [focused, setFocused] = useState(false);

        // boolean flag if current item is being dragged
        const isItemBeingDragged = Boolean(isDragging) && isDragging.index === inputIndex;

        // dragging over logic to display correct position on bullets
        if (isDraggingOver && inputIndex !== isDraggingOver.index) {
            if (isDraggingOver.newIndex >= inputIndex && isDraggingOver.index < inputIndex) {
                inputIndex = inputIndex - 1;
            } else if (isDraggingOver.newIndex <= inputIndex && isDraggingOver.index > inputIndex) {
                inputIndex = inputIndex + 1;
            }
        }

        const handleHoverInput = (index: number, hovered: boolean) => {
            onHoverItem(index, hovered);
            setHovered(hovered);
        };

        const handleFocusInput = (target: EventTarget & HTMLInputElement, index: number) => {
            target.select();
            onFocusItem(target.value, index);
            setFocused(true);
        };

        const buildTitle = (waypoint?: TTMWaypoint): string =>
            waypoint?.isCurrentLocation ? currentLocationText : buildLocationInfoTitle(waypoint?.locationInfo);

        let placeholderText = "";
        if (!waypoint && (inputIndex <= 1 || inputIndex == itemsLength - 1)) {
            placeholderText = t(`PlannerGeoInputs:${inputIndex === 0 ? "placeholder_from" : "placeholder_to"}`);
        }

        return (
            <li
                className={classNames(
                    sortableInputsSortableElement.block(),
                    !isDragging && highlighted && sortableInputsSortableElement.modifier("highlighted"),
                    !isDragging && hovered && sortableInputsSortableElement.modifier("hovered"),
                    !isDragging && focused && sortableInputsSortableElement.modifier("focused")
                )}
                onMouseOver={() => handleHoverInput(inputIndex, true)}
                onMouseLeave={() => handleHoverInput(inputIndex, false)}
                {...waypointsQa.block()}
            >
                <div className={sortableInputs.element("highlight-wrapper")}>
                    <DragHandle
                        position={inputIndex}
                        itemsLength={itemsLength}
                        isBeingDragged={isItemBeingDragged}
                        {...waypointsQa.element("drag")}
                    />
                    <InputControl
                        // Watch out for PlannerGeoInputs.scss reference to this ID
                        id={waypointsQa.blockValue()}
                        defaultValue={buildTitle(waypoint)}
                        placeholder={placeholderText}
                        onFocus={(ev) => handleFocusInput(ev.currentTarget, inputIndex)}
                        onChange={(ev) => onChangeItem(ev.currentTarget.value)}
                        autoComplete="off"
                    />
                    {/* if there are 2 or more filled waypoints, you can delete the placeholder */}
                    {(waypoint || nonPlaceholderItemsLength >= 2) && (
                        <ButtonContainer
                            className={sortableInputs.element("delete-btn")}
                            onClick={onDeleteItem}
                            {...waypointsQa.element("remove")}
                        >
                            <IcDelete24 />
                        </ButtonContainer>
                    )}
                </div>
            </li>
        );
    }
);

type ContainerProps = CommonProps & {
    waypoints: TTMWaypoint[];
    hoveredItemIndex: number;
    highlightedItemIndex: number;
    nonPlaceholderItemsLength: number;
    onHoverItem?: (index: number, hovered: boolean) => void;
    onFocusItem?: (value: string, index?: number) => void;
    onBlurItem?: () => void;
    onDeleteItem: (index: number) => void;
    onChangeItem: (value: string) => void;
};

const EmptyWrapper: React.FC<{ children?: React.ReactNode }> = (props: { children?: React.ReactNode }) => (
    <ul className={classNames(sortableInputs.block(), "list-group")}>{props.children}</ul>
);

// NOTE: Planner.tsx (focusWaypointInput) depends on this underlying html structure to programmatically focus on waypoint input boxes:
const PlannerGeoInputsContainer = SortableContainer(
    ({
        waypoints,
        hoveredItemIndex,
        highlightedItemIndex,
        nonPlaceholderItemsLength,
        onHoverItem,
        onFocusItem,
        onDeleteItem,
        onChangeItem,
        isDragging,
        isDraggingOver
    }: ContainerProps) => (
        <EmptyWrapper>
            {waypoints.map((waypoint, index) => (
                <SortableItem
                    key={`${waypoint?.locationInfo?.externalID}_${index + 1}`}
                    index={index}
                    waypoint={waypoint}
                    inputIndex={index}
                    itemsLength={waypoints.length}
                    nonPlaceholderItemsLength={nonPlaceholderItemsLength}
                    isHovered={index === hoveredItemIndex}
                    isDragging={isDragging}
                    isDraggingOver={isDraggingOver}
                    onHoverItem={onHoverItem}
                    onFocusItem={onFocusItem}
                    onDeleteItem={() => onDeleteItem(index)}
                    onChangeItem={onChangeItem}
                    isHighlighted={index === highlightedItemIndex}
                />
            ))}
        </EmptyWrapper>
    )
);

const PlannerGeoInputsCmp = (props: ContainerProps & SortableContainerProps) => {
    // preload localization strings earlier than "sortable" components initialize
    useTranslation("PlannerGeoInputs");

    return (
        <IsomorphicSuspense fallback={<EmptyWrapper />}>
            <PlannerGeoInputsContainer {...props} />
        </IsomorphicSuspense>
    );
};

const PlannerGeoInputs = (props: ContainerProps & SortableContainerProps) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <PlannerGeoInputsCmp {...props} />
    </IsomorphicSuspense>
);

export default PlannerGeoInputs;
