import React from "react";
import { Accent, bem, Empty, IcTraffic16, qaId, TextWithIcon } from "../../ui-library";
import { UnitsType } from "@anw/gor-sdk";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { formatDuration, formatMetersWithUnitsType } from "../../utils/units";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

export const routePopupStyle = bem("route-popup");

type Props = {
    lengthInMeters: number;
    durationInSeconds: number;
    routeIndex: number;
    trafficDelayInSeconds: number;
    unitsType: UnitsType;
    clickHandler: (index: number) => void;
};

const RoutePopupCmp = ({
    lengthInMeters,
    durationInSeconds,
    routeIndex,
    trafficDelayInSeconds,
    unitsType,
    clickHandler
}: Props) => {
    const routePopupQa = qaId(`route-popup-${routeIndex}`);
    const formattedDelay = formatDuration(trafficDelayInSeconds);
    const { t } = useTranslation("RoutePopup");
    return (
        <div
            {...routePopupQa.block()}
            className={classNames(routePopupStyle.element("wrapper"))}
            onClick={() => clickHandler(routeIndex)}
        >
            <div {...routePopupQa.element("details")} className={routePopupStyle.element("details")}>
                <Accent size="s" className={routePopupStyle.element("detail-entry")}>
                    <ul>
                        <li {...routePopupQa.element("duration")} className={routePopupStyle.element("duration")}>
                            {formatDuration(durationInSeconds)}
                        </li>
                        <li {...routePopupQa.element("distance")} className={routePopupStyle.element("distance")}>
                            {formatMetersWithUnitsType(lengthInMeters, unitsType)}
                        </li>
                    </ul>
                </Accent>
                <Accent size="s" {...routePopupQa.element("delay")}>
                    {formattedDelay ? (
                        <TextWithIcon
                            className={classNames(routePopupStyle.element("delay"))}
                            icon={<IcTraffic16 />}
                            text={formattedDelay}
                        />
                    ) : (
                        <span className={classNames(routePopupStyle.element("no-delay"))}>
                            {t("RoutePopup:no_delays")}
                        </span>
                    )}
                </Accent>
            </div>
        </div>
    );
};

const RoutePopup = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <RoutePopupCmp {...props} />
    </IsomorphicSuspense>
);

export default RoutePopup;
