import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { bem, Body, Empty, qaId, Title } from "../../ui-library";
import { ChargingInformation, ChargingParkPaymentOption, ChargingConnection } from "@anw/gor-sdk";

import { formatDuration } from "../../utils/units";
import { ReactComponent as IcEVChargerW } from "../../../resources/icons/poi-category-24/ic-ev-charger-w.svg";
import { getMSPName } from "../../constants/EvConstants";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { chargeInKWhToPercentage } from "../../utils/chargingInformation";

export const chargingPopup = bem("charging-popup");
const chargingPopupQa = qaId("charging-popup");

type Props = {
    details: ChargingInformation;
    handleMouseEnter?: () => void;
    handleMouseLeave?: () => void;
    remainingChargeAtArrivalInkWh?: number;
    maxChargeInkWh?: number;
};

const formatName = (name: string) => name.replace("charging", "");

const BasicDetails = (props: {
    chargingParkName: string;
    chargingParkUuid: string;
    targetChargeInkWh: number;
    chargingTime: string;
    remainingChargeAtArrivalInkWh?: number;
    maxChargeInkWh?: number;
}) => {
    const { t } = useTranslation("ChargingDetailsPopup");
    return (
        <>
            <ul className={chargingPopup.element("list")}>
                <li>
                    {t("park_name")}: {props.chargingParkName}
                </li>
                <li>
                    {t("remaining_charge_on_arrival")}:{" "}
                    {chargeInKWhToPercentage(props.remainingChargeAtArrivalInkWh, props.maxChargeInkWh)}%
                </li>
                <li>
                    {t("target_charge")}: {chargeInKWhToPercentage(props.targetChargeInkWh, props.maxChargeInkWh)}%
                </li>
                <li>
                    {t("charging_time")}: {props.chargingTime}
                </li>
                <li>
                    {t("park_uuid")}: {props.chargingParkUuid}
                </li>
            </ul>
            <hr className={chargingPopup.element("separator")} />
        </>
    );
};

const PaymentOption = (props: { chargingParkPaymentOption: ChargingParkPaymentOption }) => {
    const brands = props.chargingParkPaymentOption.brands?.map((brand) => getMSPName(brand)).join(", ");
    const text = brands
        ? props.chargingParkPaymentOption.method + " [" + brands + "]"
        : props.chargingParkPaymentOption.method;
    return <li>- {text}</li>;
};

const PaymentOptions = (props: { chargingParkPaymentOptions: ChargingParkPaymentOption[] }) => {
    const { t } = useTranslation("ChargingDetailsPopup");
    return (
        <>
            <b>{t("payment_options")}</b>
            <ul className={chargingPopup.element("list")}>
                {props.chargingParkPaymentOptions.map((paymentOption) => (
                    <PaymentOption key={paymentOption.method} chargingParkPaymentOption={paymentOption} />
                ))}
            </ul>
            <hr className={chargingPopup.element("separator")} />
        </>
    );
};

const Connections = (props: { chargingConnections: ChargingConnection[] }) => {
    const { t } = useTranslation("ChargingDetailsPopup");
    return (
        <>
            <b>{t("connections")}</b>
            <ul className={chargingPopup.element("list")}>
                {props.chargingConnections.map((connection) => (
                    <li key={`${connection.facilityType} - ${connection.plugType}`}>
                        - {t("facility")}: {connection.facilityType}, {t("plug")}: {connection.plugType}
                    </li>
                ))}
            </ul>
            <hr className={chargingPopup.element("separator")} />
        </>
    );
};

const InfoObject = (props: { title: string; object: any }) => (
    <>
        <b>{props.title}</b>
        <ul className={chargingPopup.element("list")}>
            {Object.keys(props.object)
                .filter((key) => props.object[key])
                .map((key, index) => (
                    <li key={index}>
                        {formatName(key)} : {props.object[key]}
                    </li>
                ))}
        </ul>
        <hr className={chargingPopup.element("separator")} />
    </>
);

const ChargingDetailsPopupCmp = ({
    handleMouseEnter,
    handleMouseLeave,
    details,
    remainingChargeAtArrivalInkWh,
    maxChargeInkWh
}: Props) => {
    const formattedDelay = formatDuration(details.chargingTimeInSeconds);
    const { t } = useTranslation("ChargingDetailsPopup");

    return (
        <div
            className={chargingPopup.block()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...chargingPopupQa.block()}
        >
            <div className={chargingPopup.element("icon-title-delay-container")}>
                <IcEVChargerW />
                <div className={chargingPopup.element("title-delay-container")}>
                    <Title size="xs" className={chargingPopup.element("title")} {...chargingPopupQa.element("title")}>
                        {t("charging_stop")}
                    </Title>

                    {formattedDelay && (
                        <div
                            className={classNames(chargingPopup.element("delay"), "accent-text")}
                            {...chargingPopupQa.element("delay")}
                        >
                            +{formattedDelay}
                        </div>
                    )}
                </div>
            </div>
            <div className={chargingPopup.element("description")}>
                <Body size="s" {...chargingPopupQa.element("description")}>
                    <BasicDetails
                        chargingParkName={details.chargingParkName}
                        chargingParkUuid={details.chargingParkUuid}
                        targetChargeInkWh={details.targetChargeInkWh}
                        maxChargeInkWh={maxChargeInkWh}
                        remainingChargeAtArrivalInkWh={remainingChargeAtArrivalInkWh}
                        chargingTime={formattedDelay}
                    />
                    {details.chargingParkLocation && (
                        <InfoObject title="Location Info" object={details.chargingParkLocation} />
                    )}
                    {details.chargingConnectionInfo && (
                        <InfoObject title="Connection Info" object={details.chargingConnectionInfo} />
                    )}
                    {details.chargingConnections && <Connections chargingConnections={details.chargingConnections} />}
                    {details.chargingParkPaymentOptions && (
                        <PaymentOptions chargingParkPaymentOptions={details.chargingParkPaymentOptions} />
                    )}
                    {t("charging_stop_type")}: {details.chargingStopType}
                </Body>
            </div>
        </div>
    );
};

const ChargingDetailsPopup = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <ChargingDetailsPopupCmp {...props} />
    </IsomorphicSuspense>
);

export default ChargingDetailsPopup;
