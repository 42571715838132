import React, { useState } from "react";
import { useSelector } from "react-redux";
import { bem, Accent, Body } from "../../ui-library";
import "rc-slider/assets/index.css";
import "./RouteTimeline.scss";
import { ResponsiveBullet } from "@nivo/bullet";
import {
    selectPlannedRouteInformation,
    selectRoutePathSelection,
    selectRouteSelection
} from "../../state/tree/map-page/planner/selectors";
import { Section } from "@anw/gor-sdk";
import isNil from "lodash/isNil";
import without from "lodash/without";
import compact from "lodash/compact";
import Slider from "rc-slider";
import isEmpty from "lodash/isEmpty";
import { actions as plannerActions } from "../../state/tree/map-page/planner";
import classNames from "classnames";
import {
    interpolateDistanceInMetersBetweenPathIndexes,
    interpolateTimeInSecondsBetweenPathIndexes
} from "../../utils/guidance";
import { formatDuration, formatMetersWithUnitsType } from "../../utils/units";
import { selectMapBBox } from "../../state/tree/map-page/map/selectors";
import { BBox } from "@turf/helpers/dist/js/lib/geojson";
import { Feature, LineString, Position } from "geojson";
import bboxClip from "@turf/bbox-clip";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import { useAppDispatch } from "../../state/RootReducer";

const routeTimelineBem = bem("route-timeline");

const toRanges = (sections: Section[]): number[] => {
    let ranges = sections.flatMap((section) => [section.startPointIndex, section.endPointIndex]);
    if (ranges[0] === 0) {
        // trick to overcome the on-and-of ranges representing gaps between sections:
        ranges = [-1, ...ranges];
    }
    return ranges;
};

const calculateViewportRoutePathSection = (feature: Feature<LineString>, viewport: BBox): [number, number] => {
    try {
        if (!isEmpty(feature?.geometry?.coordinates)) {
            const clippedLine = bboxClip(feature, viewport);
            if (!isEmpty(clippedLine?.geometry?.coordinates)) {
                const startPathIndex = nearestPointOnLine(
                    feature.geometry,
                    clippedLine.geometry.coordinates[0] as Position
                ).properties.index;
                const endPathIndex = nearestPointOnLine(
                    feature.geometry,
                    clippedLine.geometry.coordinates[clippedLine.geometry.coordinates.length - 1] as Position
                ).properties.index;
                return [startPathIndex, endPathIndex];
            }
        }
        return [0, 0];
    } catch (error) {
        return [0, 0];
    }
};

/**
 * EXPERIMENTAL
 */
export const RouteTimeline = () => {
    const dispatch = useAppDispatch();
    const routePathSelection = useSelector(selectRoutePathSelection);
    const plannedRouteInfo = useSelector(selectPlannedRouteInformation);
    const activeRouteFeature = plannedRouteInfo?.responseFeatures?.features?.[0];
    const activeRouteIndex = useSelector(selectRouteSelection)?.index;
    const activeRoute =
        !isNil(activeRouteIndex) && plannedRouteInfo.response?.plannedItineraries[activeRouteIndex].itinerary;
    const activeRouteInstructions = activeRoute?.guidance?.instructions;
    const [selectedDistanceAndDuration, setSelectedDistanceAndDuration] = useState([null, null] as [number, number]);
    const mapBBox = useSelector(selectMapBBox);
    const viewportRoutePathSection =
        mapBBox &&
        activeRouteFeature &&
        calculateViewportRoutePathSection(activeRouteFeature, mapBBox.flat() as [number, number, number, number]);
    const segment = activeRoute?.segments?.[0];
    const groupedSections = segment?.groupedSections;
    const groupedSectionKeys = groupedSections && Object.keys(groupedSections);
    const rangeSectionKeys = without(
        groupedSectionKeys,
        "travelMode",
        "pedestrian",
        "curve",
        "distanceInterval",
        "timeInterval"
    );

    const markerSectionKeys = ["timeInterval", "distanceInterval"];
    const numPathPoints = segment?.pathLatLonAlt?.length;

    const data =
        compact(
            groupedSectionKeys?.flatMap((key) => {
                const isRangeKey = rangeSectionKeys.includes(key);
                const isMarkerKey = markerSectionKeys.includes(key);
                if (!isRangeKey && !isMarkerKey) {
                    return null;
                }
                const sectionsForGroup = groupedSections[key] as Section[];
                return {
                    id: key,
                    ranges: isRangeKey ? toRanges(sectionsForGroup) : [],
                    markers: isMarkerKey ? sectionsForGroup.flatMap((section) => [section.startPointIndex]) : [],
                    measures: []
                };
            })
        ) || null;

    const inInitialState =
        !(routePathSelection.startPointIndex && routePathSelection.startPointIndex > 0) &&
        !(routePathSelection.endPointIndex && routePathSelection.endPointIndex > 0);

    const handleSliderChange = (newRange: number[]) => {
        dispatch(plannerActions.setRoutePathSelection(newRange as [number, number]));
        segment &&
            activeRouteInstructions &&
            routePathSelection &&
            setSelectedDistanceAndDuration([
                interpolateDistanceInMetersBetweenPathIndexes(
                    activeRouteInstructions,
                    routePathSelection.startPointIndex,
                    routePathSelection.endPointIndex
                ),
                interpolateTimeInSecondsBetweenPathIndexes(
                    activeRouteInstructions,
                    routePathSelection.startPointIndex,
                    routePathSelection.endPointIndex
                )
            ]);
    };

    return (
        <>
            {!isEmpty(data) && (
                <div className={routeTimelineBem.block()}>
                    <div className={routeTimelineBem.element("heading")}>
                        <Accent>Selected: </Accent>
                        <Body>
                            {inInitialState
                                ? "Move the sliders to select a part of the route"
                                : `${formatDuration(selectedDistanceAndDuration[1])} ${formatMetersWithUnitsType(
                                      selectedDistanceAndDuration[0],
                                      "METRIC"
                                  )}`}
                        </Body>
                    </div>

                    <div className={routeTimelineBem.element("graphic")}>
                        <Slider
                            className={classNames(routeTimelineBem.element("viewport-range"))}
                            range={true}
                            max={numPathPoints}
                            value={[viewportRoutePathSection[0], viewportRoutePathSection[1]]}
                        />
                        <div className={routeTimelineBem.element("timeline")}>
                            <ResponsiveBullet
                                data={data}
                                margin={{ top: 0, right: 0, bottom: 0, left: 98 }}
                                spacing={4}
                                titleAlign="end"
                                titleOffsetX={-12}
                                isInteractive={false}
                                markerColors={"#105287"}
                                rangeColors={["rgba(255, 255, 255, 0)", "#3F9CD9"]}
                                maxValue={numPathPoints}
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                strokeWidth: 0
                                            },
                                            text: {
                                                fontSize: 0
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>

                        <Slider
                            className={classNames(routeTimelineBem.element("range"), {
                                [routeTimelineBem.element("range--initial")]: inInitialState
                            })}
                            max={numPathPoints}
                            range={true}
                            draggableTrack={true}
                            defaultValue={[0, numPathPoints]}
                            value={[routePathSelection.startPointIndex, routePathSelection.endPointIndex]}
                            onChange={handleSliderChange}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
