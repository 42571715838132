import React from "react";
import { bem, Body, Empty, qaId, Subtitle, Title } from "../../ui-library";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { formatDate, formatDuration } from "../../utils/units";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import { ReactComponent as IcTrafficBroken } from "../../../resources/icons/ic-traffic-broken.svg";
import { ReactComponent as IcTrafficClosed } from "../../../resources/icons/ic-traffic-closed.svg";
import { ReactComponent as IcTrafficDanger } from "../../../resources/icons/ic-traffic-danger.svg";
import { ReactComponent as IcTrafficQueue } from "../../../resources/icons/ic-traffic-queue.svg";
import { ReactComponent as IcTrafficSlow } from "../../../resources/icons/ic-traffic-slow.svg";
import { ReactComponent as IcTrafficStatic } from "../../../resources/icons/ic-traffic-static.svg";
import { ReactComponent as IcTrafficWorks } from "../../../resources/icons/ic-traffic-works.svg";

export const trafficPopup = bem("traffic-popup");
const trafficPopupQa = qaId("traffic-popup");

const formatCategory = (category: string) => {
    if (category !== null && category !== undefined && category !== "") {
        const cat = category.replace("_", " ");
        return cat[0].toLocaleUpperCase() + cat.slice(1).toLocaleLowerCase();
    }
    return category;
};

export type IncidentDetailsInfo = {
    icons: number[];
    descriptions: string[];
    delaySeconds: number;
    magnitude: number;
    endDate?: Date;
};

type Props = {
    details: IncidentDetailsInfo;
    handleMouseEnter?: () => void;
    handleMouseLeave?: () => void;
};

// mapIconCategory follows the generic map style incident icon_category_x coding
const getCorrectTrafficIcon = (mapIconCategory: number, magnitude: number) => {
    switch (mapIconCategory) {
        case 0:
            return "";
        case 6:
            // three different types of jams
            switch (magnitude) {
                case 2:
                    return <IcTrafficQueue key={`${mapIconCategory}-${magnitude}`} />;
                case 3:
                    return <IcTrafficStatic key={`${mapIconCategory}-${magnitude}`} />;
                default:
                    return <IcTrafficSlow key={`${mapIconCategory}-${magnitude}`} />;
            }
        case 7:
        case 8:
            return <IcTrafficClosed key={`${mapIconCategory}-${magnitude}`} />;
        case 9:
            return <IcTrafficWorks key={`${mapIconCategory}-${magnitude}`} />;
        case 14:
            return <IcTrafficBroken key={`${mapIconCategory}-${magnitude}`} />;
        default:
            return <IcTrafficDanger key={`${mapIconCategory}-${magnitude}`} />;
    }
};

const getRelatedIncidentsIcons = (iconNumbers: number[], magnitude: number) => {
    const icons = [];
    const len = iconNumbers.length;

    for (let i = 1; i < len; i++) {
        if (len > 7 && i > 5) {
            icons.push(
                <div key={`${i}-${magnitude}`} className={trafficPopup.element("related-incidents-icons-extra")}>
                    <div
                        className={classNames(
                            trafficPopup.element("related-incidents-icons-extra-text"),
                            "accent-text"
                        )}
                    >{`+${len - 6}`}</div>
                </div>
            );
            break;
        } else {
            icons.push(getCorrectTrafficIcon(iconNumbers[i], magnitude));
        }
    }
    return <div className={trafficPopup.element("related-incidents-icons")}>{icons}</div>;
};

const IncidentDetailsPopupCmp = ({ handleMouseEnter, handleMouseLeave, details }: Props) => {
    const formattedDelay = formatDuration(details.delaySeconds);
    const { t } = useTranslation(["Traffic", "IncidentDetailsPopup", "Units"]);
    return (
        <div
            className={classNames(trafficPopup.block(), trafficPopup.modifier("magnitude-" + details.magnitude))}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...trafficPopupQa.block()}
        >
            <div className={trafficPopup.element("main-incident")}>
                {getCorrectTrafficIcon(details.icons[0], details.magnitude)}
                <div className={trafficPopup.element("icon-title-delay-container")}>
                    <Title
                        size="xs"
                        className={trafficPopup.element("icon-title")}
                        {...trafficPopupQa.element("title")}
                    >
                        {t(`Traffic:incident_${details.icons[0]}`)}
                    </Title>
                    {formattedDelay && (
                        <div
                            className={classNames(trafficPopup.element("delay"), "accent-text")}
                            {...trafficPopupQa.element("delay")}
                        >
                            +{formattedDelay}
                        </div>
                    )}
                </div>
                <Title size="xs" className={trafficPopup.element("magnitude")}>
                    {details.icons[0] === 6
                        ? t(`IncidentDetailsPopup:jam_magnitude_${details.magnitude}`)
                        : t(`IncidentDetailsPopup:magnitude_${details.magnitude}`)}
                </Title>
            </div>
            <div className={trafficPopup.element("expected-and-description")}>
                {details.endDate && details.endDate.getTime() > new Date().getTime() && (
                    <div
                        className={classNames(trafficPopup.element("end-date"), "accent-text-s")}
                        {...trafficPopupQa.element("end-date")}
                    >
                        {`${t("IncidentDetailsPopup:expected_until")} ${formatDate(details.endDate, "TRAFFIC", t)}`}
                    </div>
                )}
                <Body
                    size="s"
                    className={trafficPopup.element("description")}
                    {...trafficPopupQa.element("description")}
                >
                    {details.descriptions.map((value) => formatCategory(value)).join(", ")}
                </Body>
            </div>
            {details.icons.length > 1 && (
                <div
                    className={trafficPopup.element("related-incidents")}
                    {...trafficPopupQa.element("related-incidents")}
                >
                    <Subtitle className={trafficPopup.element("related-incidents-title")}>
                        {t(`IncidentDetailsPopup:related_incidents`)}
                    </Subtitle>
                    {getRelatedIncidentsIcons(details.icons, details.magnitude)}
                </div>
            )}
        </div>
    );
};

const IncidentDetailsPopup = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <IncidentDetailsPopupCmp {...props} />
    </IsomorphicSuspense>
);

export default IncidentDetailsPopup;
