import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
    bem,
    ButtonPrimary,
    ButtonSecondary,
    Empty,
    InputControl,
    qaId,
    Radio,
    Title,
    ToggleGroupRadio
} from "../../ui-library";
import { UserMapLocationType } from "@anw/gor-sdk";
import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

import { selectPlaceBeingEdited } from "../../state/tree/map-page/my-items/selectors";
import { actions as myItemsActions } from "../../state/tree/map-page/my-items";
import { useAppDispatch } from "../../state/RootReducer";
import { onSavePlace } from "../../state/tree/map-page/my-items/userActionThunks";
import { defaultNameForType } from "../../utils/myPlaces";
import { buildLocationInfoTitle } from "../../utils/location";
import { Portal } from "../elements/Portal";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import "./FavouriteEditModal.scss";

const favouriteEditModal = bem("favourite-edit-modal");
const favouriteEditModalQa = qaId("favourite-edit-modal");

const FavouriteEditModalCmp = () => {
    const dispatch = useAppDispatch();
    const placeBeingEdited = useSelector(selectPlaceBeingEdited);
    const initialName = buildLocationInfoTitle(placeBeingEdited.mapLocation?.locationInfo);
    const [editedName, setEditedName] = useState(initialName);
    const [editedType, setEditedType] = useState(placeBeingEdited.type || "GENERIC");
    const { t } = useTranslation("FavouriteEditModal");

    const handleSave = () => {
        dispatch(
            onSavePlace({
                userMapLocation: {
                    ...placeBeingEdited,
                    type: editedType,
                    mapLocation: {
                        ...placeBeingEdited.mapLocation,
                        locationInfo: {
                            ...placeBeingEdited.mapLocation.locationInfo,
                            customName: editedName
                        }
                    }
                },
                nameEdited: !isEqual(initialName, editedName)
            })
        );
    };

    const handleTypeToggled = (type: UserMapLocationType) => {
        setEditedName(
            type == "GENERIC" ? initialName : t(`FavouriteEditModal:${defaultNameForType(type).toLowerCase()}`)
        );
        setEditedType(type);
    };

    const handleCancel = () => {
        dispatch(myItemsActions.setPlaceBeingEdited(null));
        dispatch(myItemsActions.setPlaceBeingEditedOrigin(null));
    };

    return (
        <Portal>
            <div
                {...favouriteEditModalQa.element("backdrop")}
                className="modal-backdrop fade show"
                tabIndex={-1}
                aria-labelledby="favouriteEditModalLabel"
                aria-hidden="true"
            />
            <div className={classNames(favouriteEditModal.block(), "modal fade show")}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <Title size="s" className="modal-title" {...favouriteEditModalQa.element("heading")}>
                                {t("FavouriteEditModal:heading")}
                            </Title>
                        </div>
                        <div className="modal-body">
                            <ToggleGroupRadio
                                className={classNames(favouriteEditModal.element("favourite-type-selector"))}
                                name="favouriteTypeGroup"
                                selectedValue={editedType}
                                onChange={(value) => setEditedType(value)}
                            >
                                <Radio
                                    {...favouriteEditModalQa.element("pin-as-generic")}
                                    value="GENERIC"
                                    text={t("FavouriteEditModal:place")}
                                    onClick={() => handleTypeToggled("GENERIC")}
                                    className={classNames(favouriteEditModal.element("favourite-home-selector"))}
                                />
                                <Radio
                                    {...favouriteEditModalQa.element("pin-as-home")}
                                    value="HOME"
                                    text={t("FavouriteEditModal:home")}
                                    onClick={() => handleTypeToggled("HOME")}
                                    className={classNames(favouriteEditModal.element("favourite-home-selector"))}
                                />
                                <Radio
                                    value="WORK"
                                    {...favouriteEditModalQa.element("pin-as-work")}
                                    text={t("FavouriteEditModal:work")}
                                    onClick={() => handleTypeToggled("WORK")}
                                />
                            </ToggleGroupRadio>
                            <InputControl
                                name={"Edit place name"}
                                type={"text"}
                                value={editedName}
                                onChange={(ev) => setEditedName(ev.target.value)}
                                id="favourite-edit-name"
                                label={t("FavouriteEditModal:name")}
                                disabled={["HOME", "WORK"].includes(editedType)}
                            />
                        </div>
                        <div className="modal-footer">
                            <ButtonSecondary
                                text={t("FavouriteEditModal:cancel")}
                                {...favouriteEditModalQa.element("cancel")}
                                onClick={() => handleCancel()}
                            />
                            <ButtonPrimary
                                text={t("FavouriteEditModal:save")}
                                {...favouriteEditModalQa.element("save")}
                                onClick={handleSave}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
};

const FavouriteEditModal = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <FavouriteEditModalCmp />
    </IsomorphicSuspense>
);

export default FavouriteEditModal;
