import React from "react";
import { Hyperlink, IlConnectionFailure, IlWhereAreYou } from "../../ui-library";

import {
    ActiveDestinationNotification,
    MyItemsPlacesNotification,
    MyItemsRoutesNotification,
    NotificationTypes
} from "../../state/tree/notification/reducers";
import { buildLocationInfoTitle } from "../../utils/location";
import { formatTitle } from "../../utils/itinerary";

import { ReactComponent as IlRouteSynced } from "../../../resources/illustrations/route-synced.svg";
import { ReactComponent as IlRouteUnSynced } from "../../../resources/illustrations/route-unsynced.svg";
import { ReactComponent as IlMyPlacesAdd } from "../../../resources/illustrations/my-places-add.svg";
import { ReactComponent as IlMyPlacesRemove } from "../../../resources/illustrations/my-places-remove.svg";
import { ReactComponent as IlMyRoutesAdd } from "../../../resources/illustrations/my-routes-add.svg";
import { ReactComponent as IlMyRoutesRemove } from "../../../resources/illustrations/my-routes-remove.svg";
import { TFunction } from "i18next";
export const composePlaceHeading = (
    { locationInfo, locationType, notificationType }: MyItemsPlacesNotification,
    t: TFunction
) => {
    const placeName = buildLocationInfoTitle(locationInfo);
    switch (notificationType) {
        case "place-add":
            switch (locationType) {
                case "HOME":
                    return t("PageNotifications:place.heading.add_home");
                case "WORK":
                    return t("PageNotifications:place.heading.add_work");
                case "GENERIC":
                default:
                    return t("PageNotifications:place.heading.add_generic");
            }
        case "place-update":
            return t("PageNotifications:place.heading.update");
        case "place-remove":
            return placeName
                ? t("PageNotifications:place.heading.remove_known", { name: placeName })
                : t("PageNotifications:place.heading.remove_unknown");
    }
};

export const composePlaceMessage = ({ notificationType }: MyItemsPlacesNotification, t: TFunction) => {
    switch (notificationType) {
        case "place-add":
        case "place-update":
            return (
                <>
                    {t("PageNotifications:place.message.add_first_part") + " Check "}
                    <Hyperlink>{t("PageNotifications:place.message.add_second_part")}</Hyperlink>.
                </>
            );
        case "place-remove":
            return t("PageNotifications:place.message.remove");
    }
};

export const composeRouteHeading = ({ notificationType, itinerary }: MyItemsRoutesNotification, t: TFunction) => {
    switch (notificationType) {
        case "route-add":
            return t("PageNotifications:route.heading.add");
        case "route-update":
            return t("PageNotifications:route.heading.update");
        case "route-removed":
            return t("PageNotifications:route.heading.remove", { name: `${formatTitle(itinerary, true) || "Route"}` });
        case "route-un-published":
            return t("PageNotifications:route.heading.un-publish");
        case "route-published":
            return t("PageNotifications:route.heading.publish");
        case "route-synced":
            return t("PageNotifications:route.heading.sync");
        case "route-un-synced":
            return t("PageNotifications:route.heading.un-sync", { name: `${formatTitle(itinerary, true) || "Route"}` });
    }
};

export const composeRouteMessage = ({ notificationType }: MyItemsRoutesNotification, t: TFunction) => {
    switch (notificationType) {
        case "route-add":
            return (
                <>
                    {t("PageNotifications:route.message.add_first_part") + " "}
                    <Hyperlink>{t("PageNotifications:route.message.add_second_part")}</Hyperlink>
                </>
            );
        case "route-update":
            return t("PageNotifications:route.message.update");
        case "route-removed":
            return t("PageNotifications:route.message.remove");
        case "route-un-published":
            return t("PageNotifications:route.message.un-publish");
        case "route-published":
            return t("PageNotifications:route.message.publish");
        case "route-synced":
            return (
                <>
                    {t("PageNotifications:route.message.sync_first_part") + " check  "}
                    <Hyperlink>{t("PageNotifications:route.message.sync_second_part") + " "}</Hyperlink>
                    {t("PageNotifications:route.message.sync_third_part")}
                </>
            );
        case "route-un-synced":
            return t("PageNotifications:route.message.un-sync");
    }
};

export const composeActiveDestinationHeading = (
    { notificationType, locationInfo }: ActiveDestinationNotification,
    t: TFunction
) => {
    switch (notificationType) {
        case "activeDestination-add":
            if (Array.isArray(locationInfo) && locationInfo.length > 2) {
                return t("PageNotifications:active_destination.heading.add_stops");
            } else {
                return t("PageNotifications:active_destination.heading.add_destination");
            }
        case "activeDestination-remove":
            return t("PageNotifications:active_destination.heading.remove");
    }
};

export const composeActiveDestinationMessage = ({ notificationType }: ActiveDestinationNotification, t: TFunction) => {
    switch (notificationType) {
        case "activeDestination-add":
            return (
                <>
                    {t("PageNotifications:active_destination.message.add_first_part") + " "}
                    <Hyperlink>{t("PageNotifications:active_destination.message.add_second_part") + " "}</Hyperlink>
                    {t("PageNotifications:active_destination.message.add_third_part")}
                </>
            );
        case "activeDestination-remove":
            return t("PageNotifications:active_destination.message.remove");
    }
};

export const notificationImage = (notificationType: NotificationTypes) => {
    switch (notificationType) {
        case "place-add":
            return <IlMyPlacesAdd />;
        case "place-remove":
            return <IlMyPlacesRemove />;
        case "route-add":
            return <IlMyRoutesAdd />;
        case "route-update":
            return <IlMyRoutesAdd />;
        case "route-removed":
            return <IlMyRoutesRemove />;
        case "route-synced":
        case "activeDestination-add":
            return <IlRouteSynced />;
        case "route-un-synced":
        case "activeDestination-remove":
            return <IlRouteUnSynced />;
        case "connection-failure":
            return <IlConnectionFailure />;
        case "unknown-location":
            return <IlWhereAreYou />;
        case "route-published":
        case "route-un-published":
        case "generic-error":
        case "generic-message":
        case "detailed-range-uncalculable":
            return <></>;
    }
};
