import { LayerSpecification } from "maplibre-gl";
import { LocationInfo } from "@anw/gor-sdk";
import { MultiPolygon, Polygon } from "@turf/helpers";
import { Feature, LineString, Point } from "geojson";

import GeoJsonSource from "../../classes/map/GeoJsonSource";
import WaypointGeoJsonSourceWithLayers from "../../classes/map/WaypointGeoJsonSourceWithLayers";
import { locationInfoToFeature, userMapLocationToFeature } from "../../utils/location";
import * as configLayers from "../../map-layers";
import SourceWithLayers from "../../classes/map/SourceWithLayers";
import MapSource from "../../classes/map/MapSource";
import { LayerConfig, LayerWithSource } from "../../classes/map/LayerTypes";
import { TTMSearchResult, TTMUserMapLocation } from "../../utils/locationTypes";
import { buildBoostedPOICategoryLayer } from "../../map-layers/boostedPOIs";
import { GeoJsonSourceWithLayers } from "../../classes/map/GeoJsonSourceWithLayers";
import MapProvider from "../../classes/map/MapProvider";

const getLayersBySource = (layers: LayerWithSource[], source: string): LayerConfig[] => {
    return layers.filter((layer) => layer.source === source).map((layer: LayerSpecification) => ({ layer }));
};

/**
 * Map sources and layers from TTM, with control on the right layer ordering and grouping for initialization and map querying.
 * This is the place where we recognize, order and group the different map sources and layers which are relevant for TTM.
 */
export default class TTMSourcesAndLayers {
    // POI vector source/layer expected from the main style (see style URL on map initialization):
    readonly mainStylePOIs: SourceWithLayers;
    readonly boostedPOIs: SourceWithLayers;

    // Traffic incident/flow vector source/layers expected from the main style (see style URL on map initialization):
    readonly mainStyleTrafficIncidents: SourceWithLayers;
    readonly mainStyleTrafficFlow: SourceWithLayers;

    // Search
    readonly searchResults: GeoJsonSourceWithLayers<Feature<Point>, TTMSearchResult>;
    readonly hoveredSearchResult: GeoJsonSourceWithLayers<Feature<Point>, TTMSearchResult>;
    readonly selectedSearchResult: GeoJsonSourceWithLayers<Feature<Point>, TTMSearchResult>;

    // Route waypoints:
    readonly routeWaypoints: WaypointGeoJsonSourceWithLayers;

    // Main route lines:
    readonly mainRouteLine: GeoJsonSourceWithLayers<Feature<LineString>>;
    readonly altRouteLines: GeoJsonSourceWithLayers<Feature<LineString>>;

    // Selected route part:
    readonly routePathSelection: GeoJsonSourceWithLayers<Feature<LineString>>;

    // Route sections:
    readonly routeIncidents: GeoJsonSourceWithLayers<Feature<LineString>>;
    readonly routeTunnels: GeoJsonSourceWithLayers<Feature<LineString>>;
    readonly routeTollRoads: GeoJsonSourceWithLayers<Feature<LineString>>;
    readonly routeFerries: GeoJsonSourceWithLayers<Feature<LineString>>;
    readonly routeCurves: GeoJsonSourceWithLayers<Feature<LineString>>;
    readonly routeCurvyStretches: GeoJsonSourceWithLayers<Feature<LineString>>;

    // Route time-distance intervals:
    readonly routeTimeIntervals: GeoJsonSourceWithLayers<Feature<Point>>;
    readonly routeDistanceIntervals: GeoJsonSourceWithLayers<Feature<Point>>;

    // TomTom Road Trips (vector source):
    readonly roadTrips: SourceWithLayers;
    readonly riderRoutes: SourceWithLayers;

    // Scenic segments (vector source):
    readonly scenicSegments: SourceWithLayers;

    // Location geometry:
    readonly locationGeometry: GeoJsonSourceWithLayers<Feature<MultiPolygon>>;

    // context menu pin:
    readonly contextMenuPin: GeoJsonSourceWithLayers<Feature<Point>>;

    // My places
    readonly myPlaces: GeoJsonSourceWithLayers<Feature<Point>, TTMUserMapLocation>;

    // Active destination
    readonly activeDestination: GeoJsonSourceWithLayers<Feature<Point>, LocationInfo>;

    // Reachable Range geometry:
    readonly reachableRangeGeometry: GeoJsonSourceWithLayers<Feature<Polygon>>;

    /**
     * @param layerToRenderLinesUnder Reference layer from the base map style to render e.g. our own route lines below
     * @param itineraryCollectionTilesURL The source URL to itinerary collection vector tiles
     */
    constructor(layerToRenderLinesUnder: string, itineraryCollectionTilesURL: string) {
        const map = MapProvider.map;
        const mainStyleLayers = map.getStyle().layers as LayerWithSource[];

        // Main style POI layer:
        this.mainStylePOIs = new SourceWithLayers(
            new MapSource("poiTiles", map.getStyle().sources["poiTiles"]),
            getLayersBySource(mainStyleLayers, "poiTiles")
        );

        // Main style traffic incidents and flow:
        this.mainStyleTrafficIncidents = new SourceWithLayers(
            new MapSource("vectorTilesIncidents", map.getStyle().sources["vectorTilesIncidents"]),
            getLayersBySource(mainStyleLayers, "vectorTilesIncidents")
        );
        this.mainStyleTrafficFlow = new SourceWithLayers(
            new MapSource("vectorTilesFlow", map.getStyle().sources["vectorTilesFlow"]),
            getLayersBySource(mainStyleLayers, "vectorTilesFlow")
        );

        // Search:
        const selectedSearchResultID = "SELECTED_SEARCH_RESULT";
        this.selectedSearchResult = new GeoJsonSourceWithLayers(
            new GeoJsonSource(selectedSearchResultID, locationInfoToFeature),
            configLayers.selectedSearchPinLayer(selectedSearchResultID)
        );
        const hoveredSearchResultID = "HOVERED_SEARCH_RESULT";
        this.hoveredSearchResult = new GeoJsonSourceWithLayers(
            new GeoJsonSource(hoveredSearchResultID, locationInfoToFeature),
            configLayers.hoveredSearchResultLayer(hoveredSearchResultID, selectedSearchResultID)
        );
        const searchResultsIconsID = "SEARCH_RESULTS_ICONS";
        this.searchResults = new GeoJsonSourceWithLayers(new GeoJsonSource("SEARCH_RESULTS", locationInfoToFeature), [
            configLayers.searchResultIconPointsLayer(searchResultsIconsID, hoveredSearchResultID),
            configLayers.searchResultTextPointsLayer("SEARCH_RESULTS_TEXTS", searchResultsIconsID)
        ]);

        // Route waypoints:
        const routeWaypointsID = "PLAN_ROUTE_WAYPOINTS";
        this.routeWaypoints = new WaypointGeoJsonSourceWithLayers(new GeoJsonSource(routeWaypointsID), [
            configLayers.planRouteWaypoints(routeWaypointsID, hoveredSearchResultID),
            configLayers.planRouteWaypointLabels("PLAN_ROUTE_WAYPOINT_LABELS", hoveredSearchResultID)
        ]);

        // Active destination
        const activeDestinationID = "ACTIVE_DESTINATION";
        this.activeDestination = new GeoJsonSourceWithLayers(
            new GeoJsonSource(activeDestinationID, locationInfoToFeature),
            configLayers.activeDestinationLayer(`${activeDestinationID}_ICONS`, routeWaypointsID)
        );

        // My places
        const myPlacesID = "MY_PLACES";
        this.myPlaces = new GeoJsonSourceWithLayers(new GeoJsonSource(myPlacesID, userMapLocationToFeature), [
            configLayers.myPlacesIconLayer(`${myPlacesID}_ICONS`, `${activeDestinationID}_ICONS`),
            configLayers.myPlacesTextLayer(`${myPlacesID}_TEXT`, `${myPlacesID}_ICONS`)
        ]);

        // Main route line:
        const mainRouteBGLayer = "PLAN_ROUTE_LINE_BG";
        this.mainRouteLine = new GeoJsonSourceWithLayers(new GeoJsonSource("PLAN_ROUTE"), [
            configLayers.planRouteBackgroundLine(mainRouteBGLayer, layerToRenderLinesUnder),
            configLayers.planRouteForegroundLine("PLAN_ROUTE_LINE_FG", layerToRenderLinesUnder)
        ]);

        // Alternative route lines:
        this.altRouteLines = new GeoJsonSourceWithLayers(new GeoJsonSource("PLAN_ROUTE_ALTERNATIVES"), [
            configLayers.planRouteAltBgLine("PLAN_ROUTE_ALT_LINE_BG", mainRouteBGLayer),
            configLayers.planRouteAltFgLine("PLAN_ROUTE_ALT_LINE_FG", mainRouteBGLayer)
        ]);

        // Selected route part:
        this.routePathSelection = new GeoJsonSourceWithLayers(
            new GeoJsonSource("PLAN_ROUTE_SELECTION"),
            configLayers.planRouteSelectionLine("PLAN_ROUTE_SELECTION_LINE", mainRouteBGLayer)
        );

        // Route sections:
        const routeIncidentsSymbolLayer = "PLAN_ROUTE_INCIDENTS_POINT_SYMBOL";
        this.routeIncidents = new GeoJsonSourceWithLayers(new GeoJsonSource("PLAN_ROUTE_TRAFFIC_INCIDENTS"), [
            configLayers.planRouteIncidentsBGLine("PLAN_ROUTE_INCIDENT_LINES", layerToRenderLinesUnder),
            configLayers.planRouteIncidentsDashedLine("PLAN_ROUTE_INCIDENT_DASHED_LINES", layerToRenderLinesUnder),
            configLayers.planRouteIncidentsPatternLine("PLAN_ROUTE_INCIDENT_PATTERN_LINES", layerToRenderLinesUnder),
            configLayers.planRouteIncidentsPointSymbol(routeIncidentsSymbolLayer, routeWaypointsID)
        ]);
        this.routeTunnels = new GeoJsonSourceWithLayers(
            new GeoJsonSource("PLAN_ROUTE_TUNNELS"),
            configLayers.planRouteTunnelsLine("PLAN_ROUTE_TUNNELS", layerToRenderLinesUnder)
        );
        const tollRoadsSymbolID = "PLAN_ROUTE_TOLL_ROADS_POINT_SYMBOL";
        this.routeTollRoads = new GeoJsonSourceWithLayers(new GeoJsonSource("PLAN_ROUTE_TOLL_ROADS"), [
            configLayers.planRouteTollRoadsBackgroundLine("PLAN_ROUTE_TOLL_ROADS", mainRouteBGLayer),
            configLayers.planRouteTollRoadsPointSymbol(tollRoadsSymbolID, routeWaypointsID)
        ]);
        this.routeFerries = new GeoJsonSourceWithLayers(new GeoJsonSource("PLAN_ROUTE_FERRIES"), [
            configLayers.planRouteFerriesLine("PLAN_ROUTE_FERRY_LINES", layerToRenderLinesUnder),
            configLayers.planRouteFerriesPointSymbol("PLAN_ROUTE_FERRIES_POINT_SYMBOL", routeWaypointsID)
        ]);

        // Route time-distance intervals:
        const timeMarkersLayerID = "PLAN_ROUTE_TIME_MARKERS";
        this.routeTimeIntervals = new GeoJsonSourceWithLayers(
            new GeoJsonSource("PLAN_ROUTE_TIME_INTERVALS"),
            configLayers.planRouteTimeIntervalsSymbol(timeMarkersLayerID, tollRoadsSymbolID)
        );
        this.routeDistanceIntervals = new GeoJsonSourceWithLayers(
            new GeoJsonSource("PLAN_ROUTE_DISTANCE_INTERVALS"),
            configLayers.planRouteDistanceIntervalsSymbol("PLAN_ROUTE_DISTANCE_MARKERS", routeIncidentsSymbolLayer)
        );

        const curveLinesLayerID = "PLAN_ROUTE_CURVE_LINES";
        this.routeCurves = new GeoJsonSourceWithLayers(
            new GeoJsonSource("PLAN_ROUTE_CURVES"),
            configLayers.planRouteCurvesLine(curveLinesLayerID, mainRouteBGLayer)
        );

        this.routeCurvyStretches = new GeoJsonSourceWithLayers(
            new GeoJsonSource("PLAN_ROUTE_CURVY_STRETCHES"),
            configLayers.planRouteCurvyStretchesLine("PLAN_ROUTE_CURVY_STRETCH_LINES", curveLinesLayerID)
        );

        // Published routes:
        this.roadTrips = new SourceWithLayers(
            new MapSource("TOMTOM_ROAD_TRIPS", {
                type: "vector",
                tiles: [itineraryCollectionTilesURL.replace("{collectionName}", "tomtomroadtrips")],
                maxzoom: 14
            }),
            [
                configLayers.publishedRouteOutline("TOMTOM_ROAD_TRIPS_OUTLINE", layerToRenderLinesUnder),
                configLayers.publishedRoute("TOMTOM_ROAD_TRIPS_LINE", layerToRenderLinesUnder)
            ]
        );

        // Premium Rider Routes:
        this.riderRoutes = new SourceWithLayers(
            new MapSource("TOMTOM_RIDER_ROUTES", {
                type: "vector",
                tiles: [itineraryCollectionTilesURL.replace("{collectionName}", "riderroutecollection")],
                maxzoom: 14
            }),
            [
                configLayers.publishedRouteOutline("TOMTOM_RIDER_ROUTES_OUTLINE", layerToRenderLinesUnder),
                configLayers.publishedRiderRoute("TOMTOM_RIDER_ROUTES_LINE", layerToRenderLinesUnder)
            ]
        );

        // Scenic segments:
        this.scenicSegments = new SourceWithLayers(
            new MapSource("SCENIC_SEGMENTS", {
                type: "vector",
                tiles: [itineraryCollectionTilesURL.replace("{collectionName}", "scenicsegments")],
                maxzoom: 14
            }),
            [
                configLayers.publishedRouteOutline("SCENIC_SEGMENTS_OUTLINE", layerToRenderLinesUnder),
                configLayers.scenicSegmentLine("SCENIC_SEGMENTS_LINE", layerToRenderLinesUnder)
            ]
        );

        // Location geometry:
        this.locationGeometry = new GeoJsonSourceWithLayers(new GeoJsonSource("LOCATION_GEOMETRY"), [
            configLayers.locationGeometryFill("LOCATION_GEOMETRY_FILL", routeWaypointsID),
            configLayers.locationGeometryOutline("LOCATION_GEOMETRY_OUTLINE", layerToRenderLinesUnder)
        ]);

        this.boostedPOIs = new SourceWithLayers(new MapSource("poiTiles", map.getStyle().sources["poiTiles"]), {
            beforeId: searchResultsIconsID,
            layer: { ...(buildBoostedPOICategoryLayer() as LayerSpecification) }
        });

        // context panel pin
        this.contextMenuPin = new GeoJsonSourceWithLayers(new GeoJsonSource("OPTIONS_PIN"), [
            configLayers.contextMenuPinLayer("OPTIONS_PIN")
        ]);

        this.reachableRangeGeometry = new GeoJsonSourceWithLayers(new GeoJsonSource("REACHABLE_RANGE_GEOMETRY"), [
            configLayers.reachableRangeFill("REACHABLE_RANGE_FILL", layerToRenderLinesUnder),
            configLayers.reachableRangeOutline("REACHABLE_RANGE_OUTLINE", layerToRenderLinesUnder)
        ]);
    }

    public ensureAddedToMap = () => {
        const map = MapProvider.map;
        this.selectedSearchResult.ensureAddedToMapWithVisibility(false);
        this.hoveredSearchResult.ensureAddedToMapWithVisibility(false);
        this.searchResults.ensureAddedToMapWithVisibility(false);
        this.routeWaypoints.ensureAddedToMapWithVisibility(false);
        this.activeDestination.ensureAddedToMapWithVisibility(false);
        this.myPlaces.ensureAddedToMapWithVisibility(false);
        this.mainRouteLine.ensureAddedToMapWithVisibility(false);
        this.altRouteLines.ensureAddedToMapWithVisibility(false);
        this.routePathSelection.ensureAddedToMapWithVisibility(false);
        this.routeIncidents.ensureAddedToMapWithVisibility(false);
        this.routeTollRoads.ensureAddedToMapWithVisibility(false);
        this.routeFerries.ensureAddedToMapWithVisibility(false);
        this.routeTunnels.ensureAddedToMapWithVisibility(false);
        this.routeTimeIntervals.ensureAddedToMapWithVisibility(false);
        this.routeDistanceIntervals.ensureAddedToMapWithVisibility(false);
        this.routeCurves.ensureAddedToMapWithVisibility(false);
        this.routeCurvyStretches.ensureAddedToMapWithVisibility(false);
        // published routes default to not-visible:
        this.roadTrips.ensureAddedToMapWithVisibility(false);
        this.riderRoutes.ensureAddedToMapWithVisibility(false);
        this.scenicSegments.ensureAddedToMapWithVisibility(false);
        this.locationGeometry.ensureAddedToMapWithVisibility(false);
        if (map.getSource(this.boostedPOIs.source.id)) {
            this.boostedPOIs.ensureAddedToMapWithVisibility(false);
        }
        this.contextMenuPin.ensureAddedToMapWithVisibility(false);
        this.reachableRangeGeometry.ensureAddedToMapWithVisibility(false);
    };
}
