import React from "react";
import { MapOptions } from "maplibre-gl";

import { ExposedApi } from "../../utils/exposed-api";
import MapProvider from "../../classes/map/MapProvider";

export default abstract class AbstractMap<P = AnyObject, S = AnyObject> extends React.Component<P, S> {
    constructor(props) {
        super(props);
        this.exposedApi = new ExposedApi();
    }

    protected exposedApi: ExposedApi;
    protected abstract apiKey: string;
    protected abstract mapElementId: string; // id of the element you wish to display the map

    /**
     * This optional function will be called after the map is destroyed.
     * Use this to detach listeners or bubble events to redux and similar.
     */
    protected onMapDestroy?: () => void;

    /**
     * Call this function from the child class to render the map on the DOM.
     */
    protected initializeMap(
        style = undefined,
        lat = 52.36203,
        lng = 4.89153,
        extra: Pick<MapOptions, "zoom" | "bearing" | "pitch"> = {}
    ) {
        const { zoom, bearing, pitch } = extra;

        MapProvider.initializeMap({
            container: "map",
            center: { lat, lng },
            maxZoom: 20,
            zoom: zoom || 12,
            ...(bearing && { bearing }),
            ...(pitch && { pitch }),
            style
        });
    }
}
