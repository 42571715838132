import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { UnitsType } from "@anw/gor-sdk";
import escape from "lodash/escape";

import { configureAppStore } from "./state/RootReducer";
import RemoteLogger from "./classes/RemoteLogger";
import { TealiumLogger } from "./classes/TealiumLogger";
import initServiceWorker from "./classes/ServiceWorker";
import { initializeLocalization } from "./classes/localization/Localization";
import App from "./components/main/App";
import { ExposedApi } from "./utils/exposed-api";
import { getLocalStorageJson, getLocalStorageValue } from "./utils/localStorage";
import { actions as userActions, AwsUserLocationInfo } from "./state/tree/user/reducers";
import { unitsTypeFromCountryCode } from "./utils/units";
import { actions as globalConfigActions, featureConfigsKey } from "./state/tree/global-configuration/reducers";
import { actions as settingsActions, unitsTypeKey } from "./state/tree/map-page/settings/reducers";
import { initDefaultVehicleParametersForEV } from "./state/tree/map-page/settings/thunks";

// settings for analytics
window["utag_cfg_ovrd"] = { noview: true };

RemoteLogger.init();

window.addEventListener("error", (event: ErrorEvent) => {
    const errorLog = {
        errorEventMessage: event.message,
        errorEventFilename: event.filename,
        errorEventLineCol: `${event.lineno}:${event.colno}`,
        errorMessage: event.error?.message ?? "",
        errorStack: event.error?.stack ?? "",
        errorName: event.error?.name ?? ""
    };
    console.error(`Application global error handler: ${JSON.stringify(errorLog, null, 4)}`);
    RemoteLogger.log({
        severity: "error",
        category: "index",
        message: "Application global error handler",
        data: errorLog
    });
});

new TealiumLogger();
const exposedAPI = new ExposedApi();
initServiceWorker();

const createAwsUserLocationInfo = (headers: Headers): AwsUserLocationInfo => {
    let coordinates;
    if (headers.has("cloudfront-viewer-longitude") && headers.has("cloudfront-viewer-latitude")) {
        coordinates = [
            Number(headers.get("cloudfront-viewer-longitude")),
            Number(headers.get("cloudfront-viewer-latitude"))
        ];
    }
    return {
        coordinates,
        city: headers.get("cloudfront-viewer-city"),
        countryCode: headers.get("cloudfront-viewer-country"),
        countryName: headers.get("cloudfront-viewer-country-name")
    };
};

const loadLocalStorageFeatureConfigs = (store) => {
    const features = getLocalStorageJson<{ [key: string]: unknown }>(featureConfigsKey);
    if (features) {
        // We ensure to skip hidden features when reading from local storage.
        // This way when hiding a feature, we can effectively override whatever was stored for this user.
        const hiddenFeatures = store.getState().globalConfiguration.featureConfigs.hidden;
        for (const key of Object.keys(features)) {
            if (hiddenFeatures.includes(key)) {
                delete features[key];
            }
        }
        store.dispatch(globalConfigActions.putFeatureConfigs(features));
    }
};

const autoDetectUnitsTypeIfNeeded = (store, awsUserLocationInfo: AwsUserLocationInfo) => {
    const unitsTypeFromLocalStorage = getLocalStorageValue<UnitsType>(unitsTypeKey);
    if (unitsTypeFromLocalStorage) {
        // no need to do anything, user already picked one
        return;
    }
    const unitsType = unitsTypeFromCountryCode(awsUserLocationInfo.countryCode);
    if (unitsType !== "METRIC") {
        // metric is by default
        store.dispatch(settingsActions.setAutoUnitsType(unitsType));
    }
};

(async () => {
    const rootElement = document.querySelector(".main");
    const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
    const globalConfigEndpoint = "https://configuration.plan.tomtom.com/runtime";

    RemoteLogger.generateInitialLog();
    try {
        const response = await fetch(globalConfigEndpoint, null);
        if (response.status !== 200) {
            const text = await response.text();
            const safeText = escape(text).replace(/\n/g, "\\n").replace(/\r/g, "\\r");
            console.error(`global config response: ${safeText}`);
        }

        const [runtimeCfg, headers] = await Promise.all([response.json(), response.headers]);

        const store = configureAppStore(runtimeCfg);
        exposedAPI.store = store;
        loadLocalStorageFeatureConfigs(store);
        const awsUserLocationInfo = createAwsUserLocationInfo(headers);
        store.dispatch(userActions.setAwsUserLocationInfo(awsUserLocationInfo));
        const features: { [key: string]: unknown } = getLocalStorageJson(featureConfigsKey);
        // check feature toggle config from local storage or runtimeCfg
        const initializeEVParameters =
            (features?.enableLDEV ?? runtimeCfg.globalConfiguration.featureConfigs?.enableLDEV) &&
            !runtimeCfg.globalConfiguration.featureConfigs?.hidden?.includes("enable_LDEV");
        // initialize VehicleParameters with default values used in LDEV branches, or reset it when the feature is disabled
        store.dispatch(initDefaultVehicleParametersForEV(initializeEVParameters));
        autoDetectUnitsTypeIfNeeded(store, awsUserLocationInfo);
        await initializeLocalization();

        if (rootElement.hasChildNodes()) {
            // see custom global declaration
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.snapSaveState = () => {
                rootElement.setAttribute("data-server-rendered", "true");
            };
            hydrateRoot(rootElement, <App store={store} />);
        } else {
            root.render(<App store={store} />);
        }
    } catch (error) {
        console.error("Application loading failed");
        console.error(error?.message ?? error);
        RemoteLogger.log({
            severity: "error",
            category: "index",
            message: "Application loading failed",
            data: {
                errorMessage: error.message,
                errorStack: error.stack,
                errorName: error.name
            }
        });
        root.render(
            <div className="main-error" role="main">
                <div className="main-error-title">Oops, something is broken</div>
                <div className="main-error-name">{error.name}</div>
                <div className="main-error-message">{error.message}</div>
                <div className="main-error-stack">{error.stack}</div>
            </div>
        );
    }
})();

window.addEventListener("beforeunload", () => {
    RemoteLogger.flush();
});

// eslint-disable-next-line
(module as any).hot?.accept();
