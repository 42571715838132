import { useAsync } from "react-use";
import memoize from "lodash/memoize";
import { detailsToTTMSearchResult } from "../utils/location";
import { EvPOI } from "../utils/ev";
import { details, detailsFromLink } from "../services/ngs/ngsClient";
import { ServiceUrls } from "../state/tree/global-configuration/reducers";
import { fetchJson } from "../utils/async";
import i18next from "i18next";
import { DetailsResponse } from "src/services/ngs/ngsAPITypes";

const fetchPoiByIdRequest = async (id: string, link: string): Promise<DetailsResponse> => {
    if (!id) {
        return undefined;
    }
    if (link === undefined) {
        return await details({
            id: id,
            language: i18next.language
        }).catch((error) => {return undefined});
    }

    return await detailsFromLink(link).catch((error) => {return undefined});
};

export const fetchPoiById = memoize(fetchPoiByIdRequest);

const fetchEvChargingAvailabilityRequest = async (
    chargingAvailabilityId: string,
    key: string,
    serviceUrls: ServiceUrls
) => {
    // chargingAvailability service can't use the web-sdk as the attributes between api version differs
    return chargingAvailabilityId
        ? await fetchJson(`${serviceUrls.chargingAvailabilityEndpoint}?key=${key}&id=${chargingAvailabilityId}`, {
              headers: {
                  "Content-Type": "application/json"
              },
              method: "GET"
          })
        : null;
};

const fetchEvChargingAvailability = memoize(fetchEvChargingAvailabilityRequest);

const useFetchPoi = (
    poiId: string,
    detailsLink: string,
    apiKey: string,
    serviceUrls: ServiceUrls
) => {
    const poiInfoRequest = useAsync(() => fetchPoiById(poiId, detailsLink), [poiId]);
    const poiInfo = poiInfoRequest?.value;
    const chargingAvailabilityId = poiInfo?.dataSources?.chargingAvailability?.id;
    const evChargingAvailabilityRequest = useAsync(
        () => fetchEvChargingAvailability(chargingAvailabilityId, apiKey, serviceUrls),
        [chargingAvailabilityId, apiKey]
    );

    return {
        poiLocationInfo: poiInfoRequest && detailsToTTMSearchResult(poiInfoRequest.value),
        evChargingAvailability: evChargingAvailabilityRequest?.value as EvPOI
    };
};

export default useFetchPoi;
