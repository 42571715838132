import React, { ReactNode, useEffect, useState } from "react";
import {
    BadgeCollection,
    BadgeGroup,
    bem,
    Body,
    ButtonContainer,
    IcArrowDown24,
    IcArrowRight24,
    IcClose24,
    IcDelete24,
    IcLab24,
    IcSettings24,
    IcStar24,
    ListsItem,
    qaId
} from "../../ui-library";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectAuthentication } from "../../state/tree/authentication/selectors";
import { useAppDispatch } from "../../state/RootReducer";
import { onClearData, onDownloadData } from "../../state/tree/map-page/settings/userActionThunks";
import {
    selectLegalLinks,
    selectFeatureConfigs,
    selectExternalLinks
} from "../../state/tree/global-configuration/selectors";
import { LabsLink, MyItemsLink, SettingsLink } from "../elements/Links";
import ConfirmationDialog from "../dialog/ConfirmationDialog";
import { navigateToMyItemsWithAuth } from "../../state/tree/navigation/thunks";
import { actions as applicationActions } from "../../state/tree/application/reducers";
import { TealiumLogger } from "../../classes/TealiumLogger";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import "./Menu.scss";

import packagejson from "../../../package.json";
import { ReactComponent as IcTomTomLogo } from "../../../resources/images/tomtom-logo.svg";
import { languageToAPILocale, supportedLocale } from "../../classes/localization/Localization";
import i18next from "i18next";

const mainMenu = bem("main-menu");
const mainMenuItem = bem("main-menu-item");
const mainMenuQa = qaId("main-menu");

type MenuItemProps = {
    icon: ReactNode;
    text: string;
    secondary?: boolean;
    hasArrowRight?: boolean;
};

const MenuItem = ({ icon, text, secondary, hasArrowRight = true }: MenuItemProps) => {
    return (
        <div className={classNames(mainMenuItem.block(), { [mainMenuItem.modifier("secondary")]: secondary })}>
            <div className={mainMenuItem.element("icon")}>{icon}</div>
            <Body size={"l"} className={mainMenuItem.element("text")}>
                {text}
            </Body>

            {hasArrowRight && <IcArrowRight24 className={mainMenuItem.element("icon")} />}
        </div>
    );
};

type MenuProps = {
    open?: boolean;
};

export const MenuContainer: React.FC<{ open: boolean; children?: React.ReactNode }> = (props) => (
    <div {...mainMenuQa.block()} className={classNames(mainMenu.block(), { [mainMenu.modifier("open")]: props.open })}>
        {props.children}
    </div>
);

const MenuCmp = (props: MenuProps) => {
    const [clearDataConfirmationOpen, setClearDataConfirmationOpen] = useState(false);
    const auth = useSelector(selectAuthentication);
    const dispatch = useAppDispatch();
    const featureConfigs = useSelector(selectFeatureConfigs);
    const { t, i18n } = useTranslation("Menu");

    useEffect(() => {
        if (!auth?.authenticated) {
            setClearDataConfirmationOpen(false);
        }
    }, [auth?.authenticated]);

    const closeMenu = (trackEvent?: boolean) => {
        if (trackEvent) {
            TealiumLogger.linkEventName("close_menu");
        }
        dispatch(applicationActions.setIsMenuOpen(false));
    };

    const handleMyItemsClick = async (e) => {
        if (!auth.authenticated) {
            e.preventDefault();
            dispatch(applicationActions.setLoginAnalyticsInfo({ method: "ttm", trigger: "my_items_menu" }));
            dispatch(navigateToMyItemsWithAuth());
            closeMenu();
        } else {
            closeMenu();
        }
    };

    const handleClearData = () => {
        dispatch(applicationActions.setLoginAnalyticsInfo({ method: "ttm", trigger: null }));
        dispatch(onClearData());
    };

    const handleDownloadData = () => {
        dispatch(onDownloadData());
    };

    const legalLinks = useSelector(selectLegalLinks);
    const externalLinks = useSelector(selectExternalLinks);

    return (
        <MenuContainer open={props.open}>
            <div
                {...mainMenuQa.element("overlay")}
                className={mainMenu.element("overlay")}
                onClick={() => closeMenu(true)}
            />
            <div {...mainMenuQa.element("side-bar")} className={classNames(mainMenu.element("side-bar"))}>
                <div className={mainMenu.element("content-wrapper")}>
                    <div className={mainMenu.element("header")}>
                        <IcTomTomLogo {...mainMenuQa.element("tomtom-logo")} />
                        <ButtonContainer
                            onClick={() => closeMenu(true)}
                            className={mainMenu.element("close")}
                            {...mainMenuQa.element("close")}
                        >
                            <IcClose24 className="btn-close" />
                        </ButtonContainer>
                    </div>

                    <div className={mainMenu.element("body")}>
                        <MyItemsLink
                            onClick={handleMyItemsClick}
                            className={mainMenu.element("link-to-panel")}
                            {...mainMenuQa.element("my-items")}
                        >
                            <MenuItem text={t("Menu:my-items.title")} icon={<IcStar24 />} />
                        </MyItemsLink>

                        <SettingsLink
                            onClick={() => closeMenu()}
                            className={mainMenu.element("link-to-panel")}
                            {...mainMenuQa.element("settings")}
                        >
                            <MenuItem text={t("Menu:settings.title")} icon={<IcSettings24 />} />
                        </SettingsLink>
                        {featureConfigs && (
                            <LabsLink
                                onClick={() => closeMenu()}
                                className={mainMenu.element("link-to-panel")}
                                {...mainMenuQa.element("tomtom-labs")}
                            >
                                <MenuItem text={t("Menu:lab.title")} icon={<IcLab24 />} />
                            </LabsLink>
                        )}
                    </div>

                    <div className={mainMenu.element("footer")}>
                        {auth?.authenticated && (
                            <>
                                <ButtonContainer
                                    onClick={() => setClearDataConfirmationOpen(true)}
                                    className={mainMenu.element("link-to-panel")}
                                    {...mainMenuQa.element("clear-data")}
                                >
                                    <MenuItem text={t("Menu:clear_data.button")} icon={<IcDelete24 />} secondary />
                                </ButtonContainer>

                                {clearDataConfirmationOpen && (
                                    <ConfirmationDialog
                                        title={t("Menu:clear_data.dialog_title")}
                                        // eslint-disable-next-line max-len
                                        description={t("Menu:clear_data.dialog_description")}
                                        yesButtonTitle={t("Menu:clear_data.dialog_yes")}
                                        noButtonTitle={t("Menu:clear_data.dialog_no")}
                                        onYesClicked={() => handleClearData()}
                                        onNoClicked={() => setClearDataConfirmationOpen(false)}
                                    />
                                )}

                                <ButtonContainer
                                    onClick={handleDownloadData}
                                    className={mainMenu.element("link-to-panel")}
                                    {...mainMenuQa.element("download-data")}
                                >
                                    <MenuItem
                                        text={t("Menu:download_data.button")}
                                        icon={<IcArrowDown24 />}
                                        secondary
                                        hasArrowRight={false}
                                    />
                                </ButtonContainer>
                            </>
                        )}

                        <div className={mainMenu.element("legal")} {...mainMenuQa.element("legal")}>
                            <Body>{t("Menu:version", { version: packagejson.version })}</Body>
                            <div className={mainMenu.element("legal-links")}>
                                <a
                                    href={`${legalLinks?.support}/${languageToAPILocale[
                                        i18next.language
                                    ].toLowerCase()}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    {...mainMenuQa.element("legal-support")}
                                >
                                    <Body className={mainMenu.element("legal-links-text")}>
                                        {t("Menu:legal_links.support")}
                                    </Body>
                                </a>
                                <Body>&nbsp;|&nbsp;</Body>
                                <a
                                    href={`${externalLinks?.tomtomURL}/${supportedLocale[i18next.language]}/${
                                        legalLinks?.legal
                                    }/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    {...mainMenuQa.element("legal-terms")}
                                >
                                    <Body className={mainMenu.element("legal-links-text")}>
                                        {t("Menu:legal_links.legal")}
                                    </Body>
                                </a>
                                <Body>&nbsp;|&nbsp;</Body>
                                <a
                                    href={`${externalLinks?.tomtomURL}/${supportedLocale[i18next.language]}/${
                                        legalLinks?.privacy
                                    }/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    {...mainMenuQa.element("legal-privacy")}
                                >
                                    <Body className={mainMenu.element("legal-links-text")}>
                                        {t("Menu:legal_links.privacy")}
                                    </Body>
                                </a>
                                <Body>&nbsp;|&nbsp;</Body>
                                <a
                                    href="/licenses.txt"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    {...mainMenuQa.element("licenses")}
                                >
                                    <Body className={mainMenu.element("legal-links-text")}>
                                        {t("Menu:legal_links.licenses")}
                                    </Body>
                                </a>
                            </div>
                        </div>

                        <div className={mainMenu.element("versions")} {...mainMenuQa.element("build")}>
                            <ListsItem title="Build Versions" asSubtitle noSidePadding />
                            <BadgeCollection className="main-menu__dev-info">
                                <BadgeGroup size={"small"} title="TTM" value={packagejson.version} />
                                <BadgeGroup
                                    size={"small"}
                                    title="maplibre-gl"
                                    value={packagejson.dependencies["maplibre-gl"]}
                                />
                                <BadgeGroup
                                    size={"small"}
                                    title="web-sdk-services"
                                    value={packagejson.dependencies["@tomtom-international/web-sdk-services"]}
                                />
                                <BadgeGroup
                                    size={"small"}
                                    title="ui-library"
                                    value={packagejson.dependencies["../../../ui-library/src"]}
                                />
                                <BadgeGroup
                                    size={"small"}
                                    title="gor-sdk"
                                    value={packagejson.dependencies["@anw/gor-sdk"]}
                                />
                                <BadgeGroup size={"small"} title="lang" value={i18n.language} />
                            </BadgeCollection>
                        </div>
                    </div>
                </div>
            </div>
        </MenuContainer>
    );
};

const Menu = (props: MenuProps) => (
    <IsomorphicSuspense fallback={<MenuContainer open={props.open} />}>
        <MenuCmp {...props} />
    </IsomorphicSuspense>
);

export default Menu;
