import React from "react";
import { createRoot } from "react-dom/client";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouterState } from "connected-react-router";
import { Feature, LineString, Point, Position } from "geojson";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import {
    AttributionControl,
    GeolocateControl,
    LayerSpecification,
    LngLat,
    LngLatBounds,
    LngLatBoundsLike,
    LngLatLike,
    MapGeoJSONFeature,
    NavigationControl,
    PointLike,
    Popup,
    ScaleControl,
    SymbolLayerSpecification,
    Unit
} from "maplibre-gl";
import {
    ActiveItinerary,
    ChargingInformation,
    CurveSection,
    IntervalSection,
    Itinerary,
    ItineraryPlanningResponse,
    Section,
    SectionType,
    UnitsType
} from "@anw/gor-sdk";
import { featureCollection, lineString } from "@turf/helpers";
import bbox from "@turf/bbox";
import rhumbBearing from "@turf/rhumb-bearing";
// TODO Use publicly exported BBox type from the package root, but not the distribution file
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import distance from "@turf/distance";
import i18next from "i18next";
import { withTranslation, WithTranslation } from "react-i18next";

import { Empty } from "../../ui-library";
import AbstractMapComponent from "./AbstractMapComponent";
import { AppDispatchProp, RootState } from "../../state/RootReducer";
import { actions as locationActions } from "../../state/tree/map-page/location/reducers";
import { actions as mapActions } from "../../state/tree/map-page/map/reducers";
import { actions as navigationActions } from "../../state/tree/navigation/reducers";
import { searchActions } from "../../state/tree/map-page/search/reducers";
import { RouteSelection } from "../../state/tree/map-page/planner/reducers";
import { updateUserLocation } from "../../state/tree/user/thunks";
import { actions as myItemsActions } from "../../state/tree/map-page/my-items";
import { actions as plannerActions, selectors as plannerSelectors } from "../../state/tree/map-page/planner";
import {
    navigateHome,
    navigateToLocation,
    navigateToRoutePlan,
    navigateToRouteView,
    updateWithViewport
} from "../../state/tree/navigation/thunks";
import {
    selectApiKey,
    selectServiceUrls,
    selectFeatureConfigs,
    selectSelectedMapStyle,
    selectSelectedMapStyleUrl
} from "../../state/tree/global-configuration/selectors";
import {
    actions as applicationActions,
    ForegroundOption,
    foregroundsChangingStartupViewport,
    RetryAction
} from "../../state/tree/application/reducers";
import { SearchIntention } from "../../state/tree/map-page/search/types";
import { retrievePositionFromURL, selectStartedWithPosition } from "../../state/tree/navigation/selectors";
import { onValueDiffer } from "../../utils/objects";
import { withRemovalAt } from "../../utils/lists";
import { sameCoordinates, toFixedLngLat } from "../../utils/numbers";
import {
    calculateAnchorForRoutePopup,
    findIndexForPopupThatIsNotClashingWithWaypoints,
    mostDivergentPointsOfTheRoutes,
    toIntervalSectionPointFeature,
    toRouteFeatures,
    toSectionFeatureCollection,
    toTrimmedPathFeature
} from "../../utils/route";
import LocationDetailsPopup, { HandleActionArg, locationPopup, PopupLocation } from "./LocationDetailsPopup";
import IncidentDetailsPopup, { IncidentDetailsInfo } from "./IncidentDetailsPopup";
import ChargingDetailsPopup from "./ChargingDetailsPopup";
import GeoJsonSource from "../../classes/map/GeoJsonSource";
import { BACKGROUND_STATE, HOVERED_STATE, SELECTED_STATE, STATE_PROP } from "../../map-layers";
import TTMSourcesAndLayers from "./TTMSourcesAndLayers";
import SourceWithLayers from "../../classes/map/SourceWithLayers";
import HoverClickEventsHandler, { ClickType } from "../../classes/map/HoverClickEventsHandler";
import { poiClassificationFromIconID, poiClassificationToIconID } from "../../utils/poiClassificationsMapping";
import {
    buildNgsLocationInfoHeading,
    getLocationInfo,
    getLocationInfoFromActiveItinerary,
    getPoint,
    isNonEmptyBBox,
    lngLatToFeature,
    locationInfosToMapBounds
} from "../../utils/location";
import { getLngLatPath, getWaypoints } from "../../utils/itinerary";
import {
    calcNewWaypointIndex,
    currentAffectedWaypoints,
    findIndexOfMatchingWaypoint,
    waypointIndexMappings
} from "../../utils/waypoint";
import {
    TTMLocation,
    TTMLocationContext,
    TTMSearchResult,
    TTMUserMapLocation,
    TTMWaypoint
} from "../../utils/locationTypes";
import {
    selectElectricVehicleConsumptionSettings,
    selectUnitsType
} from "../../state/tree/map-page/settings/selectors";
import { fetchInvertedGeometry } from "../../utils/locationGeometry";
import { getTrafficPopupDetailsAlongTheRoute, getTrafficPopupDetailsForIncidents } from "../../utils/traffic";
import {
    buildExcludeIconArrayFilterExpression,
    buildIconArrayFilterExpression,
    buildIconFilterExpression
} from "../../map-layers/boostedPOIs";
import DragEventsHandler, { DragEventType } from "../../classes/map/DragEventsHandler";
import {
    selectAwsUserLocationInformation,
    selectGeolocationAllowed,
    selectUserEntitledToRiderRoutes,
    selectUserLngLat,
    selectUserLngLatFromRecentButtonClick,
    selectUserLocationAccuracy
} from "../../state/tree/user/selectors";
import { selectFilteredMyPlaces, selectSelectedItinerary } from "../../state/tree/map-page/my-items/selectors";
import { TealiumLogger } from "../../classes/TealiumLogger";
import { logEventWithActiveMode } from "../../state/tree/application/thunks";
import CategoryShortcutPopup, { categoryShortcutPopup } from "./CategoryShortcutPopup";
import * as searchThunks from "../../state/tree/map-page/search/thunks";
import RoutePopup, { routePopupStyle } from "./RoutePopup";
import { selectActiveItinerary } from "../../state/tree/map-page/active-destination/selectors";
import { reverseGeocode } from "../map-page/SearchResults";
import { actions as notificationActions } from "../../state/tree/notification/reducers";
import { changeSelectedLocation, clearSelectedLocation } from "../../state/tree/map-page/location/thunks";
import { actions as suggestEditActions } from "../../state/tree/map-page/suggest-edit/reducers";
import {
    asSavedLocation,
    getMatchFromSavedLocations,
    getSelectedLocationAfterSavedPlacesChanged
} from "../../utils/myPlaces";
import { GeoJsonSourceWithLayers } from "../../classes/map/GeoJsonSourceWithLayers";
import {
    selectCurrentMapStyleEdit,
    selectHiddenPOIsCategoryGroupIconIDs,
    selectIsMyPlacesToggled,
    selectIsOtherPOIsToggled,
    selectScenicSegmentsToggled,
    selectRoadTripsToggled,
    selectTrafficFlowToggled,
    selectTrafficIncidentsToggled,
    selectRiderRoutesToggled
} from "../../state/tree/map-page/map-controls/selectors";
import { POICategoryGroupIconIDs } from "../../utils/mapControls";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { languageToMapLocale } from "../../utils/languageToMapLocale";
import { selectAuthentication } from "../../state/tree/authentication/selectors";
import { changeStyleQuickProps, diffMapStyleEdits } from "../../utils/mapStyle";
import { changeRouteSelection } from "../../state/tree/map-page/planner/thunks";
import { LayerWithSource } from "../../classes/map/LayerTypes";
import PublishedRoutePopup, { PublishedRoutePopupSummary } from "./PublishedRoutePopup";
import { ServiceSource } from "../../state/tree/global-configuration/reducers";
import { fetchCopyrightAction } from "../../state/tree/map-page/map/thunks";
import MapProvider from "../../classes/map/MapProvider";

import ic_map_planner_waypoint_start from "../../../resources/icons/map/waypoint-start.png";
import ic_map_planner_waypoint_stop from "../../../resources/icons/map/waypoint-stop.png";
import ic_map_planner_waypoint_finish from "../../../resources/icons/map/waypoint-finish.png";
import ic_map_planner_waypoint_soft from "../../../resources/icons/map/waypoint-soft.png";
import ic_map_planner_waypoint_start_background from "../../../resources/icons/map/waypoint-start-background.png";
import ic_map_planner_waypoint_stop_background from "../../../resources/icons/map/waypoint-stop-background.png";
import ic_map_planner_waypoint_finish_background from "../../../resources/icons/map/waypoint-finish-background.png";
import ic_map_planner_waypoint_charging_point from "../../../resources/icons/map/waypoint-charging-point.png";
import ic_map_planner_waypoint_charging_point_background from "../../../resources/icons/map/waypoint-charging-point-background.png";
import ic_clock from "../../../resources/icons/map/ic-clock.png";
import selection_pin from "../../../resources/icons/map/selection-pin.png";
import favourites_pin from "../../../resources/icons/map/favourites_pin.png";
import favourites_pin_selected from "../../../resources/icons/map/favourites_pin_selected.png";

import "maplibre-gl/dist/maplibre-gl.css";
import "./MapAttributionControl.scss";
import "./MapScale.scss";
import "./MapPopups.scss";
import nearestPointOnLine from "@turf/nearest-point-on-line";

const incidentsTags = [
    "id",
    "icon_category",
    "description",
    "delay",
    "road_type",
    "magnitude",
    "traffic_road_coverage",
    "clustered",
    "end_date",
    "left_hand_traffic",
    "probability_of_occurrence",
    "number_of_reports",
    "last_report_time",
    "road_category",
    "road_subcategory"
];

const incidentsTagsOrbis = [
    "id",
    "icon_category",
    "description",
    "delay",
    // TODO Unsupported tags in Orbis?
    //"road_type",
    //"magnitude",
    //"traffic_road_coverage",
    //"clustered",
    //"end_date",
    "left_hand_traffic",
    "probability_of_occurrence",
    "number_of_reports",
    "last_report_time",
    "road_category",
    "road_subcategory"
];

import { detailsToTTMSearchResult } from "../../utils/location";
import { fetchPoiById } from "../../hooks/useFetchPoi";

const mapStateToProps = (state: RootState) => ({
    // when language string changes in url, this property will be updated and component update hook will be called
    systemLanguage: i18next.language,
    isMapReady: state.mapPage.map.mapReady,
    apiKey: selectApiKey(state),
    mapDefaultLanguage: state.globalConfiguration.map.defaultLanguage,
    selectedMapStyle: selectSelectedMapStyle(state),
    selectedMapStyleUrl: selectSelectedMapStyleUrl(state),
    serviceUrls: selectServiceUrls(state),
    authenticated: selectAuthentication(state)?.authenticated,
    currentMapStyleEdit: selectCurrentMapStyleEdit(state),
    searchResults: state.mapPage.search.searchResultsOnMap,
    myPlaces: selectFilteredMyPlaces(state),
    areResultsOutsideViewport: state.mapPage.search.areResultsOutsideViewport,
    selectedLocation: state.mapPage.location.selectedLocation,
    selectedLocationFrom: state.mapPage.location.selectedFrom,
    filledWaypoints: plannerSelectors.selectFilledWaypoints(state),
    waypoints: plannerSelectors.selectWaypoints(state),
    waypointIndexMappings: plannerSelectors.selectWaypointIndexMappings(state),
    canRouteBePlanned: plannerSelectors.canRouteBePlanned(state),
    hoveredWaypointIndex: state.mapPage.planner.hoveredWaypointIndex,
    highlightWaypointIndex: state.mapPage.planner.highlightedWaypointIndex,
    plannedRoutesInfo: state.mapPage.planner.plannedRouteInformation,
    activeRouteIndex: state.mapPage.planner.routeSelection.index,
    plannedRoutesResponse: state.mapPage.planner.plannedRouteInformation.response,
    plannedRouteFeatures: state.mapPage.planner.plannedRouteInformation.responseFeatures,
    routeCalculationContext: state.mapPage.planner.routeCalculation.context,
    currentPlannerParams: state.mapPage.planner.currentPlannerParams,
    trafficIncidentsToggled: selectTrafficIncidentsToggled(state),
    trafficFlowToggled: selectTrafficFlowToggled(state),
    scenicSegmentsToggled: selectScenicSegmentsToggled(state),
    roadTripsToggled: selectRoadTripsToggled(state),
    riderRoutesToggled: selectRiderRoutesToggled(state),
    userEntitledToRiderRoutes: selectUserEntitledToRiderRoutes(state),
    isMyPlacesToggled: selectIsMyPlacesToggled(state),
    routerLocationSearch: (state.router as RouterState<{ search: string }>).location.search,
    routeSelection: plannerSelectors.selectRouteSelection(state),
    routePathSelection: plannerSelectors.selectRoutePathSelection(state),
    itineraryCollectionTilesURL: state.globalConfiguration.map.itineraryCollectionTilesURL,
    searchIntention: state.mapPage.search.matchedAutoCompleteSearch?.searchIntention,
    hoveredSearchResultIndex: state.mapPage.search.hoveredSearchResultIndex,
    highlightedSearchResultIndex: state.mapPage.search.highlightedSearchResultIndex,
    selectedResultFeatureIndex: state.mapPage.search.selectedResultFeatureIndex,
    searchInputValue: state.mapPage.search.inputString,
    foreground: state.application.foreground,
    previousForeground: state.application.previousForeground,
    categoryShortcutTriggered: state.mapPage.search.categoryShortcutTriggered,
    urlPosition: retrievePositionFromURL(state),
    unitsType: selectUnitsType(state),
    featureConfigs: selectFeatureConfigs(state),
    startedWithPosition: selectStartedWithPosition(state),
    userLngLat: selectUserLngLat(state),
    userLngLatFromRecentButtonClick: selectUserLngLatFromRecentButtonClick(state),
    locationAccuracy: selectUserLocationAccuracy(state),
    geoLocationAllowed: selectGeolocationAllowed(state),
    awsUserLocationInfo: selectAwsUserLocationInformation(state),
    selectedItinerary: selectSelectedItinerary(state),
    activeItinerary: selectActiveItinerary(state),
    hiddenPOIsCategoryGroupIconIDs: selectHiddenPOIsCategoryGroupIconIDs(state),
    isOtherPOIsToggled: selectIsOtherPOIsToggled(state),
    reachableRange: state.mapPage.planner.reachableRangeInformation.geoJson,
    electricVehicleConsumptionSettings: selectElectricVehicleConsumptionSettings(state)
});

type Props = WithTranslation & AppDispatchProp & ReturnType<typeof mapStateToProps> & RouteComponentProps;

// for creating a small view box around the point where category shortcut was clicked
// 0.1 and 0.15 are arbitrary values and can be changed based on testing, maybe use center with radius instead of bounding box
const CATEGORY_SHORTCUT_BOUNDING_BOX_DIFF_LAT = 0.1;
const CATEGORY_SHORTCUT_BOUNDING_BOX_DIFF_LNG = 0.15;

const ACCURATE_USER_LOCATION_ZOOM = 15;
const VAGUE_USER_LOCATION_ZOOM = 6;

const LEFT_MARGIN_PX = 450;
const OTHER_MARGINS_PX = 100;

export const geolocateControl = new GeolocateControl({
    positionOptions: { enableHighAccuracy: true, maximumAge: Infinity, timeout: 10000 },
    fitBoundsOptions: {
        zoom: ACCURATE_USER_LOCATION_ZOOM,
        offset: MapProvider.getCenteringOffset()
    },
    showAccuracyCircle: false
});

const toScaleUnits = (unitsType: UnitsType): Unit => (!unitsType || unitsType == "METRIC" ? "metric" : "imperial");

const attributionControl = new AttributionControl({ customAttribution: "...", compact: false });

const scaleControl = new ScaleControl({ maxWidth: 80, unit: "metric" });

const newPopupMouseWheelHandler =
    (popup: Popup) =>
    (ev: WheelEvent): void => {
        ev.preventDefault();
        popup.addClassName("popup-while-map-moving");
    };

const setLayersVisible = (layers: LayerSpecification[], visible: boolean): void => {
    const map = MapProvider.map;
    layers.forEach((layer) => map.setLayoutProperty(layer.id, "visibility", visible ? "visible" : "none"));
};

class MapCmp extends AbstractMapComponent<Props> {
    protected apiKey = this.props.apiKey;
    protected mapElementId = "map";

    private searchNeedsUpdate = true; // flag that will be checked to set the search to be updated.

    private sourcesAndLayers: TTMSourcesAndLayers;
    private readonly hoverClickHandler = new HoverClickEventsHandler();
    private readonly routeDragHandler = new DragEventsHandler();

    private locationPopup = new Popup({
        className: locationPopup.block(),
        closeOnClick: false,
        offset: 10,
        closeButton: false
    });
    private categoryShortcutPopup = new Popup({
        className: categoryShortcutPopup.block(),
        closeOnClick: false,
        offset: -45,
        closeButton: false,
        anchor: "bottom"
    });
    private routePopups = [] as Popup[];

    private popupLocation: PopupLocation = null;
    private indexOfLocationPopup = null;
    private locationPopupFromClick = false;
    private lastLeftClickHidPopupOrSelectedLocation = false;

    // timeout var used for showing popup when hovering over a search result from the list
    private searchResultHoverTimeoutForPopup = null;
    // timeout var used for showing highlighted pin when hovering over a search result from the list
    private searchResultHoverTimeoutForPins = null;

    private trafficIncidentPopup = new Popup({
        closeButton: false,
        closeOnClick: false,
        className: locationPopup.block(),
        offset: 15
    });

    private chargingDetailsPopup = new Popup({
        closeButton: false,
        closeOnClick: false,
        className: locationPopup.block(),
        offset: 15
    });

    private clickedTrafficIncidentFeature: MapGeoJSONFeature = undefined;
    private clickedChargingDetailsFeature: MapGeoJSONFeature = undefined;

    private publishedRoutePopup = new Popup({
        closeButton: false,
        closeOnClick: false,
        className: locationPopup.block(),
        offset: 15
    });

    private lastFetchedGeometryID: string = null;
    private lastFetchedGeometryZoom: number = null;

    // timeout handle to re-enable route popup interactions after the map stops moving:
    private routePopupsInteractionEnablingHandleID: number = null;

    // map generic traffic incident symbol layers (lazily-loaded):
    private trafficIncidentSymbolLayers = null as SymbolLayerSpecification[];
    // map generic traffic incident symbols visibility (only effective if the incidents themselves are visible):
    private trafficIncidentSymbolsVisible = true;

    // local control flag to indicate that the geolocate control button was just clicked
    // gets reset right when the geolocation control yields the next location result
    // (compensates for the lack of context for geolocation control events)
    private geolocationButtonJustClicked = false;

    private setMapAttribution(attribution: string, isOrbis: boolean) {
        const attributionWrapperEl = document.querySelector(".maplibregl-ctrl-attrib-inner");

        if (isOrbis) {
            attributionWrapperEl.innerHTML = attribution;

            return;
        }

        const attributionAnchor = document.createElement("a");

        attributionAnchor.innerHTML = attribution;
        attributionAnchor.setAttribute("href", "https://tomtom.com/product-attributions/");

        attributionWrapperEl.innerHTML = "";
        attributionWrapperEl.appendChild(attributionAnchor);
    }

    async componentDidMount() {
        const { lat, lng, zoom, bearing, pitch } = this.props.urlPosition;
        let initLat, initLng;
        let initZoom = zoom || VAGUE_USER_LOCATION_ZOOM;
        if (lat && lng) {
            // we have position from url
            initLng = lng;
            initLat = lat;
            this.props.dispatch(navigationActions.updateStartedWithPosition(true));
        } else if (!isEmpty(this.props.userLngLat)) {
            // we have likely a previous user location from local storage, while still allowing for geolocation:
            initLng = this.props.userLngLat[0];
            initLat = this.props.userLngLat[1];
            initZoom = ACCURATE_USER_LOCATION_ZOOM;
        } else if (!isEmpty(this.props.awsUserLocationInfo?.coordinates)) {
            // getting ip location from aws
            initLng = this.props.awsUserLocationInfo.coordinates[0];
            initLat = this.props.awsUserLocationInfo.coordinates[1];
        }
        this.initializeMap(this.props.selectedMapStyleUrl, initLat, initLng, {
            zoom: initZoom,
            pitch,
            bearing
        });
        const map = MapProvider.map;
        map.setTransformRequest(this.requestTransformer(this.props.featureConfigs.serviceSource));
        // update map position in URL and state
        this.updatePosition();
        this.addMapControls();
        map.once("styledata", this.onMapFirstStyleLoad);

        const copyRight = await this.props.dispatch(fetchCopyrightAction());

        this.setMapAttribution(copyRight.payload as string, !!this.props.selectedMapStyle.orbis as boolean);
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.isMapReady) {
            return;
        }

        const firstTimeMapReady = !prevProps.isMapReady && this.props.isMapReady;
        const map = MapProvider.map;

        onValueDiffer(prevProps.featureConfigs.serviceSource, this.props.featureConfigs.serviceSource, async (curr) => {
            map.setTransformRequest(this.requestTransformer(curr));
            const copyRight = await this.props.dispatch(fetchCopyrightAction());
            this.setMapAttribution(copyRight.payload as string, !!this.props.selectedMapStyle.orbis as boolean);
        });

        onValueDiffer(
            prevProps.systemLanguage,
            this.props.systemLanguage,
            // to be removed when/if sdk supports map style v2 localization
            () => this.localizeMap(),
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.unitsType,
            this.props.unitsType,
            (curr) => {
                scaleControl.setUnit(toScaleUnits(curr));
            },
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.selectedLocation,
            this.props.selectedLocation,
            (curr, prev) => {
                if (this.props.foreground == ForegroundOption.SELECTED_LOCATION) {
                    this.selectLocation(curr, prev);
                }
            },
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.foreground,
            this.props.foreground,
            (curr, prev) => this.handleForegroundChange(prev, curr),
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.urlPosition,
            this.props.urlPosition,
            this.handleRouterLocationSearchChange,
            firstTimeMapReady
        );
        onValueDiffer(prevProps.userLngLat, this.props.userLngLat, (curr) => {
            if (this.props.userLngLatFromRecentButtonClick) {
                this.focusOnPoint({ lng: curr[0], lat: curr[1] });
            }
        });
        onValueDiffer(prevProps.searchResults, this.props.searchResults, this.renderSearchMarkers, firstTimeMapReady);
        onValueDiffer(
            prevProps.activeItinerary,
            this.props.activeItinerary,
            this.renderActiveDestinationMarker,
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.myPlaces,
            this.props.myPlaces,
            (curr, prev) => {
                if (this.props.isMyPlacesToggled) {
                    this.renderMyPlacesMarkers(curr);
                }
                if (this.popupLocation?.location) {
                    const updatedLocation = getSelectedLocationAfterSavedPlacesChanged(
                        this.popupLocation?.location,
                        prev,
                        curr
                    );
                    if (updatedLocation.changed) {
                        this.renderLocationPopup(updatedLocation.location, this.popupLocation.waypointIndex);
                    }
                } else if (this.props.selectedLocation) {
                    // checking if the selected location has just been saved:
                    const updatedLocation = getSelectedLocationAfterSavedPlacesChanged(
                        this.props.selectedLocation,
                        prev,
                        curr
                    );
                    if (updatedLocation.changed) {
                        // We re-select the location which now should be changed after the My Places update:
                        this.selectLocation(updatedLocation.location);
                    }
                }
            },
            firstTimeMapReady
        );
        onValueDiffer(
            [prevProps.plannedRoutesResponse, prevProps.routeSelection] as [ItineraryPlanningResponse, RouteSelection],
            [this.props.plannedRoutesResponse, this.props.routeSelection] as [
                ItineraryPlanningResponse,
                RouteSelection
            ],
            (curr, prev) => this.renderAndFocusPlannedRouteLinesOnMap(prev, curr),
            firstTimeMapReady
        );

        onValueDiffer(prevProps.routePathSelection, this.props.routePathSelection, (curr) => {
            const activeRouteFeature = this.props.plannedRouteFeatures.features[this.props.routeSelection.index];
            if (activeRouteFeature) {
                this.sourcesAndLayers.routePathSelection.reconfigure(
                    toTrimmedPathFeature(
                        activeRouteFeature.geometry.coordinates,
                        curr.startPointIndex,
                        curr.endPointIndex
                    )
                );
            }
        });

        onValueDiffer(
            prevProps.routeCalculationContext,
            this.props.routeCalculationContext,
            (curr) => {
                if (curr === "DRAGGING") {
                    this.clearInteractionPopups();
                }
                this.hoverClickHandler.enable(curr !== "DRAGGING");
            },
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.waypoints,
            this.props.waypoints,
            (curr, prev) => this.renderAndFocusPlannerWaypoints(prev, curr),
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.hoveredWaypointIndex,
            this.props.hoveredWaypointIndex,
            this.hoverWaypoint,
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.hoveredSearchResultIndex,
            this.props.hoveredSearchResultIndex,
            this.hoverSearchResult,
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.highlightedSearchResultIndex,
            this.props.highlightedSearchResultIndex,
            (curr) => {
                // A selected result has priority over highlighted ones:
                if (!this.props.selectedLocation) {
                    this.highlightSearchResult(curr);
                }
            },
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.trafficIncidentsToggled,
            this.props.trafficIncidentsToggled,
            this.toggleTrafficIncidents,
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.scenicSegmentsToggled,
            this.props.scenicSegmentsToggled,
            (displayed) => this.sourcesAndLayers.scenicSegments.ensureAddedToMapWithVisibility(displayed),
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.roadTripsToggled,
            this.props.roadTripsToggled,
            (displayed) => this.sourcesAndLayers.roadTrips.ensureAddedToMapWithVisibility(displayed),
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.riderRoutesToggled,
            this.props.riderRoutesToggled,
            (displayed) => this.sourcesAndLayers.riderRoutes.ensureAddedToMapWithVisibility(displayed),
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.userEntitledToRiderRoutes,
            this.props.userEntitledToRiderRoutes,
            (entitled) => {
                if (!entitled) {
                    this.sourcesAndLayers.riderRoutes.setVisible(false);
                } else if (this.props.riderRoutesToggled) {
                    this.sourcesAndLayers.riderRoutes.setVisible(true);
                }
            },
            firstTimeMapReady
        );

        onValueDiffer(
            {
                detectCurves: prevProps.featureConfigs?.detectCurves
            },
            {
                detectCurves: this.props.featureConfigs?.detectCurves
            },
            () => this.renderRouteLinesOnMap([this.props.plannedRoutesResponse, this.props.routeSelection]),
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.featureConfigs?.boostedMapPOIsFromCategorySearch,
            this.props.featureConfigs?.boostedMapPOIsFromCategorySearch,
            (curr) => {
                this.boostMapPOIsFromSearch(curr ? this.props.searchResults : null);
            },
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.featureConfigs?.boostedMapPOIsFromSelectedLocation,
            this.props.featureConfigs?.boostedMapPOIsFromSelectedLocation,
            (curr) => this.boostMapPOIsFor(curr ? this.props.selectedLocation : null),
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.hiddenPOIsCategoryGroupIconIDs,
            this.props.hiddenPOIsCategoryGroupIconIDs,
            this.togglePOICategories,
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.isOtherPOIsToggled,
            this.props.isOtherPOIsToggled,
            this.togglePOICategories,
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.isMyPlacesToggled,
            this.props.isMyPlacesToggled,
            this.toggleMyPlaces,
            firstTimeMapReady
        );
        onValueDiffer(
            prevProps.trafficFlowToggled,
            this.props.trafficFlowToggled,
            this.toggleTrafficFlow,
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.selectedItinerary,
            this.props.selectedItinerary,
            (curr) => this.showSelectedUserItinerary(curr),
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.featureConfigs?.enableLDEV,
            this.props.featureConfigs?.enableLDEV,
            this.renderReachableRange,
            firstTimeMapReady
        );

        onValueDiffer(
            prevProps.reachableRange,
            this.props.reachableRange,
            this.renderReachableRange,
            firstTimeMapReady
        );

        // NOTE: we ensure we detect a change of map style if the previous one is already defined, to avoid infinite loops on init flows:
        if (prevProps.selectedMapStyleUrl) {
            onValueDiffer(prevProps.selectedMapStyleUrl, this.props.selectedMapStyleUrl, () => {
                map.once("styledata", () => {
                    this.toggleVectorLayers();
                    // When changing style with diff: true (new MapLibre 3.0 feature), we need a workaround minimal delay
                    // to further restore things after the style is changed (likely some MapLibre bug/glitch).
                    setTimeout(() => {
                        this.onMapStyleLoad();
                        map.once("styledata", () => this.props.dispatch(mapActions.setMapReady(true)));
                    });
                });
                this.props.dispatch(mapActions.setMapReady(false));
                map.setStyle(this.props.selectedMapStyleUrl);
            });
        }

        onValueDiffer(prevProps.currentMapStyleEdit, this.props.currentMapStyleEdit, (prev, curr) => {
            if (curr) {
                const mapStyleEditDiff = diffMapStyleEdits(prev, curr);
                Object.keys(mapStyleEditDiff).forEach((layerGroup) =>
                    changeStyleQuickProps(mapStyleEditDiff[layerGroup])
                );
            }
        });
    }

    private requestTransformer = (serviceSource: ServiceSource) => (url, resourceType) => {
        // (Taken from https://developer.tomtom.com/maps-sdk-web-js/functional-examples#examples,code,hover-effect.html)
        // This transformRequest property is used to add the `tags` parameter to the Vector Incident Tiles urls.
        // The `id` is an on-demand tag which in this example is needed to compare features.
        // https://developer.tomtom.com/traffic-api/traffic-api-documentation-traffic-incidents/vector-incident-tiles
        if (resourceType === "Tile" && url.indexOf("incidents") > -1) {
            const urlObj = new URL(url);
            const originalTags = new Set(
                urlObj.searchParams
                    .get("tags")
                    // remove [] from tags
                    .replaceAll(/[[\]]/g, "")
                    .split(",")
            );
            const newTags = new Set(serviceSource === "ORBIS" ? incidentsTagsOrbis : incidentsTags);

            for (const tag of originalTags) {
                if (!newTags.has(tag)) {
                    newTags.add(tag);
                }
            }

            const newTagsString = Array.from(newTags).join(",");
            // wrap tags with [] for Genesis (Global)
            urlObj.searchParams.set("tags", serviceSource === "ORBIS" ? newTagsString : `[${newTagsString}]`);

            return {
                url: decodeURI(urlObj.href)
            };
        }
    };

    private localizeMap = () => {
        const map = MapProvider.map;
        const mapStyle = map.getStyle();
        mapStyle.layers.forEach((layer) => {
            // tries to detect layers that has "text-field" can be localized
            // ex. "text-field": "{name}" or "text-field": ["get", "name"]
            const toBeLocalized =
                layer["layout"]?.["text-field"] === "{name}" ||
                (layer["layout"]?.["text-field"]?.length == 2 && layer["layout"]?.["text-field"]?.[1] == "name");
            // tries to detect layers that were "text-field" was localized already
            // ex. "text-field": ["coalesce", ["get", "name_en-GB"], ["get", "name"]]
            const isTextFieldAlreadyLocalized =
                isArray(layer["layout"]?.["text-field"]?.[2]) && layer["layout"]?.["text-field"]?.[2].includes("name");
            const layerNeedsLocalization = toBeLocalized || isTextFieldAlreadyLocalized;

            if (layerNeedsLocalization) {
                map.setLayoutProperty(layer.id, "text-field", [
                    "coalesce",
                    ["get", `name_${languageToMapLocale[this.props.systemLanguage] || "en-GB"}`],
                    ["get", "name"]
                ]);
            }
        });
    };

    private onMapStyleLoad = () => {
        const map = MapProvider.map;
        // Extra images to add to the map sprite:
        [
            ["waypoint_start", ic_map_planner_waypoint_start],
            ["waypoint_stop", ic_map_planner_waypoint_stop],
            ["waypoint_finish", ic_map_planner_waypoint_finish],
            ["waypoint_soft", ic_map_planner_waypoint_soft],
            ["waypoint_start_background", ic_map_planner_waypoint_start_background],
            ["waypoint_stop_background", ic_map_planner_waypoint_stop_background],
            ["waypoint_finish_background", ic_map_planner_waypoint_finish_background],
            ["waypoint_charging_point", ic_map_planner_waypoint_charging_point],
            ["waypoint_charging_point_background", ic_map_planner_waypoint_charging_point_background],
            ["clock", ic_clock],
            ["selection_pin", selection_pin],
            ["favourites_pin", favourites_pin],
            ["favourites_pin_selected", favourites_pin_selected]
        ].forEach(([id, image]) => {
            map.loadImage(image, (_, img: HTMLImageElement) => {
                if (!map.hasImage(id)) {
                    map.addImage(id, img);
                }
            });
        });

        // Initializing TTM's sources and layers:
        this.sourcesAndLayers = new TTMSourcesAndLayers(
            this.props.selectedMapStyle?.layerToRenderLinesUnder,
            this.props.itineraryCollectionTilesURL
        );
        this.sourcesAndLayers.ensureAddedToMap();
        this.hoverClickHandler.removeAll();
        this.routeDragHandler.removeAll();

        // Adding the sources and layers we want to interact with:
        this.hoverClickHandler.add([
            this.sourcesAndLayers.boostedPOIs,
            this.sourcesAndLayers.selectedSearchResult,
            this.sourcesAndLayers.hoveredSearchResult,
            this.sourcesAndLayers.searchResults,
            this.sourcesAndLayers.routeIncidents,
            this.sourcesAndLayers.mainStyleTrafficIncidents,
            this.sourcesAndLayers.mainStylePOIs,
            this.sourcesAndLayers.altRouteLines,
            this.sourcesAndLayers.contextMenuPin,
            this.sourcesAndLayers.activeDestination,
            this.sourcesAndLayers.myPlaces,
            this.sourcesAndLayers.routeTimeIntervals,
            this.sourcesAndLayers.routeDistanceIntervals,
            this.sourcesAndLayers.scenicSegments,
            this.sourcesAndLayers.roadTrips,
            this.sourcesAndLayers.riderRoutes
        ]);
        if (this.props.foreground !== ForegroundOption.ITINERARY_DETAILS) {
            this.hoverClickHandler.add([this.sourcesAndLayers.mainRouteLine, this.sourcesAndLayers.routeWaypoints]);
            this.routeDragHandler.add([this.sourcesAndLayers.mainRouteLine, this.sourcesAndLayers.routeWaypoints]);
        }

        this.routeDragHandler.add([this.sourcesAndLayers.altRouteLines]);
    };

    private addMapControls = () => {
        const map = MapProvider.map;
        map.addControl(attributionControl, "bottom-right");
        map.addControl(scaleControl, "bottom-right");
        scaleControl.setUnit(toScaleUnits(this.props.unitsType));
        map.addControl(new NavigationControl({ visualizePitch: true }), "bottom-right");
        map.addControl(geolocateControl, "bottom-right");

        // With a defensive delay, to ensure geolocateControl is in the DOM, we listen to clicks for it
        // (to compensate for lack of context in geolocate events)
        setTimeout(
            () =>
                document
                    .getElementsByClassName("maplibregl-ctrl-geolocate")[0]
                    .addEventListener("click", () => (this.geolocationButtonJustClicked = true)),
            1000
        );

        geolocateControl.on("geolocate", (event) => {
            if (this.props.startedWithPosition || foregroundsChangingStartupViewport.includes(this.props.foreground)) {
                // Workaround to avoid the geolocation control to move the map around:
                map.stop();
            }
            const coordinates = event.coords as GeolocationCoordinates;
            this.props.dispatch(
                updateUserLocation({
                    lngLat: [coordinates.longitude, coordinates.latitude],
                    accuracy: coordinates.accuracy,
                    userLngLatFromRecentButtonClick: this.geolocationButtonJustClicked
                })
            );
        });

        geolocateControl.on("error", (error) => {
            // @ts-ignore because the event is not defined
            console.warn("geolocateControl", error.message, error.code);
            this.props.dispatch(
                notificationActions.addPreDefinedNotification({
                    notificationType: "unknown-location"
                })
            );
            this.geolocationButtonJustClicked = false;
        });
    };

    private onMapFirstStyleLoad = () => {
        const map = MapProvider.map;
        this.onMapStyleLoad();

        this.hoverClickHandler.listenToUserEvents(this.onHover, this.onClick);
        this.routeDragHandler.listenToDragEvents(this.onRouteDrag);

        map.on("movestart", this.onMapMoveStart);
        map.on("moveend", this.onMapMoveEnd);

        if (this.props.geoLocationAllowed) {
            geolocateControl.trigger();
        }

        // There's a MapLibre bug where setting GeoJSON source data too early before the map is fully loaded might result in loaded-state issues.
        // (The bug should be fixed with the current release, but the issue seems reproducible in TTM)
        // see https://github.com/maplibre/maplibre-gl-js/issues/1693
        // The above affects mostly test instability but could affect runtime instability as well.
        // Therefore, here we wait for the map to be fully loaded to set the map-ready state.
        // (See similar setMapReady logic in this component)
        if (map.loaded()) {
            setTimeout(() => {
                this.props.dispatch(mapActions.setMapReady(true));
                this.exposedApi.mapInitTriggered = true;
            }, 500);
        } else {
            map.once("load", () => {
                this.props.dispatch(mapActions.setMapReady(true));
                this.exposedApi.mapInitTriggered = true;
            });
        }
        // Meanwhile, to avoid initial visual glitches with traffic and POI visibility, here we already initialize those:
        this.toggleVectorLayers();
    };

    private toggleVectorLayers() {
        this.toggleTrafficIncidents(this.props.trafficIncidentsToggled);
        this.toggleTrafficFlow(this.props.trafficFlowToggled);
        this.togglePOICategories();
    }

    private isPublishedRoutes(sourceWithLayers: SourceWithLayers): boolean {
        return (
            sourceWithLayers === this.sourcesAndLayers.scenicSegments ||
            sourceWithLayers === this.sourcesAndLayers.roadTrips ||
            sourceWithLayers === this.sourcesAndLayers.riderRoutes
        );
    }

    private onHover = (
        lngLat: LngLat,
        feature: MapGeoJSONFeature,
        sourceWithLayers: SourceWithLayers,
        longHover: boolean
    ) => {
        if (this.props.highlightedSearchResultIndex !== undefined) {
            this.props.dispatch(searchActions.highlightedSelectedResultIndex(null));
        }
        if (longHover) {
            // Existing popups cleanup as needed:
            if (!this.clickedTrafficIncidentFeature || (feature && feature !== this.clickedTrafficIncidentFeature)) {
                // We clear incidents popup if we're hovering on anything else (but keep it if we un-hover and it was clicked):
                this.trafficIncidentPopup.remove();
                this.clickedTrafficIncidentFeature = undefined;
            }
            if (!this.clickedChargingDetailsFeature || (feature && feature !== this.clickedChargingDetailsFeature)) {
                // We clear charging details popup if we're hovering on anything else (but keep it if we un-hover and it was clicked):
                this.clearChargingDetailsPopup();
            }
            if (!this.locationPopupFromClick) {
                // (We only cleanup an existing location popup here if it isn't locked by click)
                this.clearLocationPopup();
            }
            this.publishedRoutePopup.remove();
        }
        if (
            !longHover &&
            this.props.hoveredWaypointIndex !== undefined &&
            sourceWithLayers !== this.sourcesAndLayers.routeWaypoints
        ) {
            // Waypoint de-hovering:
            this.props.dispatch(plannerActions.hoverWaypointIndex(null));
            this.props.dispatch(plannerActions.highlightWaypointIndex(null));
        }
        if (
            !longHover &&
            this.props.hoveredSearchResultIndex !== undefined &&
            sourceWithLayers !== this.sourcesAndLayers.searchResults &&
            sourceWithLayers !== this.sourcesAndLayers.hoveredSearchResult
        ) {
            // SearchResult de-hovering:
            this.props.dispatch(searchActions.hoveredSelectedResultIndex(null));
        }

        if (longHover && sourceWithLayers === this.sourcesAndLayers.mainStylePOIs) {
            this.renderMapPOIPopup(feature as Feature<Point>);
        } else if (longHover && sourceWithLayers === this.sourcesAndLayers.routeIncidents) {
            this.renderAlongRouteIncidentDetailsPopup(feature, lngLat, "hover");
        } else if (longHover && sourceWithLayers === this.sourcesAndLayers.mainStyleTrafficIncidents) {
            this.renderGenericIncidentDetailsPopup(feature, lngLat, "hover");
        } else if (sourceWithLayers === this.sourcesAndLayers.routeWaypoints) {
            if (!longHover) {
                // Waypoint pin immediate hover:
                const hoveredWaypointIndex = feature.properties.index;
                if (this.props.hoveredWaypointIndex !== hoveredWaypointIndex) {
                    this.props.dispatch(plannerActions.hoverWaypointIndex(hoveredWaypointIndex));
                }
                if (this.props.highlightWaypointIndex !== hoveredWaypointIndex) {
                    this.props.dispatch(plannerActions.highlightWaypointIndex(hoveredWaypointIndex));
                }
            } else {
                // Waypoint pin long hover (show popup):
                this.renderWaypointPopup(feature as Feature<Point>);
            }
        } else if (
            sourceWithLayers === this.sourcesAndLayers.searchResults ||
            sourceWithLayers === this.sourcesAndLayers.hoveredSearchResult
        ) {
            if (longHover) {
                this.renderSearchResultPopup(feature as Feature<Point>, "hover");
            } else {
                // SearchResult pin immediate hover:
                const hoveredSearchResultIndex = feature.properties.index;
                // ignore if we are hovering over hover search result layer
                if (
                    sourceWithLayers !== this.sourcesAndLayers.hoveredSearchResult &&
                    this.props.hoveredSearchResultIndex !== hoveredSearchResultIndex
                ) {
                    this.props.dispatch(searchActions.hoveredSelectedResultIndex(hoveredSearchResultIndex));
                }
            }
        } else if (sourceWithLayers === this.sourcesAndLayers.myPlaces) {
            if (longHover) {
                this.renderSavedLocationPopup(feature);
            }
        } else if (longHover && this.isPublishedRoutes(sourceWithLayers)) {
            this.renderPublishedRoutePopup(feature.properties as PublishedRoutePopupSummary, lngLat);
        }
    };

    private clearInteractionPopups = () => {
        this.clearLocationPopup();
        this.clearTrafficIncidentPopup();
        this.clearChargingDetailsPopup();
        this.publishedRoutePopup.remove();
        this.categoryShortcutPopup.remove();
    };

    private hasAnyInteractionPopups = (): boolean =>
        this.locationPopup.isOpen() ||
        this.trafficIncidentPopup.isOpen() ||
        this.categoryShortcutPopup.isOpen() ||
        this.publishedRoutePopup.isOpen();

    private clearLocationPopup = () => {
        this.sourcesAndLayers.contextMenuPin.reconfigure([]);
        this.locationPopup.remove();
        this.indexOfLocationPopup = null;
        this.popupLocation = null;
    };

    private clearTrafficIncidentPopup = () => {
        this.trafficIncidentPopup.remove();
        this.clickedTrafficIncidentFeature = undefined;
    };

    private clearChargingDetailsPopup = () => {
        this.chargingDetailsPopup.remove();
        this.clickedChargingDetailsFeature = undefined;
    };

    private onClick = (
        lngLat: LngLat,
        feature: MapGeoJSONFeature,
        sourceWithLayers: SourceWithLayers,
        clickType: ClickType
    ) => {
        if (clickType === ClickType.LEFT_CLICK) {
            this.onLeftClick(lngLat, feature, sourceWithLayers);
        } else if (clickType === ClickType.DELAYED_LEFT_CLICK) {
            this.onDelayedLeftClick(lngLat, sourceWithLayers);
        } else {
            this.onRightClick(lngLat, feature, sourceWithLayers);
        }
    };

    private onLeftClick = (lngLat: LngLat, feature: MapGeoJSONFeature, sourceWithLayers: SourceWithLayers) => {
        // Upfront popups cleanup:
        this.lastLeftClickHidPopupOrSelectedLocation = this.hasAnyInteractionPopups();
        this.clearInteractionPopups();
        if (
            sourceWithLayers === this.sourcesAndLayers.searchResults ||
            sourceWithLayers === this.sourcesAndLayers.hoveredSearchResult
        ) {
            this.onSearchResultClick(feature);
        } else if (sourceWithLayers === this.sourcesAndLayers.selectedSearchResult) {
            this.clearSelectedLocation();
        } else if (sourceWithLayers === this.sourcesAndLayers.mainStylePOIs) {
            this.onMapPOIClick(feature);
        } else if (sourceWithLayers === this.sourcesAndLayers.mainStyleTrafficIncidents) {
            this.clearSelectedLocation();
            this.renderGenericIncidentDetailsPopup(feature, lngLat, "click_left");
            this.clickedTrafficIncidentFeature = feature;
        } else if (sourceWithLayers === this.sourcesAndLayers.routeIncidents) {
            this.clearSelectedLocation();
            this.renderAlongRouteIncidentDetailsPopup(feature, lngLat, "click_left");
            this.clickedTrafficIncidentFeature = feature;
        } else if (sourceWithLayers === this.sourcesAndLayers.routeWaypoints) {
            this.onRouteWaypointClick(feature);
        } else if (sourceWithLayers === this.sourcesAndLayers.altRouteLines) {
            this.onAltRouteClick(feature);
        } else if (sourceWithLayers === this.sourcesAndLayers.activeDestination) {
            this.onActiveDestinationClick(feature);
        } else if (sourceWithLayers === this.sourcesAndLayers.myPlaces) {
            this.onMyPlaceClick(feature);
        } else if (
            (sourceWithLayers === this.sourcesAndLayers.routeTimeIntervals ||
                sourceWithLayers === this.sourcesAndLayers.routeDistanceIntervals) &&
            this.props.featureConfigs.interactiveRouteMarkers
        ) {
            const featurePoint = feature.geometry as Point;
            this.renderCategoryShortcutPopup(new LngLat(featurePoint.coordinates[0], featurePoint.coordinates[1]));
        } else if (this.isPublishedRoutes(sourceWithLayers)) {
            this.props.dispatch(navigateToRouteView(feature.properties.id));
        } else {
            this.lastLeftClickHidPopupOrSelectedLocation =
                this.lastLeftClickHidPopupOrSelectedLocation || !!this.props.selectedLocation;
            this.clearSelectedLocation();
        }
        this.locationPopupFromClick = false;
    };

    private onDelayedLeftClick = (lngLat: LngLat, sourceWithLayers: SourceWithLayers) => {
        if (!sourceWithLayers && !this.lastLeftClickHidPopupOrSelectedLocation) {
            this.renderRevGeoPopup(lngLat);
            this.locationPopupFromClick = true;
        }
    };

    private onRightClick = (lngLat: LngLat, feature: MapGeoJSONFeature, sourceWithLayers: SourceWithLayers) => {
        if (!sourceWithLayers) {
            this.renderRevGeoPopup(lngLat);
        } else {
            this.sourcesAndLayers.contextMenuPin.reconfigure([]);
            // we handle right clicks on supported layers the same way as long hovers:
            this.onHover(lngLat, feature, sourceWithLayers, true);
        }
        this.locationPopupFromClick = true;
    };

    private async clearSelectedLocation() {
        // using it async/await because we want this logic to finish before we continue with other parts of the code and we needed to reuse the logic
        await this.props.dispatch(clearSelectedLocation());
    }

    private findResultIndex = (feature: MapGeoJSONFeature) => {
        const { hoveredSearchResultIndex } = this.props;
        let index;
        if (hoveredSearchResultIndex !== null && hoveredSearchResultIndex !== undefined) {
            index = hoveredSearchResultIndex;
        } else {
            index = feature.properties.index;
        }
        return index;
    };

    private onSearchResultClick = (feature: MapGeoJSONFeature) => {
        const { searchResults } = this.props;
        const index = this.findResultIndex(feature);
        const searchResult = searchResults[index];
        this.props.dispatch(
            logEventWithActiveMode({
                event_name: "select_on_map",
                selected_item: "location",
                method: "click_left"
            })
        );
        this.props.dispatch(
            logEventWithActiveMode({
                event_name: "search_result_selected",
                search_query_length: this.props.searchInputValue?.length,
                item_index: index,
                search_intent: "discovery",
                location_type: searchResult.type,
                method: "map"
            })
        );
        this.props.dispatch(searchActions.updateSelectedResultIndex(index));
        this.props.dispatch(
            changeSelectedLocation({
                location: {
                    context: TTMLocationContext.SEARCH_RESULT,
                    ...searchResult
                },
                selectedFrom: "MAP"
            })
        );
    };

    private onActiveDestinationClick = (feature: MapGeoJSONFeature) => {
        const { selectedLocation } = this.props;
        const featurePoint = (feature.geometry as Point).coordinates.map((coor) => toFixedLngLat(coor));
        const locationPoint = selectedLocation && getPoint(selectedLocation);
        if (
            selectedLocation?.context !== TTMLocationContext.ACTIVE_DESTINATION ||
            !isEqual([featurePoint[1], featurePoint[0]], locationPoint)
        ) {
            this.props.dispatch(
                changeSelectedLocation({
                    location: {
                        context: TTMLocationContext.ACTIVE_DESTINATION,
                        ...getLocationInfoFromActiveItinerary(this.props.activeItinerary)
                    },
                    selectedFrom: "MAP"
                })
            );
        } else {
            this.clearSelectedLocation();
        }
    };

    private onMyPlaceClick = (feature: MapGeoJSONFeature) => {
        const { selectedLocation } = this.props;
        const featurePoint = (feature.geometry as Point).coordinates.map((coor) => toFixedLngLat(coor));
        const locationPoint = selectedLocation && getPoint(selectedLocation);
        this.clearSelectedLocation();
        if (
            selectedLocation?.context !== TTMLocationContext.MY_PLACES ||
            !isEqual([featurePoint[1], featurePoint[0]], locationPoint)
        ) {
            this.props.dispatch(
                changeSelectedLocation({
                    location: {
                        context: TTMLocationContext.MY_PLACES,
                        ...this.props.myPlaces[feature.properties.index]
                    },
                    selectedFrom: "MAP"
                })
            );
        }
    };

    private onMapPOIClick = (feature: MapGeoJSONFeature) => {
        if (feature.properties?.id) {
            this.selectLocation(null);
            const poiCategory = poiClassificationFromIconID(feature.properties.icon);
            this.props.dispatch(
                logEventWithActiveMode({
                    event_name: "select_on_map",
                    selected_item: "poi",
                    poi_category: poiCategory,
                    method: "click_left"
                })
            );
            const featurePoint = feature.geometry as Point;
            // we artificially construct a location object to reuse the selected item mechanism:
            this.props.dispatch(
                changeSelectedLocation({
                    location: {
                        context: TTMLocationContext.MAP_POI,
                        type: "POI",
                        provider: "TOM_TOM",
                        // WORKAROUND: this is a poi-details-ID (additionalData API), not the final location ID
                        // (this could be improved in a future by using a custom field for it instead)
                        // (see LocationDetails component for further logic on this)
                        externalID: feature.properties.id,
                        poiName: feature.properties.title || feature.properties.name,
                        poiCategory: poiCategory,
                        formattedAddress: "",
                        point: [featurePoint.coordinates[1], featurePoint.coordinates[0]]
                    },
                    selectedFrom: "MAP"
                })
            );
        }
    };

    private renderRevGeoPopup = async (lngLat: LngLat) => {
        this.sourcesAndLayers.contextMenuPin.reconfigure(lngLatToFeature(lngLat));
        const locationInfo = await reverseGeocode(lngLat, this.props.apiKey, i18next.language, this.props.serviceUrls);
        const point = [lngLat.lat, lngLat.lng] as [number, number];
        this.renderLocationPopup({ ...locationInfo, point, context: TTMLocationContext.MAP_POINT });
    };

    private onRouteWaypointClick = (feature: MapGeoJSONFeature) => {
        // we want to ignore left click on soft waypoint
        if (feature?.properties.type === "SOFT" || feature?.properties.isChargingStop) {
            return;
        }
        const index = feature?.properties.index;
        const { waypoints } = this.props;
        this.props.dispatch(searchActions.clearSelectedResultIndex());
        if (this.props.selectedLocation?.context === TTMLocationContext.WAYPOINT) {
            // De-selecting waypoint
            // (order is important so in case of location details page shown we don't trigger search for address)
            this.props.dispatch(navigateToRoutePlan());
            this.props.dispatch(locationActions.setSelectedLocation(null));
        } else {
            this.props.dispatch(
                changeSelectedLocation({
                    location: {
                        ...waypoints[index],
                        context: TTMLocationContext.WAYPOINT
                    },
                    selectedFrom: "MAP"
                })
            );
            this.props.dispatch(
                logEventWithActiveMode({
                    event_name: "select_on_map",
                    selected_item: "waypoint",
                    number_stops: waypoints.length,
                    item_index: index,
                    method: "click_left"
                })
            );
            this.props.dispatch(navigateToLocation({ ...waypoints[index], context: TTMLocationContext.WAYPOINT }));
        }
    };

    private onAltRouteClick = (feature: MapGeoJSONFeature) => {
        if (this.props.foreground !== ForegroundOption.ROUTE_PLANNER) {
            // return to the route planner
            this.clearSelectedLocation();
            this.props.dispatch(
                logEventWithActiveMode({
                    event_name: "select_on_map",
                    selected_item: "route",
                    method: "click_left"
                })
            );
            this.props.dispatch(navigateToRoutePlan());
        } else {
            TealiumLogger.link({
                event_name: "alternative_route_selected",
                method: "map"
            });
            // because the alternate routes are added in order except main route we need to find
            // what indexes are in alternate routes and for those to find out we need to get the feature id
            // and we need index of the active route, feature id is always 0 or 1
            const featureOrder = +feature.id;
            switch (this.props.routeSelection?.index) {
                case 0:
                    // active route is 0 so we need to add 1 to get the proper index of the alternative route
                    this.props.dispatch(changeRouteSelection({ index: 1 + featureOrder, selectedFrom: "MAP" }));
                    break;
                case 1:
                    // active route is 1 so if the feature id is 0 we use that and if it is 1 we add 1 to get to the index 2
                    if (featureOrder === 0) {
                        this.props.dispatch(changeRouteSelection({ index: featureOrder, selectedFrom: "MAP" }));
                    } else {
                        this.props.dispatch(changeRouteSelection({ index: 1 + featureOrder, selectedFrom: "MAP" }));
                    }
                    break;
                case 2:
                    // active route is 2 so we can use feature ids as it is, 0 or 1
                    this.props.dispatch(changeRouteSelection({ index: featureOrder, selectedFrom: "MAP" }));
                    break;
            }
        }
    };

    private onRouteDrag = (
        draggedLngLat: LngLat,
        draggedFeature: MapGeoJSONFeature,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        draggedSourceWithLayers: SourceWithLayers<GeoJsonSource<any, any>>,
        dragType: DragEventType
    ): void => {
        if (dragType === "DRAG_END") {
            this.props.dispatch(plannerActions.endRouteDrag());
            if (this.props.canRouteBePlanned) {
                // we do a final re-planning of the route (should have instructions included in the response), for consolidation:
                this.props.dispatch(plannerActions.planRoute());
            }
        } else {
            if (dragType === "DRAG_BEGIN") {
                // Here we pre-compute the radius of the new soft waypoint based on current map viewport and a pixels radius
                const radius = this.pixelsToMeters(10, draggedLngLat);
                this.props.dispatch(plannerActions.setDraggingWaypointRadius(radius));

                if (draggedSourceWithLayers === this.sourcesAndLayers.routeWaypoints) {
                    // we're dragging an existing waypoint, so we pass its index
                    this.props.dispatch(plannerActions.setDraggingWaypointIndex(draggedFeature.properties.index));
                } else if (
                    this.sourcesAndLayers.mainRouteLine === draggedSourceWithLayers ||
                    this.sourcesAndLayers.altRouteLines === draggedSourceWithLayers
                ) {
                    const newWaypointIndex = calcNewWaypointIndex(
                        this.props.waypoints,
                        draggedLngLat.toArray(),
                        draggedSourceWithLayers.source.renderedFeatures.features[0]
                    ).value;
                    this.props.dispatch(
                        plannerActions.addWaypoint({
                            waypoint: {
                                type: "SOFT",
                                radius,
                                pointLatLon: [draggedLngLat[1], draggedLngLat[0]],
                                context: TTMLocationContext.MAP_POINT
                            },
                            method: "route_line_drag",
                            index: { value: newWaypointIndex, addMode: "INSERT" }
                        })
                    );
                    this.props.dispatch(
                        plannerActions.setDraggingWaypointIndex(newWaypointIndex != -1 ? newWaypointIndex : null)
                    );
                } else {
                    this.props.dispatch(
                        // Should not occur (dragging new waypoint from strange layer), defaulting to unsorted geo-input:
                        plannerActions.setDraggingWaypointIndex(null)
                    );
                }
            }

            this.props.dispatch(plannerActions.updateDraggingWaypoint([draggedLngLat.lat, draggedLngLat.lng]));
        }
    };

    // Calculates the amount of meters for the given amount of pixels and reference map coordinates.
    // NOTE: the reference coordinates have an impact due to the mercator projection.
    private pixelsToMeters = (numPixels: number, referenceLngLat: LngLat): number => {
        const map = MapProvider.map;
        const startPixels = map.project(referenceLngLat);
        const nextPointInPixels: PointLike = [startPixels.x, numPixels + startPixels.y];
        const nextPointLngLat = map.unproject(nextPointInPixels);
        return Math.ceil(
            distance([referenceLngLat.lng, referenceLngLat.lat], [nextPointLngLat.lng, nextPointLngLat.lat], {
                units: "meters"
            })
        );
    };

    private onMapMoveStart = () => {
        this.clearInteractionPopups();
        this.props.dispatch(searchActions.setSearchNeedsUpdate(false));
        // while the map moves we remove interactions from route popups to mitigate them blocking the user to keep zooming/panning:
        window.clearTimeout(this.routePopupsInteractionEnablingHandleID);
        for (const routePopup of this.routePopups) {
            routePopup.addClassName("popup-while-map-moving");
        }
    };

    private onMapMoveEnd = () => {
        this.updatePosition();
        // Once the map finishes moving, we re-enable interactions in route popups, with a delay:
        // (the delay helps mitigate route popups suddenly blocking panning while the user continuously does it in a way that it briefly stops the map in between drags)
        this.routePopupsInteractionEnablingHandleID = window.setTimeout(() => {
            for (const routePopup of this.routePopups) {
                routePopup.removeClassName("popup-while-map-moving");
            }
        }, 400);
    };

    protected onMapDestroy = () => {
        this.props.dispatch(mapActions.setMapReady(false));
    };

    private updateMapCenterAndBBox = () => {
        const map = MapProvider.map;
        const center = map.getCenter();
        this.props.dispatch(
            mapActions.updateMapCenter({
                lat: center.lat,
                lng: center.lng
            })
        );
        this.props.dispatch(
            mapActions.updateMapBBox(map.getBounds().toArray() as [[number, number], [number, number]])
        );
        this.props.dispatch(mapActions.updateMapViewableBBox(this.getViewableBBox()));
        if (this.searchNeedsUpdate) {
            this.props.dispatch(searchActions.setSearchNeedsUpdate(true));
        } else {
            this.searchNeedsUpdate = true;
        }
    };

    private getViewableBBox = (): [[number, number], [number, number]] => {
        const map = MapProvider.map;
        const bbox = map.getBounds();
        const swPoint = map.project(bbox.getSouthWest());

        const correctedSWCoords = map.unproject([LEFT_MARGIN_PX, swPoint.y - OTHER_MARGINS_PX]);
        const nePoint = map.project(bbox.getNorthEast());
        const correctedNECoords = map.unproject([nePoint.x - OTHER_MARGINS_PX, nePoint.y + OTHER_MARGINS_PX]);
        return [
            [correctedSWCoords.lng, correctedSWCoords.lat],
            [correctedNECoords.lng, correctedNECoords.lat]
        ];
    };

    private renderSearchMarkers = (results: TTMSearchResult[]) => {
        const map = MapProvider.map;
        this.clearInteractionPopups();
        this.sourcesAndLayers.searchResults.reconfigure(results);
        if (results.length > 0 && (this.props.categoryShortcutTriggered || this.props.areResultsOutsideViewport)) {
            map.fitBounds(locationInfosToMapBounds(results), this.centeringOptions());
            this.searchNeedsUpdate = false;
            this.props.dispatch(searchActions.changeAreResultsOutsideViewport(false));
            if (this.props.categoryShortcutTriggered) {
                // resetting after it was used
                this.props.dispatch(searchActions.setCategoryShortcutTriggered(false));
            }
        }
        this.boostMapPOIsFromSearch(results);
        this.toggleTrafficIncidentIcons();
    };

    private renderActiveDestinationMarker = (activeItinerary: ActiveItinerary) => {
        this.sourcesAndLayers.activeDestination.reconfigure(getLocationInfoFromActiveItinerary(activeItinerary) || []);
        // defensive logic to ensure that if the selected location is actually the active destination, we ensure to show it as selected on the map:
        if (this.props.selectedLocation?.context == TTMLocationContext.ACTIVE_DESTINATION) {
            this.selectActiveDestination(this.props.selectedLocation);
        }
    };

    private renderMyPlacesMarkers = (myPlaces: TTMUserMapLocation[]) => {
        !isNil(myPlaces) && this.sourcesAndLayers.myPlaces.reconfigure(myPlaces);
        // if we had a map popup for a selected location, we re-render it to ensure it stays updated inside (regarding favourites)
        // (we need to do it this way since it's not behaving like a state-connected react component)
        if (this.popupLocation) {
            this.renderLocationPopupInfo(this.popupLocation);
        }
    };

    private boostMapPOIsFromSearch = (results: TTMSearchResult[]) => {
        if (
            this.props.featureConfigs.boostedMapPOIsFromCategorySearch &&
            results &&
            this.props.searchIntention === SearchIntention.DISCOVERY
        ) {
            this.boostMapPOIsFor({ context: TTMLocationContext.SEARCH_RESULT, ...results[0] });
        } else {
            this.boostMapPOIsFor(null);
        }
    };

    private renderGenericIncidentDetailsPopup = (feature: MapGeoJSONFeature, lngLat: LngLat, method: string) => {
        this.props.dispatch(
            logEventWithActiveMode({
                event_name: "select_on_map",
                selected_item: "traffic_incident",
                method
            })
        );
        this.renderTrafficIncidentPopup(getTrafficPopupDetailsForIncidents(feature), lngLat);
    };

    private renderAlongRouteIncidentDetailsPopup = (feature: MapGeoJSONFeature, lngLat: LngLat, method: string) => {
        this.props.dispatch(
            logEventWithActiveMode({
                event_name: "select_on_map",
                selected_item: "traffic_along_the_route",
                method
            })
        );
        const { t } = this.props;
        this.renderTrafficIncidentPopup(getTrafficPopupDetailsAlongTheRoute(feature, t), lngLat);
    };

    private renderTrafficIncidentPopup = (details: IncidentDetailsInfo, lngLat: LngLat) => {
        this.clearInteractionPopups();
        const popupHtml = document.createElement("div");
        popupHtml.onwheel = newPopupMouseWheelHandler(this.trafficIncidentPopup);
        createRoot(popupHtml).render(
            <IncidentDetailsPopup details={details} handleMouseLeave={this.clearTrafficIncidentPopup} />
        );
        this.trafficIncidentPopup.setDOMContent(popupHtml).setLngLat(lngLat).addTo(MapProvider.map);
    };

    private renderPublishedRoutePopup = (summary: PublishedRoutePopupSummary, lngLat: LngLat) => {
        this.clearInteractionPopups();
        const popupHtml = document.createElement("div");
        popupHtml.onwheel = newPopupMouseWheelHandler(this.publishedRoutePopup);
        createRoot(popupHtml).render(
            <PublishedRoutePopup
                summary={summary}
                handleClick={(id) => this.props.dispatch(navigateToRouteView(id))}
                handleMouseLeave={this.publishedRoutePopup.remove}
                unitsType={this.props.unitsType}
            />
        );
        this.publishedRoutePopup.setDOMContent(popupHtml).setLngLat(lngLat).addTo(MapProvider.map);
    };

    private maxChargeInkWh = () =>
        this.props.plannedRoutesResponse?.plannedItineraries[this.props.activeRouteIndex]?.itinerary.vehicleParameters
            ?.electricVehicleConsumptionModel?.maxChargeInkWh ??
        this.props.electricVehicleConsumptionSettings?.maxChargeInkWh;

    private renderChargingInformationPopup = (
        details: ChargingInformation,
        lngLat: LngLat,
        remainingChargeAtArrivalInkWh: number
    ) => {
        this.clearInteractionPopups();
        const popupHtml = document.createElement("div");
        popupHtml.onwheel = newPopupMouseWheelHandler(this.chargingDetailsPopup);
        const root = createRoot(popupHtml);
        root.render(
            <ChargingDetailsPopup
                details={details}
                handleMouseLeave={this.clearChargingDetailsPopup}
                remainingChargeAtArrivalInkWh={remainingChargeAtArrivalInkWh}
                maxChargeInkWh={this.maxChargeInkWh()}
            />
        );
        this.chargingDetailsPopup.setDOMContent(popupHtml).setLngLat(lngLat).addTo(MapProvider.map);
    };

    private renderMapPOIPopup = (feature: Feature<Point>) => {
        this.indexOfLocationPopup = null;
        const poiCategory = poiClassificationFromIconID(feature.properties.icon);
        this.props.dispatch(
            logEventWithActiveMode({
                event_name: "select_on_map",
                selected_item: "poi",
                poi_category: poiCategory,
                method: "hover"
            })
        );
        if (feature.properties.id) {
            this.renderLocationPopup({
                externalID: feature.properties.id,
                poiName: feature.properties.name,
                point: [feature.geometry.coordinates[1], feature.geometry.coordinates[0]],
                type: "POI",
                formattedAddress: "",
                context: TTMLocationContext.MAP_POI
            });
        } else {
            // defensive check, should already be filtered in the events handler:
            this.clearLocationPopup();
        }
    };

    private renderWaypointPopup = async (feature: Feature<Point>) => {
        const hoveredWaypointIndex = feature.properties.index;
        this.indexOfLocationPopup = hoveredWaypointIndex;
        const waypoint = this.props.waypoints[hoveredWaypointIndex];
        if (waypoint) {
            if (waypoint.chargingInformation == null) {
                this.props.dispatch(
                    logEventWithActiveMode({
                        event_name: "select_on_map",
                        selected_item: "waypoint",
                        number_stops: this.props.waypoints.length,
                        item_index: hoveredWaypointIndex,
                        method: "hover"
                    })
                );
                this.renderLocationPopup(
                    {
                        ...waypoint,
                        ...(!waypoint.locationInfo && {
                            locationInfo: await reverseGeocode(
                                {
                                    lng: waypoint.pointLatLon[1],
                                    lat: waypoint.pointLatLon[0]
                                },
                                this.props.apiKey,
                                i18next.language,
                                this.props.serviceUrls
                            )
                        }),
                        context: TTMLocationContext.WAYPOINT
                    },
                    hoveredWaypointIndex
                );
            } else if (this.props.featureConfigs?.enableLDEV) {
                const latLng = new LngLat(waypoint.pointLatLon[1], waypoint.pointLatLon[0]);
                this.renderChargingInformationPopup(
                    waypoint.chargingInformation,
                    latLng,
                    waypoint.remainingChargeAtArrivalInkWh
                );
            }
        }
    };

    private renderSearchResultPopup = (feature: Feature<Point>, method: "hover" | "highlight") => {
        if (!feature) {
            return;
        }
        let hoveredSearchResultIndex;
        if (this.props.hoveredSearchResultIndex !== null && this.props.hoveredSearchResultIndex !== undefined) {
            // in case of hovered search result layer we want to pick the index from props
            hoveredSearchResultIndex = this.props.hoveredSearchResultIndex;
        } else {
            // this will handle highlighting popup
            hoveredSearchResultIndex = feature.properties.index;
        }
        this.indexOfLocationPopup = hoveredSearchResultIndex;
        const searchResult = this.props.searchResults[hoveredSearchResultIndex];
        this.props.dispatch(
            logEventWithActiveMode({
                event_name: "select_on_map",
                selected_item: "location",
                method
            })
        );
        this.renderLocationPopup({ ...searchResult, context: TTMLocationContext.SEARCH_RESULT });
    };

    private renderSavedLocationPopup = (feature: MapGeoJSONFeature) => {
        if (!feature) {
            return;
        }
        this.props.dispatch(
            logEventWithActiveMode({
                event_name: "select_on_map",
                selected_item: "saved_location",
                method: "hover"
            })
        );
        const userMapLocation = this.props.myPlaces[feature.properties.index];
        this.renderLocationPopup({ ...userMapLocation, context: TTMLocationContext.MY_PLACES });
    };

    private renderCategoryShortcutPopup = (lnglat: LngLat) => {
        const popupHtml = document.createElement("div");
        const root = createRoot(popupHtml);
        root.render(
            <CategoryShortcutPopup
                handleMouseClickPopup={this.clearInteractionPopups}
                handleMouseClickCategory={this.categoryShortcutClick}
                lnglat={lnglat}
            />
        );
        this.categoryShortcutPopup.setDOMContent(popupHtml).setLngLat(lnglat).addTo(MapProvider.map);
    };

    private categoryShortcutClick = (seg: TTMSearchResult, lnglat: LngLat) => {
        this.props.dispatch(searchActions.clearSearchResults());
        this.props.dispatch(navigateHome());
        const newBoundingBox: [[number, number], [number, number]] = [
            [
                lnglat.lng - CATEGORY_SHORTCUT_BOUNDING_BOX_DIFF_LNG,
                lnglat.lat - CATEGORY_SHORTCUT_BOUNDING_BOX_DIFF_LAT
            ],
            [lnglat.lng + CATEGORY_SHORTCUT_BOUNDING_BOX_DIFF_LNG, lnglat.lat + CATEGORY_SHORTCUT_BOUNDING_BOX_DIFF_LAT]
        ];
        // when the search checks for the map view box we trick it by setting it to view box close to the category shortcut click
        // this way map doesn't move but the search is using correct view box
        this.props.dispatch(mapActions.updateMapViewableBBox(newBoundingBox));
        this.props.dispatch(searchActions.setCategoryShortcutTriggered(true));
        this.props.dispatch(
            searchThunks.autocompleteSegmentClicked({
                boundingBox: newBoundingBox,
                autocompleteSegment: seg
            })
        );
    };

    private renderLocationPopup = (location: TTMLocation, waypointIndex?: number) => {
        // defensive clearing of rev-geo marker:
        if (location.context !== TTMLocationContext.MAP_POINT) {
            this.sourcesAndLayers.contextMenuPin.reconfigure([]);
        }

        const heading = buildNgsLocationInfoHeading(getLocationInfo(location));

        return this.renderLocationPopupInfo({
            location,
            title: heading.title,
            subtitle: heading.subtitle,
            locationFromSavedPlaces:
                asSavedLocation(location) || getMatchFromSavedLocations(location, this.props.myPlaces),
            waypointIndex
        });
    };

    private renderLocationPopupInfo = (popupLocation: PopupLocation) => {
        this.clearTrafficIncidentPopup();
        const popupHtml = document.createElement("div");
        popupHtml.onwheel = newPopupMouseWheelHandler(this.locationPopup);
        const totalChargingTimeInSeconds =
            popupLocation.waypointIndex === this.props.waypoints?.length - 1
                ? this.props.plannedRoutesResponse?.plannedItineraries[this.props.activeRouteIndex]?.itinerary
                      ?.totalChargingTimeInSeconds
                : undefined;

        const root = createRoot(popupHtml);
        root.render(
            <LocationDetailsPopup
                popupLocation={popupLocation}
                apiKey={this.props.apiKey}
                serviceUrls={this.props.serviceUrls}
                filledWaypointsLength={this.props.filledWaypoints.length}
                userLocation={this.props.userLngLat}
                unitsType={this.props.unitsType}
                plannerParams={this.props.currentPlannerParams}
                plannedRouteInfo={this.props.plannedRoutesInfo}
                activeRouteIndex={this.props.activeRouteIndex}
                locationAccuracy={this.props.locationAccuracy}
                onRouteActionClick={this.handleRouteAction}
                onOpenEditModal={this.handleLocationPopupOpenEditModal}
                onSetRetryAction={this.handleSetRetryAction}
                handleMouseEnter={this.onPointerEnteringLocationPopup}
                handleMouseLeave={this.clearLocationPopup}
                waypoints={this.props.waypoints}
                authenticated={this.props.authenticated}
                onCardHeadingClick={(location) => this.handleLocationPopupTitleClick(location)}
                onFavoriteClick={(location) => this.props.dispatch(myItemsActions.onAddPlacePopup(location))}
                onUnFavoriteClick={(savedLocation) =>
                    this.props.dispatch(
                        myItemsActions.onRemovePlaceFromPopup({ id: savedLocation.id, type: savedLocation.type })
                    )
                }
                remainingChargeAtArrivalInkWh={
                    this.props.waypoints[popupLocation.waypointIndex]?.remainingChargeAtArrivalInkWh
                }
                totalChargingTimeInSeconds={totalChargingTimeInSeconds}
                featureConfigs={this.props.featureConfigs}
                maxChargeInkWh={this.maxChargeInkWh()}
            />
        );
        const location = popupLocation.location;
        const point = getPoint(location);
        this.locationPopup.setDOMContent(popupHtml).setLngLat(new LngLat(point[1], point[0])).addTo(MapProvider.map);

        // adding fake class name so we could control popup offset better with css
        this.locationPopup.removeClassName("context-popup");
        this.locationPopup.removeClassName("search-pin");
        this.locationPopup.removeClassName("search-highlighted-pin");
        this.locationPopup.removeClassName("waypoint-circle");
        this.locationPopup.removeClassName("waypoint-pin");

        const locationInfo = getLocationInfo(location);

        if (location.context === TTMLocationContext.MAP_POINT) {
            this.locationPopup.addClassName("context-popup");
        } else if (location.context === TTMLocationContext.WAYPOINT) {
            if (popupLocation.waypointIndex === 0 || popupLocation.waypointIndex === this.props.waypoints.length - 1) {
                this.locationPopup.addClassName("waypoint-pin");
            } else {
                this.locationPopup.addClassName("waypoint-circle");
            }
        } else if (location.context === TTMLocationContext.SEARCH_RESULT) {
            if (
                this.props.highlightedSearchResultIndex !== null &&
                this.props.highlightedSearchResultIndex !== undefined &&
                this.props.searchResults[this.props.highlightedSearchResultIndex]?.externalID ===
                    locationInfo.externalID
            ) {
                this.locationPopup.addClassName("search-highlighted-pin");
            } else {
                this.locationPopup.addClassName("search-pin");
            }
        }

        this.popupLocation = popupLocation;
    };

    private handlerRoutePopupClick = (index: number) => {
        if (this.props.activeRouteIndex !== index) {
            TealiumLogger.link({
                event_name: "alternative_route_selected",
                method: "popup"
            });
            this.props.dispatch(changeRouteSelection({ index, selectedFrom: "MAP" }));
        }
    };

    private renderRoutePopups = (response: ItineraryPlanningResponse) => {
        this.routePopups.forEach((popup) => popup.remove());
        this.routePopups.length = 0;
        const routes = response?.plannedItineraries?.map((plannedItinerary) => plannedItinerary.itinerary);
        if (routes && routes?.length > 0) {
            let maxDistanceIndexes = [] as number[];
            if (routes?.length > 1) {
                maxDistanceIndexes = mostDivergentPointsOfTheRoutes(routes);
            } else {
                maxDistanceIndexes[0] = findIndexForPopupThatIsNotClashingWithWaypoints(routes[0]);
            }
            const lngLatPaths = [] as [number, number][][];
            const boundingBoxCoordinates = [] as [number, number][];
            routes?.forEach((itinerary) => {
                lngLatPaths.push(getLngLatPath(itinerary));
                boundingBoxCoordinates.push([itinerary.boundingBox.southWest[1], itinerary.boundingBox.southWest[0]]);
                boundingBoxCoordinates.push([itinerary.boundingBox.northEast[1], itinerary.boundingBox.northEast[0]]);
            });
            const mainAbsoluteBearing = Math.abs(
                rhumbBearing(lngLatPaths[0][0], lngLatPaths[0][lngLatPaths[0].length - 1])
            );
            const mainNorthSouth = mainAbsoluteBearing < 45 || mainAbsoluteBearing > 135;
            maxDistanceIndexes.forEach((maxDistanceIndex, index) => {
                // need to detect what type of route it is north-south or east-west by comparing start and end point
                const absoluteBearing = Math.abs(
                    rhumbBearing(
                        lngLatPaths[index][Math.max(0, maxDistanceIndex - 5)],
                        lngLatPaths[index][Math.min(lngLatPaths[index].length - 1, maxDistanceIndex + 5)]
                    )
                );
                const northSouth = absoluteBearing < 45 || absoluteBearing > 135;
                const anchor = calculateAnchorForRoutePopup(
                    northSouth,
                    index,
                    lngLatPaths,
                    maxDistanceIndexes,
                    !mainNorthSouth ? (bbox(lineString(boundingBoxCoordinates)) as BBox2d) : [null, null, null, null]
                );
                const routePopup = new Popup({
                    className: routePopupStyle.block(),
                    closeOnClick: false,
                    offset: 5,
                    closeButton: false,
                    anchor
                });
                const popupHtml = document.createElement("div");
                popupHtml.onwheel = newPopupMouseWheelHandler(routePopup);

                const root = createRoot(popupHtml);
                root.render(
                    <RoutePopup
                        lengthInMeters={routes[index].lengthInMeters}
                        durationInSeconds={routes[index].durationInSeconds}
                        routeIndex={index}
                        trafficDelayInSeconds={routes[index].trafficDelayInSeconds}
                        unitsType={this.props.unitsType}
                        clickHandler={this.handlerRoutePopupClick}
                    />
                );
                routePopup
                    .setDOMContent(popupHtml)
                    .setLngLat(
                        new LngLat(lngLatPaths[index][maxDistanceIndex][0], lngLatPaths[index][maxDistanceIndex][1])
                    );
                this.routePopups.push(routePopup);

                routePopup.addTo(MapProvider.map);
                this.props.activeRouteIndex === index
                    ? routePopup.addClassName(routePopupStyle.modifier("active"))
                    : routePopup.removeClassName(routePopupStyle.modifier("active"));
            });
        }
    };

    private renderRouteLinesOnMap = ([response, currRouteSelection]: [ItineraryPlanningResponse, RouteSelection]) => {
        this.clearInteractionPopups();
        this.sourcesAndLayers.routePathSelection.reconfigure([]);
        this.routePopups.forEach((popup) => popup.remove());
        const routes = response?.plannedItineraries?.map((plannedItinerary) => plannedItinerary.itinerary);
        let activeRouteFeature: Feature<LineString> = null;
        if (routes && routes?.length) {
            activeRouteFeature = this.props.plannedRouteFeatures.features[currRouteSelection.index];
            if (this.props.foreground !== ForegroundOption.ROUTE_PLANNER) {
                this.sourcesAndLayers.altRouteLines.reconfigure(activeRouteFeature);
                this.sourcesAndLayers.mainRouteLine.reconfigure([]);
            } else {
                this.sourcesAndLayers.altRouteLines.reconfigure(
                    withRemovalAt(this.props.plannedRouteFeatures.features, currRouteSelection.index)
                );
                this.sourcesAndLayers.mainRouteLine.reconfigure(activeRouteFeature);
                if (routes.length > 0 && this.props.routeCalculationContext !== "DRAGGING") {
                    this.renderRoutePopups(response);
                }
            }
        } else {
            // clear route
            this.sourcesAndLayers?.mainRouteLine?.reconfigure([]);
            this.sourcesAndLayers?.altRouteLines?.reconfigure([]);
        }
        const routePlannerForeground = this.props.foreground === ForegroundOption.ROUTE_PLANNER;

        const foregroundActiveLineString = routePlannerForeground ? activeRouteFeature?.geometry.coordinates : null;
        const foregroundActiveRoute: Itinerary = foregroundActiveLineString
            ? routes?.[currRouteSelection?.index]
            : null;

        this.renderPlannedRouteSections(
            foregroundActiveRoute,
            foregroundActiveLineString,
            "traffic",
            this.sourcesAndLayers.routeIncidents
        );
        this.renderPlannedRouteSections(
            foregroundActiveRoute,
            foregroundActiveLineString,
            "tollRoad",
            this.sourcesAndLayers.routeTollRoads
        );
        this.renderPlannedRouteSections(
            foregroundActiveRoute,
            foregroundActiveLineString,
            "ferry",
            this.sourcesAndLayers.routeFerries
        );
        this.renderPlannedRouteSections(
            foregroundActiveRoute,
            foregroundActiveLineString,
            "tunnel",
            this.sourcesAndLayers.routeTunnels
        );
        this.renderPlannedRouteSections(
            this.props.featureConfigs.detectCurves && foregroundActiveRoute,
            foregroundActiveLineString,
            "curve",
            this.sourcesAndLayers.routeCurves,
            (section: CurveSection) => foregroundActiveRoute.costModel !== "THRILLING" || !section.relatedToManeuvering
        );
        this.renderPlannedRouteSections(
            this.props.featureConfigs.detectCurves && foregroundActiveRoute,
            foregroundActiveLineString,
            "curvyStretch",
            this.sourcesAndLayers.routeCurvyStretches
        );

        const activeLineString = activeRouteFeature?.geometry.coordinates;
        const activeRoute = routes?.[currRouteSelection?.index];

        this.renderPlannedRouteIntervalMarkers(
            activeLineString,
            activeRoute?.segments[0].groupedSections.timeInterval,
            !!foregroundActiveRoute,
            this.props.unitsType,
            this.sourcesAndLayers.routeTimeIntervals
        );
        this.renderPlannedRouteIntervalMarkers(
            activeLineString,
            activeRoute?.segments[0].groupedSections.distanceInterval,
            !!foregroundActiveRoute,
            this.props.unitsType,
            this.sourcesAndLayers.routeDistanceIntervals
        );
    };

    private focusRouteLinesOnMap = (
        [prevResponse, prevRouteSelection]: [ItineraryPlanningResponse, RouteSelection],
        [response, currRouteSelection]: [ItineraryPlanningResponse, RouteSelection]
    ) => {
        if (
            this.props.routeCalculationContext === "DRAGGING" ||
            !response?.plannedItineraries ||
            !response.plannedItineraries.length
        ) {
            return;
        }
        const routesBBox = bbox(this.props.plannedRouteFeatures) as LngLatBoundsLike;
        const map = MapProvider.map;
        const mapBounds = map.getBounds();
        if (
            !prevResponse ||
            (currRouteSelection.selectedFrom === "DEFAULT" &&
                this.props.routeCalculationContext !== "MAP_POPUP_ACTION") ||
            (currRouteSelection.selectedAt > prevRouteSelection?.selectedAt &&
                currRouteSelection.selectedFrom === "PLANNER") ||
            (mapBounds.contains([routesBBox[0], routesBBox[1]]) && mapBounds.contains([routesBBox[2], routesBBox[3]]))
        ) {
            // New routes, alternative selections, or routes already within map viewport will be zoomed to fill in the whole route:
            map.fitBounds(routesBBox, {
                ...this.centeringOptions(),
                duration: 2000
            });
        } else {
            // Other routes will have the map zoom out just enough to see some of the active route line:
            const activeRouteFeature = this.props.plannedRouteFeatures.features[currRouteSelection.index];
            if (activeRouteFeature) {
                const nearestLinePoint = nearestPointOnLine(activeRouteFeature.geometry, [
                    map.getCenter().lng,
                    map.getCenter().lat
                ]).geometry.coordinates as [number, number];
                if (!mapBounds.contains(nearestLinePoint)) {
                    map.fitBounds(mapBounds.extend(nearestLinePoint), this.centeringOptions());
                }
            }
        }
    };

    private renderAndFocusPlannedRouteLinesOnMap = (
        [prevResponse, prevRouteSelection]: [ItineraryPlanningResponse, RouteSelection],
        [response, currRouteSelection]: [ItineraryPlanningResponse, RouteSelection]
    ) => {
        this.focusRouteLinesOnMap([prevResponse, prevRouteSelection], [response, currRouteSelection]);
        this.renderRouteLinesOnMap([response, currRouteSelection]);
    };

    private renderPlannedRouteSections = (
        route: Itinerary,
        lineStringPath: Position[],
        sectionType: SectionType,
        sourceAndLayers: GeoJsonSourceWithLayers,
        sectionFilter?: { (section: Section) }
    ) => {
        if (route) {
            sourceAndLayers.reconfigureData(
                toSectionFeatureCollection(route, lineStringPath, sectionType, sectionFilter)
            );
        } else {
            sourceAndLayers.reconfigureData(featureCollection([]));
        }
    };

    private renderPlannedRouteIntervalMarkers = (
        lineStringPath: Position[],
        intervals: IntervalSection[],
        foregroundRoute: boolean,
        unitsType: UnitsType,
        sourceWithLayers: GeoJsonSourceWithLayers<Feature<Point>>
    ) => {
        if (lineStringPath && intervals) {
            sourceWithLayers.reconfigureData(
                featureCollection(
                    intervals.map((interval) =>
                        toIntervalSectionPointFeature(lineStringPath, interval, foregroundRoute, unitsType)
                    )
                )
            );
        } else {
            sourceWithLayers.reconfigureData(featureCollection([]));
        }
    };

    private renderAndFocusPlannerWaypoints = (prev: TTMWaypoint[], curr: TTMWaypoint[]) => {
        const map = MapProvider.map;
        this.clearInteractionPopups();
        this.sourcesAndLayers.routeWaypoints.reconfigure(
            curr,
            this.props.waypointIndexMappings.waypointToPlannerInputIndexes
        );
        const prevFilledWaypoints = compact(prev);
        const currFilledWaypoints = compact(curr);
        if (
            this.props.routeCalculationContext != "DRAGGING" &&
            currFilledWaypoints.length === 1 &&
            prevFilledWaypoints.length <= 1 &&
            currFilledWaypoints[0].context != TTMLocationContext.MAP_POI &&
            currFilledWaypoints[0].context != TTMLocationContext.MAP_POINT
        ) {
            // only zoom into a single waypoint if we're not dragging it, nor we just had removed the second one
            this.focusOnLocation({ ...currFilledWaypoints[0], context: TTMLocationContext.WAYPOINT });
        } else if (currFilledWaypoints.length > 1 && currFilledWaypoints.length === curr.length) {
            const changedWaypoints = currentAffectedWaypoints(prevFilledWaypoints, currFilledWaypoints);
            const changedWaypointsBounds = locationInfosToMapBounds(
                changedWaypoints.map((waypoint) => waypoint.locationInfo)
            );
            const mapBounds = map.getBounds();
            if (
                !changedWaypointsBounds.isEmpty() &&
                (!mapBounds.contains(changedWaypointsBounds.getSouthWest()) ||
                    !mapBounds.contains(changedWaypointsBounds.getNorthEast()))
            ) {
                // we ensure that the waypoints that have been changed fit within the viewport:
                map.fitBounds(mapBounds.extend(changedWaypointsBounds), this.centeringOptions());
            }
        }
        // special case, we are editing selected itinerary, waypoints removed and no planned route
        // clearing route shown by selected itinerary trigger
        if (
            currFilledWaypoints.length < 2 &&
            this.props.selectedItinerary &&
            this.props.foreground === ForegroundOption.ROUTE_PLANNER &&
            !this.props.plannedRoutesInfo?.response
        ) {
            this.renderRouteLinesOnMap([null, null]);
        }
    };

    private hoverLayerSource = (
        index: number,
        source: GeoJsonSourceWithLayers<Feature<Point>, TTMLocation | TTMSearchResult | TTMWaypoint>,
        featureState: string
    ) => {
        source.source.renderedFeatures.features.forEach((feature: Feature<Point>) => {
            if (feature.properties.index === index && !feature.properties[STATE_PROP]) {
                feature.properties[STATE_PROP] = featureState;
            } else if (feature.properties[STATE_PROP] && feature.properties[STATE_PROP] === featureState) {
                delete feature.properties[STATE_PROP];
            }
        });
        source.reconfigureData();
    };

    private hoverWaypoint = (index: number) => {
        if (index !== undefined && index !== null) {
            const waypoint = this.props.waypoints[index];
            if (waypoint) {
                // it is a real waypoint
                this.hoverLayerSource(
                    this.props.waypoints.indexOf(waypoint),
                    this.sourcesAndLayers.routeWaypoints,
                    HOVERED_STATE
                );
            }
        } else {
            // removes hover state
            this.hoverLayerSource(index, this.sourcesAndLayers.routeWaypoints, HOVERED_STATE);
        }
    };

    private hoverSearchResult = (index: number) => {
        if (this.props.searchResults[index]) {
            // add search result to the hover layer
            this.sourcesAndLayers.hoveredSearchResult.reconfigure(this.props.searchResults[index]);
        } else {
            this.sourcesAndLayers.hoveredSearchResult.reconfigure([]);
        }
    };

    private highlightSearchResult = (index: number) => {
        this.selectSearchResult(this.props.searchResults[index], index);
        clearTimeout(this.searchResultHoverTimeoutForPopup);
        if (index != null) {
            this.searchResultHoverTimeoutForPopup = setTimeout(() => {
                this.renderSearchResultPopup(
                    this.sourcesAndLayers.searchResults.source.renderedFeatures.features[index],
                    "highlight"
                );
            }, 500);
        } else {
            this.clearLocationPopup();
        }
    };

    private renderReachableRange = () => {
        this.sourcesAndLayers.reachableRangeGeometry.reconfigure(
            this.props.featureConfigs?.enableLDEV ? this.props.reachableRange : []
        );
    };

    private selectLocation = async (location: TTMLocation, prevLocation?: TTMLocation) => {
        const similarThanPrevious =
            location &&
            prevLocation &&
            location.context === prevLocation.context &&
            sameCoordinates(getPoint(location), getPoint(prevLocation));

        if (!similarThanPrevious) {
            this.clearInteractionPopups();
            this.selectWaypoint(-1);
            this.selectSearchResult(null, null);
            this.selectMyPlace(null);
            this.selectActiveDestination(null);
        }
        if (location && Object.keys(location).length > 1) {
            if (location.context === TTMLocationContext.WAYPOINT) {
                this.selectWaypoint(findIndexOfMatchingWaypoint(this.props.waypoints, location));
            } else if (location.context === TTMLocationContext.MY_PLACES) {
                this.selectMyPlace(location);
            } else if (location.context === TTMLocationContext.ACTIVE_DESTINATION) {
                this.selectActiveDestination(location);
            } else {
                const searchResult = location as TTMSearchResult;
                const locationDetails = await fetchPoiById(searchResult.externalID, searchResult?.link);
                const locationFromDetails = detailsToTTMSearchResult(locationDetails);
                this.selectSearchResult(locationFromDetails, this.props.selectedResultFeatureIndex);
                location = locationFromDetails as TTMLocation;
            }
        }

        this.updateLocationGeometry(location);

        if (this.props.featureConfigs.boostedMapPOIsFromSelectedLocation) {
            this.boostMapPOIsFor(location);
        }
    };

    private updateLocationGeometry = async (location: TTMLocation) => {
        const map = MapProvider.map;
        const locationInfo = getLocationInfo(location);
        const geometryID = locationInfo?.geometryId;
        if (geometryID) {
            if (
                !this.sourcesAndLayers.locationGeometry.visible ||
                this.lastFetchedGeometryID !== geometryID ||
                (this.lastFetchedGeometryZoom < 15 && this.lastFetchedGeometryZoom < map.getZoom())
            ) {
                const countryOrNotZoomLevel = locationInfo.geographyEntityType?.startsWith("COUNTRY") ? 5 : 8;
                const geoOrNotZoomLevel = locationInfo.type === "GEOGRAPHY" ? countryOrNotZoomLevel : 20;
                const zoom =
                    this.lastFetchedGeometryID === geometryID && this.lastFetchedGeometryZoom
                        ? Math.min(Math.ceil(map.getZoom()), 15)
                        : geoOrNotZoomLevel;
                await this.fetchAndShowLocationGeometry(geometryID, zoom);
                this.lastFetchedGeometryID = geometryID;
                this.lastFetchedGeometryZoom = zoom;
            }
        } else {
            await this.fetchAndShowLocationGeometry(null, null);
            this.lastFetchedGeometryID = null;
            this.lastFetchedGeometryZoom = null;
        }
    };

    private boostMapPOIsFor = (location: TTMLocation) => {
        const map = MapProvider.map;
        if (map.getSource(this.sourcesAndLayers.boostedPOIs.source.id)) {
            this.sourcesAndLayers.boostedPOIs.ensureAddedToMapWithVisibility(!!location);
            if (location) {
                map.setFilter(
                    "boostedPOIs",
                    buildIconFilterExpression(poiClassificationToIconID[getLocationInfo(location)?.poiCategory])
                );
            }
        }
    };

    private togglePOICategories = () => {
        const map = MapProvider.map;
        const { isOtherPOIsToggled, hiddenPOIsCategoryGroupIconIDs } = this.props;
        // filter the hidden group icon IDs from the full group icon IDs array to get the displayed categories
        const displayedCategories = POICategoryGroupIconIDs.filter(
            (groupIconIds) =>
                !hiddenPOIsCategoryGroupIconIDs.some((hiddenIconIds) => isEqual(groupIconIds, hiddenIconIds))
        );

        // if "other" POIs toggle is on => exclude hidden categories from the POI layer
        // otherwise hide everything except the displayed categories
        const expression = isOtherPOIsToggled
            ? buildExcludeIconArrayFilterExpression(hiddenPOIsCategoryGroupIconIDs.flat())
            : buildIconArrayFilterExpression(displayedCategories.flat());
        map.setFilter("POI", expression);
    };

    private toggleMyPlaces = (displayed: boolean) => {
        this.sourcesAndLayers.myPlaces.ensureAddedToMapWithVisibility(displayed);
        // reconfigure my places to add/remove any place that changed while the layer was hidden.
        displayed && this.renderMyPlacesMarkers(this.props.myPlaces);
    };

    private fetchAndShowLocationGeometry = async (geometryID: string, zoom: number) => {
        if (!geometryID) {
            this.sourcesAndLayers.locationGeometry.reconfigureData(featureCollection([]));
        } else {
            const invertedMultiPolygon = await fetchInvertedGeometry(geometryID, zoom, this.props.apiKey);
            if (invertedMultiPolygon) {
                // (If any problem occurred, such as when processing a huge polygon, the returned inverted polygon might be null)
                this.sourcesAndLayers.locationGeometry.reconfigureData(featureCollection([invertedMultiPolygon]));
            }
        }
    };

    private selectWaypoint = (index: number) => {
        this.sourcesAndLayers.routeWaypoints.source.renderedFeatures.features.forEach((feature: Feature<Point>) => {
            if (feature.properties.index === index) {
                feature.properties[STATE_PROP] = SELECTED_STATE;
            } else if (feature.properties[STATE_PROP] && feature.properties[STATE_PROP] === SELECTED_STATE) {
                if (this.props.foreground !== ForegroundOption.ROUTE_PLANNER) {
                    // waypoint should be in background mode in this case after deselecting it
                    feature.properties[STATE_PROP] = BACKGROUND_STATE;
                } else {
                    delete feature.properties[STATE_PROP];
                }
            }
        });
        this.sourcesAndLayers.routeWaypoints.reconfigureData();
    };

    private selectActiveDestination = (activeDestination: TTMLocation) => {
        if (activeDestination) {
            this.sourcesAndLayers.activeDestination.source.renderedFeatures.features.forEach(
                (feature: Feature<Point>) => {
                    feature.properties[STATE_PROP] = SELECTED_STATE;
                }
            );
        } else {
            this.sourcesAndLayers.activeDestination.source.renderedFeatures.features.forEach(
                (feature: Feature<Point>) => {
                    delete feature.properties[STATE_PROP];
                }
            );
        }
        this.sourcesAndLayers.activeDestination.reconfigureData();
    };

    private selectMyPlace = (savedLocation: TTMLocation) => {
        const userMapLocation = savedLocation as TTMUserMapLocation;
        const index =
            userMapLocation && this.props.myPlaces.findIndex((place) => isEqual(place.id, userMapLocation.id));
        this.sourcesAndLayers.myPlaces.source.renderedFeatures.features.forEach((feature: Feature<Point>) => {
            if (feature.properties.index === index) {
                feature.properties[STATE_PROP] = SELECTED_STATE;
            } else if (feature.properties[STATE_PROP] && feature.properties[STATE_PROP] === SELECTED_STATE) {
                delete feature.properties[STATE_PROP];
            }
        });
        this.sourcesAndLayers.myPlaces.reconfigureData();
        savedLocation && this.props.selectedLocationFrom === "HTML_UI" && this.focusOnLocation(savedLocation);
    };

    private selectSearchResult = (result: TTMSearchResult, index: number) => {
        const searchResultsSource = this.sourcesAndLayers.searchResults;
        const selectedResultSource = this.sourcesAndLayers.selectedSearchResult;
        if (result) {
            const updatedFeatures = searchResultsSource.source.renderedFeatures.features.map((feat, i) =>
                i === index
                    ? {
                          ...feat,
                          properties: {
                              ...feat.properties,
                              state: SELECTED_STATE
                          }
                      }
                    : {
                          ...feat,
                          properties: {
                              ...feat.properties,
                              state: null
                          }
                      }
            );
            // first update the search results layer and than add a selected result, looks like it helps with icon drawing
            searchResultsSource.reconfigureData(featureCollection(updatedFeatures));
            // adding a timeout to try to make sure no race condition between two layers update, icon drawing
            clearTimeout(this.searchResultHoverTimeoutForPins);
            this.searchResultHoverTimeoutForPins = setTimeout(() => selectedResultSource.reconfigure(result), 100);
            // we zoom on the search result only if selected from the HTML UI and not in discovery mode:
            if (
                this.props.selectedLocationFrom === "HTML_UI" &&
                this.props.searchIntention != SearchIntention.DISCOVERY
            ) {
                searchResultsSource.reconfigure([]);
                this.focusOnLocation({ ...result, context: TTMLocationContext.SEARCH_RESULT });
            }
        } else {
            clearTimeout(this.searchResultHoverTimeoutForPins);
            selectedResultSource.reconfigure([]);
            this.props.searchResults.length && searchResultsSource.reconfigure(this.props.searchResults);
        }
    };

    private toggleTrafficIncidents = (visible: boolean) => {
        this.sourcesAndLayers.mainStyleTrafficIncidents.setVisible(visible);
        this.toggleTrafficIncidentIcons();
    };

    private toggleTrafficIncidentIcons = () => {
        if (this.props.trafficIncidentsToggled) {
            const show =
                this.sourcesAndLayers.searchResults.isEmptyOrHidden() &&
                this.sourcesAndLayers.mainRouteLine.isEmptyOrHidden();
            if (show != this.trafficIncidentSymbolsVisible) {
                setLayersVisible(this.getTrafficIncidentSymbolLayers(), show);
            }
            this.trafficIncidentSymbolsVisible = show;
        }
    };

    private getTrafficIncidentSymbolLayers = (): SymbolLayerSpecification[] => {
        if (!this.trafficIncidentSymbolLayers) {
            this.trafficIncidentSymbolLayers = MapProvider.map
                .getStyle()
                .layers.filter(
                    (layer: LayerWithSource) => layer.source == "vectorTilesIncidents" && layer.type === "symbol"
                ) as SymbolLayerSpecification[];
        }
        return this.trafficIncidentSymbolLayers;
    };

    private toggleTrafficFlow = (visible: boolean) => {
        this.sourcesAndLayers.mainStyleTrafficFlow.setVisible(visible);
    };

    private centeringOffset = () => MapProvider.getCenteringOffset(this.props.featureConfigs.routeTimeline);

    private centeringOptions = () => MapProvider.getCenteringOptions(this.props.featureConfigs.routeTimeline);

    private focusOnPoint = (center: LngLatLike) =>
        MapProvider.map.easeTo({ center, zoom: 16, offset: this.centeringOffset() });

    private focusOnLocation = (location: TTMLocation) => {
        const bbox = getLocationInfo(location)?.boundingBox;
        // If the result has a non-empty bounding box, we'll zoom to fit it in. Otherwise, we'll zoom to a default closeup level.
        if (isNonEmptyBBox(bbox)) {
            MapProvider.map.fitBounds(
                new LngLatBounds([bbox.southWest[1], bbox.southWest[0]], [bbox.northEast[1], bbox.northEast[0]]),
                this.centeringOptions()
            );
        } else {
            const point = getPoint(location);
            if (point) {
                this.focusOnPoint({ lng: point[1], lat: point[0] });
            }
        }
    };

    private showSelectedUserItinerary = (curr: Itinerary) => {
        if (curr) {
            this.clearInteractionPopups();
            const routeFeatures = toRouteFeatures([curr]);
            this.sourcesAndLayers.mainRouteLine.reconfigure(routeFeatures.features[0]);
            this.sourcesAndLayers.altRouteLines.reconfigure([]);
            const routesBBox = bbox(routeFeatures) as LngLatBoundsLike;
            MapProvider.map.fitBounds(routesBBox, { ...this.centeringOptions(), duration: 2000 });
            this.sourcesAndLayers.routeWaypoints.reconfigure(
                getWaypoints(curr),
                waypointIndexMappings(getWaypoints(curr)).waypointToPlannerInputIndexes
            );
        }
    };

    private reRenderLayers = (prev: ForegroundOption, curr: ForegroundOption) => {
        // there is a change from route planner to search or the other way around
        this.renderRouteLinesOnMap([this.props.plannedRoutesResponse, this.props.routeSelection]);
        if (curr === ForegroundOption.ROUTE_PLANNER) {
            // making sure we put waypoints out of background mode
            this.sourcesAndLayers.routeWaypoints.source.renderedFeatures.features.forEach((feature: Feature<Point>) => {
                if (feature.properties[STATE_PROP] === BACKGROUND_STATE) {
                    delete feature.properties[STATE_PROP];
                }
            });
            this.sourcesAndLayers.routeWaypoints.reconfigureData();
            this.sourcesAndLayers.searchResults.ensureAddedToMapWithVisibility(false);
        } else {
            // making sure we put waypoints in background mode
            this.sourcesAndLayers.routeWaypoints.source.renderedFeatures.features.forEach((feature: Feature<Point>) => {
                if (feature.properties[STATE_PROP] !== SELECTED_STATE) {
                    feature.properties[STATE_PROP] = BACKGROUND_STATE;
                }
            });
            this.sourcesAndLayers.routeWaypoints.reconfigureData();
            this.sourcesAndLayers.searchResults.ensureAddedToMapWithVisibility(true);
        }
    };

    private handleForegroundChange = (prev: ForegroundOption, curr: ForegroundOption) => {
        this.clearInteractionPopups();
        // handle change of the foreground
        if (!this.sourcesAndLayers) {
            // layers are not there yet
            return;
        }
        if (curr === ForegroundOption.ITINERARY_DETAILS) {
            this.sourcesAndLayers.searchResults.ensureAddedToMapWithVisibility(false);
            // showing the itinerary was already handled
            // removing interaction with itinerary in this case, altRouteLine is not used in this case
            this.hoverClickHandler.remove([this.sourcesAndLayers.mainRouteLine, this.sourcesAndLayers.routeWaypoints]);
            this.routeDragHandler.remove([this.sourcesAndLayers.mainRouteLine, this.sourcesAndLayers.routeWaypoints]);
            return;
        } else if (
            this.props.selectedItinerary ||
            prev === ForegroundOption.ITINERARY_DETAILS ||
            prev === ForegroundOption.SAVE_ROUTE
        ) {
            // there is itinerary details but we are not in details state, revert to normal search/planner state
            // adding interaction with itinerary in this case, altRouteLine is not used in this case
            this.hoverClickHandler.add([this.sourcesAndLayers.mainRouteLine, this.sourcesAndLayers.routeWaypoints]);
            this.routeDragHandler.add([this.sourcesAndLayers.mainRouteLine, this.sourcesAndLayers.routeWaypoints]);

            // return the planning waypoints
            this.sourcesAndLayers.routeWaypoints.reconfigure(
                this.props.waypoints,
                this.props.waypointIndexMappings.waypointToPlannerInputIndexes
            );
            this.sourcesAndLayers.mainRouteLine.reconfigure([]);
            this.reRenderLayers(prev, curr);
            this.props.dispatch(myItemsActions.setSelectedItinerary(null));
        }
        if (
            (prev === ForegroundOption.ROUTE_PLANNER && curr !== ForegroundOption.ROUTE_PLANNER) ||
            (prev !== ForegroundOption.ROUTE_PLANNER && curr === ForegroundOption.ROUTE_PLANNER)
        ) {
            this.reRenderLayers(prev, curr);
        }

        // Showing or hiding selected location on the map based on foreground mode changes:
        if (curr == ForegroundOption.SELECTED_LOCATION) {
            this.selectLocation(this.props.selectedLocation);
        } else if (prev == ForegroundOption.SELECTED_LOCATION) {
            this.selectLocation(null);
            if (this.props.featureConfigs.boostedMapPOIsFromSelectedLocation) {
                this.boostMapPOIsFor(null);
            }
        }
        this.toggleTrafficIncidentIcons();
    };

    private handleRouterLocationSearchChange = () => {
        const { lng, lat, bearing, pitch, zoom } = this.props.urlPosition;

        if (lng && lat && this.props.history.action === "POP") {
            MapProvider.map.easeTo({
                center: { lng, lat },
                ...(zoom && { zoom }),
                bearing: bearing || 0,
                pitch: pitch || 0
            });
        }
    };

    private updatePosition = async () => {
        const map = MapProvider.map;
        const { lat, lng } = map.getCenter();

        await this.props.dispatch(
            updateWithViewport({
                lat,
                lng,
                zoom: map.getZoom(),
                bearing: map.getBearing(),
                pitch: map.getPitch()
            })
        );

        this.updateMapCenterAndBBox();
    };

    private onPointerEnteringLocationPopup = () => {
        // making sure we still highlight the correct search result and waypoint in case
        // user hovered over something on his way to popup
        if (this.popupLocation?.location?.context === TTMLocationContext.SEARCH_RESULT) {
            this.props.dispatch(searchActions.hoveredSelectedResultIndex(this.indexOfLocationPopup));
        } else if (this.popupLocation?.location?.context === TTMLocationContext.WAYPOINT) {
            this.props.dispatch(plannerActions.hoverWaypointIndex(this.indexOfLocationPopup));
            this.props.dispatch(plannerActions.highlightWaypointIndex(this.indexOfLocationPopup));
        }
    };

    // handle creating/adding to route from poi popup card
    private handleRouteAction = ({ location, waypointIndex, actionSource, forcedIndex = null }: HandleActionArg) => {
        this.clearInteractionPopups();
        this.clearSelectedLocation();

        this.props.dispatch(
            plannerActions.handleRouteAction({
                location,
                actionSource,
                waypointIndex,
                forcedIndex
            })
        );
    };

    private handleLocationPopupTitleClick = (location: TTMLocation) => {
        this.clearInteractionPopups();
        this.props.dispatch(
            changeSelectedLocation({
                location,
                selectedFrom: "MAP"
            })
        );
    };

    private handleLocationPopupOpenEditModal = (point: [number, number]) => {
        this.clearInteractionPopups();
        this.props.dispatch(suggestEditActions.updateCoordsForEditedPoint(point));
        this.props.dispatch(suggestEditActions.setIsSuggestEditModalOpened(true));
    };

    private handleSetRetryAction = (action: RetryAction, point: [number, number]) => {
        this.clearInteractionPopups();
        this.props.dispatch(suggestEditActions.updateCoordsForEditedPoint(point));
        this.props.dispatch(suggestEditActions.setIsSuggestEditModalOpened(false));
        this.props.dispatch(applicationActions.setRetryAction(action));
    };

    render() {
        return <div role="figure" id={this.mapElementId} />;
    }
}

const Wrapped = withTranslation("Traffic")(connect(mapStateToProps)(withRouter(MapCmp)));
const MapComponent = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <Wrapped />
    </IsomorphicSuspense>
);

export default MapComponent;
