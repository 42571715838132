import React from "react";
import { UnitsType } from "@anw/gor-sdk";
import classNames from "classnames";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { CardHeading, Empty, qaId } from "../../ui-library";
import ImageCarousel from "../elements/ImageCarousel";
import { locationPopup } from "./LocationDetailsPopup";
import { formatDuration, formatMetersWithUnitsType } from "../../utils/units";
import placeholderImage from "../../../resources/images/carousel_placeholder.png";

const publishedRoutePopupQa = qaId("published-route-popup");

type Props = {
    summary: PublishedRoutePopupSummary;
    unitsType: UnitsType;
    handleMouseLeave: () => any;
    handleClick: (id: string) => void;
};

export type PublishedRoutePopupSummary = {
    id: string;
    name: string;
    coverImageUrl: string;
    lengthMeters: number;
    durationSeconds: number;
};

const PublishedRoutePopupCmp = (props: Props) => {
    const summary = props.summary;
    const images = [summary.coverImageUrl?.replace("%7Bsize%7D", "thumbnail") || placeholderImage];

    return (
        <div
            className={classNames(locationPopup.element("content"), {
                [locationPopup.modifier("carousel")]: images?.length > 0
            })}
            {...publishedRoutePopupQa.block()}
        >
            <ImageCarousel images={images} className={locationPopup.element("carousel")} />
            <div className={locationPopup.element("info")}>
                <CardHeading
                    {...publishedRoutePopupQa.element("heading")}
                    className={locationPopup.element("title-row")}
                    title={summary.name}
                    subtitle={`${formatMetersWithUnitsType(summary.lengthMeters, props.unitsType)} - ${formatDuration(
                        summary.durationSeconds
                    )}`}
                    small
                    noOverflow
                    isFavouriteInvisible={true}
                    onClick={() => props.handleClick(summary.id)}
                />
            </div>
        </div>
    );
};

const PublishedRoutePopup = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <PublishedRoutePopupCmp {...props} />
    </IsomorphicSuspense>
);

export default PublishedRoutePopup;
