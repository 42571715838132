import React, { useEffect, useRef, useState } from "react";
import { Empty, IcClose24, ButtonPrimary, ButtonSecondary } from "../../ui-library";
import Modal from "bootstrap/js/dist/modal";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import salesModalImg from "../../../resources/images/sales_modal.png";
import { Checkbox } from "../../ui-library/components/base/Toggles";
import { getLocalStorageBoolean, setLocalStorageValue } from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import "./SalesModal.scss";

const SalesModal = () => {
    const salesModalDivRef = useRef<HTMLDivElement>();
    const [showMore, setShowMore] = useState(false);
    const [checked, setChecked] = useState(false);
    const { t } = useTranslation("SalesModal");

    useEffect(() => {
        if (!getLocalStorageBoolean("isSalesModalAccepted")) {
            const modalSales = Modal.getOrCreateInstance(salesModalDivRef.current);
            modalSales.toggle();
        }
    }, []);

    const storeClosedSalesModal = () => {
        if (checked) {
            setLocalStorageValue("isSalesModalAccepted", true);
        }
    };

    return (
        <IsomorphicSuspense fallback={<Empty />}>
            <div
                className="sales-modal modal fade"
                id="salesModal"
                tabIndex={-1}
                aria-labelledby="salesModalLabel"
                aria-hidden="true"
                ref={salesModalDivRef}
            >
                <div className="sales-modal__dialog modal-dialog modal-dialog-centered" data-bs-focus="true">
                    <div className="sales-modal__content">
                        <div className="sales-modal__header">
                            <span
                                className="sales-modal__close-wrapper"
                                data-bs-dismiss="modal"
                                aria-label={t("SalesModal:close_button")}
                            >
                                <IcClose24 className="btn-close" />
                            </span>
                            <img
                                className="sales-modal__img"
                                src={salesModalImg}
                                alt={t("SalesModal:image_description")}
                            />
                        </div>
                        <div className="sales-modal__body">
                            {!showMore ? (
                                <div>
                                    <h5 className="sales-modal__title" id="salesModalLabel">
                                        {t("SalesModal:main_heading")}
                                    </h5>
                                    <p className="sales-modal__paragraph">{t("SalesModal:main_paragraph_1")}</p>
                                    <p className="sales-modal__paragraph sales-modal__paragraph--tick">
                                        <span className="sales-modal__tick">✓</span>&nbsp;
                                        {t("SalesModal:main_paragraph_2")}
                                    </p>
                                </div>
                            ) : (
                                <div>
                                    <h5 className="sales-modal__title" id="salesModalLabel">
                                        {t("SalesModal:show_more_heading")}
                                    </h5>
                                    <p className="sales-modal__paragraph">
                                        🗺️&nbsp;
                                        <span className="sales-modal__paragraph-intro">
                                            {t("SalesModal:show_more_paragraph_intro_1")}
                                        </span>
                                        &nbsp;
                                        {t("SalesModal:show_more_paragraph_main_1")}
                                    </p>
                                    <p className="sales-modal__paragraph">
                                        🚐&nbsp;
                                        <span className="sales-modal__paragraph-intro">
                                            {t("SalesModal:show_more_paragraph_intro_2")}
                                        </span>
                                        &nbsp;
                                        {t("SalesModal:show_more_paragraph_main_2")}
                                    </p>
                                    <p className="sales-modal__paragraph">
                                        🚚&nbsp;
                                        <span className="sales-modal__paragraph-intro">
                                            {t("SalesModal:show_more_paragraph_intro_3")}
                                        </span>
                                        &nbsp;
                                        {t("SalesModal:show_more_paragraph_main_3")}
                                    </p>
                                    <p className="sales-modal__paragraph">
                                        ⏰️&nbsp;
                                        <span className="sales-modal__paragraph-intro">
                                            {t("SalesModal:show_more_paragraph_intro_4")}
                                        </span>
                                        &nbsp;
                                        {t("SalesModal:show_more_paragraph_main_4")}
                                    </p>
                                    <p className="sales-modal__paragraph">
                                        🛣️&nbsp;
                                        <span className="sales-modal__paragraph-intro">
                                            {t("SalesModal:show_more_paragraph_intro_5")}
                                        </span>
                                        &nbsp;
                                        {t("SalesModal:show_more_paragraph_main_5")}
                                    </p>
                                    <p className="sales-modal__paragraph">
                                        📲&nbsp;
                                        <span className="sales-modal__paragraph-intro">
                                            {t("SalesModal:show_more_paragraph_intro_6")}
                                        </span>
                                        &nbsp;
                                        {t("SalesModal:show_more_paragraph_main_6")}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="sales-modal__footer">
                            <div className="sales-modal__button-wrapper">
                                {!showMore ? (
                                    <ButtonSecondary
                                        className="sales-modal__button sales-modal__button--secondary"
                                        text={t("SalesModal:show_more_heading")}
                                        onClick={() => setShowMore(true)}
                                    />
                                ) : (
                                    <ButtonSecondary
                                        className="sales-modal__button sales-modal__button--secondary"
                                        text={t("SalesModal:back_button")}
                                        onClick={() => setShowMore(false)}
                                    />
                                )}

                                <ButtonPrimary
                                    className="sales-modal__button sales-modal__button--primary"
                                    text={t("SalesModal:primary_button")}
                                    data-bs-dismiss="modal"
                                    onClick={storeClosedSalesModal}
                                />
                            </div>

                            {!showMore && (
                                <Checkbox
                                    className="sales-modal__checkbox"
                                    text={t("SalesModal:checkbox")}
                                    checked={checked}
                                    onChange={({ currentTarget }) => {
                                        setChecked(currentTarget.checked);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </IsomorphicSuspense>
    );
};

export default SalesModal;
