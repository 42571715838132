/* eslint-disable max-len */
export const enum EngineTypeValue {
    Combustion = "COMBUSTION",
    Electric = "ELECTRIC"
}

const mspIdToNameMap = {
    "0": "e-tron Charging",
    "1": "Porsche Charging Service",
    "2": "We Charge",
    "3": "Electrify America"
};

export const getMSPName = (id: string): string => mspIdToNameMap[id] ?? id;

const plugTypes = [
    "IEC_62196_Type_2_Outlet",
    "IEC_62196_Type_2_Connector_Cable_Attached",
    "Combo_to_IEC_62196_Type_2_Base",
    "Combo_to_IEC_62196_Type_1_Base",
    "IEC_62196_Type_1_Connector_Cable_Attached",
    "SAE_J1772"
];

export const defaultVehicleParameters = {
    unitsType: "METRIC",
    hazmatOptionsSelection: new Array(12).fill(false),
    engineType: EngineTypeValue.Electric,
    electricVehicleConsumptionSettings: {
        constantSpeedConsumptionInkWhPerHundredkm:
            "0.0,23.9:27.0,23.9:45.0,25.9:60.0,19.6:75.0,20.7:90.0,23.8:100.0,26.0:110.0,26.0:120.0,29.6:130.0,33.7:250.0,33.7",
        minChargeAtDestinationInPercentage: 10,
        minChargeAtChargingStopsInPercentage: 10,
        maxChargeInkWh: 82.9,
        currentChargeInPercentage: 41,
        auxiliaryPowerInkW: 1.8
    },
    combustionVehicleConsumptionModel: {
        constantSpeedConsumptionInLitersPerHundredkm: "32,9.5:60,10.01",
        currentFuelInLiters: 20
    },
    hazmatOptions: [],
    chargingParameters: {
        batteryCurve: [
            {
                stateOfChargeInkWh: 1,
                maxPowerInkW: 140
            },
            {
                stateOfChargeInkWh: 2,
                maxPowerInkW: 190
            },
            {
                stateOfChargeInkWh: 51.4,
                maxPowerInkW: 180
            },
            {
                stateOfChargeInkWh: 55.5,
                maxPowerInkW: 160
            },
            {
                stateOfChargeInkWh: 59.7,
                maxPowerInkW: 140
            },
            {
                stateOfChargeInkWh: 67.15,
                maxPowerInkW: 120
            },
            {
                stateOfChargeInkWh: 67.98,
                maxPowerInkW: 100
            },
            {
                stateOfChargeInkWh: 69.64,
                maxPowerInkW: 80
            },
            {
                stateOfChargeInkWh: 75.44,
                maxPowerInkW: 60
            },
            {
                stateOfChargeInkWh: 81.24,
                maxPowerInkW: 40
            }
        ],
        chargingConnectors: [
            {
                currentType: "AC3",
                plugTypes,
                efficiency: 0.9,
                baseLoadInkW: 0.4,
                maxPowerInkW: 11
            },
            {
                currentType: "DC",
                plugTypes,
                efficiency: 0.9,
                baseLoadInkW: 0.4
            }
        ],
        chargingTimeOffsetInSec: 600
    },
    mspSettings: [
        { id: "0", enabled: true },
        { id: "1", enabled: true },
        { id: "2", enabled: true },
        { id: "3", enabled: true }
    ],
    vehicleModelId: null
};

export type EvModel = { uuid: string; name: string; powerKW: number };

export const evModels: EvModel[] = [
    { uuid: "EB8595AB-5A16-4683-9628-67B9AF23E686", name: "Volkswagen ID.3 Pro (58 kWh)", powerKW: 58 },
    { uuid: "778A4A76-E285-11EC-8FEA-0242AC120002", name: "Volkswagen ID.3 Pro (77 kWh)", powerKW: 77 },
    { uuid: "3350BF14-E287-11EC-8FEA-0242AC120002", name: "Volkswagen ID.4 Pro (52 kWh)", powerKW: 52 },
    { uuid: "A60F4264-E287-11EC-8FEA-0242AC120002", name: "Volkswagen ID.4 Pro (77 kWh)", powerKW: 77 },
    { uuid: "D828EBDA-E28A-11EC-8FEA-0242AC120002", name: "Audi E-tron", powerKW: 82.9 },
    { uuid: "54B969E8-E28D-11EC-8FEA-0242AC120002", name: "Peugeot e2008", powerKW: 45 },
    { uuid: "75B5EC7A-E28D-11EC-8FEA-0242AC120002", name: "Fiat 500 HR", powerKW: 37.8 }
];

export const engineType = {
    combustion: { id: EngineTypeValue.Combustion, label: "Combustion" },
    electric: { id: EngineTypeValue.Electric, label: "Electric" }
};

type EvConsumptionModelParameter = {
    id: string;
    label: string;
    type: "text" | "number";
    mandatory: boolean;
    min?: number;
    max?: number;
};

export const evConsumptionModelParameters: EvConsumptionModelParameter[] = [
    { id: "maxChargeInkWh", label: "Max Charge (kWh)", type: "number", mandatory: true },
    {
        id: "constantSpeedConsumptionInkWhPerHundredkm",
        label: "Constant Speed Consumption (kWh/100km)",
        type: "text",
        mandatory: true
    },
    {
        id: "currentChargeInPercentage",
        label: "Current Charge (%)",
        type: "number",
        mandatory: true,
        min: 0,
        max: 100
    },
    {
        id: "minChargeAtDestinationInPercentage",
        label: "Min Charge At Destination (%)",
        type: "number",
        mandatory: true,
        min: 0,
        max: 100
    },
    {
        id: "minChargeAtChargingStopsInPercentage",
        label: "Min Charge At Charging Stops (%)",
        type: "number",
        mandatory: true,
        min: 0,
        max: 100
    },
    { id: "auxiliaryPowerInkW", label: "Auxiliary Power (kW)", type: "number", mandatory: false },
    { id: "chargeMarginsInkWh", label: "Charge Margins (kWh)", type: "number", mandatory: false },
    {
        id: "consumptionInkWhPerkmAltitudeGain",
        label: "Consumption Altitude Gain (kWh/km)",
        type: "number",
        mandatory: false
    },
    {
        id: "recuperationInkWhPerkmAltitudeLoss",
        label: "Recuperation Altitude Loss (kWh/km)",
        type: "number",
        mandatory: false
    },
    { id: "accelerationEfficiency", label: "Acceleration Efficiency", type: "number", mandatory: false },
    { id: "decelerationEfficiency", label: "Deceleration Efficiency", type: "number", mandatory: false },
    { id: "uphillEfficiency", label: "Uphill Efficiency", type: "number", mandatory: false },
    { id: "downhillEfficiency", label: "Downhill Efficiency", type: "number", mandatory: false }
];

export const combustionConsumptionModelParameters = [
    {
        id: "constantSpeedConsumptionInLitersPerHundredkm",
        label: "Constant Speed Consumption (liters/100km)",
        type: "text",
        mandatory: true
    },
    { id: "currentFuelInLiters", label: "Current fuel (liters)", type: "number", mandatory: true }
];

export const defaultReachableRangeState = {
    showReachableRange: false,
    showDetailedRange: false
};
