import React from "react";
import classNames from "classnames";
import { ItineraryPlanningRequest, UnitsType } from "@anw/gor-sdk";
import { useTranslation } from "react-i18next";

import { bem, Body, CardHeading, Empty, qaId } from "../../ui-library";
import useFetchPoi from "../../hooks/useFetchPoi";
import { TTMLocation, TTMLocationContext, TTMUserMapLocation, TTMWaypoint } from "../../utils/locationTypes";
import { buildNgsLocationInfoHeading, copyWithLocationInfo, getLocationInfo, getPoint } from "../../utils/location";
import { toFixedLngLat } from "../../utils/numbers";
import DistanceTime from "../map-page/DistanceTime";
import { PlannedRouteInformation } from "../../state/tree/map-page/planner/reducers";
import LocationActions from "../map-page/LocationActions";
import EvConnectorSection from "../map-page/EvConnector";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { ServiceUrls } from "../../state/tree/global-configuration/reducers";
import { formatDuration } from "../../utils/units";
import { chargeInKWhToPercentage } from "../../utils/chargingInformation";
import { RetryAction } from "../../state/tree/application/reducers";

export const locationPopup = bem("location-popup");
const locationPopupQa = qaId("location-popup");

export type HandleActionArg = {
    location: TTMLocation;
    actionSource: "map_contextual" | "map_rightclick";
    waypointIndex: number;
    forcedIndex?: number;
};

export type PopupLocation = {
    location: TTMLocation;
    locationFromSavedPlaces?: TTMUserMapLocation;
    title: string;
    subtitle?: string;
    waypointIndex?: number;
};

type Props = {
    popupLocation: PopupLocation;
    apiKey: string;
    serviceUrls: ServiceUrls;
    handleMouseEnter?: (ev: React.MouseEvent<HTMLDivElement>) => void;
    handleMouseLeave?: (ev: React.MouseEvent<HTMLDivElement>) => void;
    onOpenEditModal?: (point: [number, number]) => void;
    onSetRetryAction: (retryAction: RetryAction, point: [number, number]) => void;
    onRouteActionClick?: (arg: HandleActionArg) => void;
    filledWaypointsLength: number;
    plannerParams: ItineraryPlanningRequest;
    plannedRouteInfo: PlannedRouteInformation;
    activeRouteIndex: number;
    userLocation: [number, number];
    locationAccuracy: number;
    unitsType: UnitsType;
    waypoints: TTMWaypoint[];
    authenticated: boolean;
    onCardHeadingClick: (location) => void;
    onFavoriteClick: (location: TTMLocation) => void;
    onUnFavoriteClick: (location: TTMUserMapLocation) => void;
    remainingChargeAtArrivalInkWh: number;
    totalChargingTimeInSeconds: number;
    featureConfigs: { [key: string]: unknown; enableLDEV?: boolean };
    maxChargeInkWh: number;
};

const LocationDetailsPopupCmp = (props: Props) => {
    const {
        popupLocation,
        apiKey,
        serviceUrls,
        filledWaypointsLength,
        unitsType,
        locationAccuracy,
        userLocation,
        plannerParams,
        plannedRouteInfo,
        activeRouteIndex,
        onCardHeadingClick,
        onFavoriteClick,
        onUnFavoriteClick,
        remainingChargeAtArrivalInkWh,
        totalChargingTimeInSeconds,
        featureConfigs,
        maxChargeInkWh,
        onOpenEditModal,
        onSetRetryAction
    } = props;
    const { location, title, subtitle, waypointIndex, locationFromSavedPlaces } = popupLocation || {};
    const locationInfo = getLocationInfo(location);
    const { poiLocationInfo,  evChargingAvailability } = useFetchPoi(
        locationInfo?.externalID,
        locationInfo?.link,
        apiKey,
        serviceUrls
    );
    const eligibleLocationInfo = poiLocationInfo
        ? { ...poiLocationInfo, ...(locationInfo && { customName: locationInfo.customName }) }
        : locationInfo;
    const eligibleLocation = copyWithLocationInfo(location, eligibleLocationInfo);
    const heading = !subtitle && buildNgsLocationInfoHeading(eligibleLocationInfo, {});
    const latLngPoint = getPoint(location);
    const displayedTitle =
        heading?.title ||
        title ||
        (latLngPoint && toFixedLngLat(latLngPoint[0]).toString() + ", " + toFixedLngLat(latLngPoint[1]).toString()) ||
        "";
    const { t } = useTranslation("LocationDetailsPopup");

    let mouseHoveringPopup = false;
    const handleActionsMouseEnter = () => {
        mouseHoveringPopup = true;
    };
    const handleActionsMouseLeave = () => {
        mouseHoveringPopup = false;
    };

    return (
        <div
            className={classNames(locationPopup.element("content"))}
            onMouseEnter={props.handleMouseEnter}
            onMouseLeave={mouseHoveringPopup ? props.handleMouseLeave : null}
            {...locationPopupQa.block()}
        >
            <div className={locationPopup.element("info")}>
                <CardHeading
                    {...locationPopupQa.element("heading")}
                    className={locationPopup.element("title-row")}
                    title={displayedTitle}
                    subtitle={heading?.subtitle || subtitle}
                    small
                    noOverflow
                    isFavorite={Boolean(locationFromSavedPlaces)}
                    onFavoriteClick={() => !locationFromSavedPlaces && onFavoriteClick(eligibleLocation)}
                    onUnfavoriteClick={() => onUnFavoriteClick(locationFromSavedPlaces)}
                    onClick={() => onCardHeadingClick(eligibleLocation)}
                />

                <DistanceTime
                    filledWaypointsLength={filledWaypointsLength}
                    plannerParams={plannerParams}
                    plannedRouteInfo={plannedRouteInfo}
                    location={eligibleLocation}
                    userLngLat={userLocation}
                    locationAccuracy={locationAccuracy}
                    unitsType={unitsType}
                    activeRouteIndex={activeRouteIndex}
                />

                {evChargingAvailability && (
                    <EvConnectorSection
                        className={locationPopup.element("ev-connector")}
                        evPoi={evChargingAvailability}
                        compact
                    />
                )}

                {featureConfigs.enableLDEV && (!!remainingChargeAtArrivalInkWh || !!totalChargingTimeInSeconds) && (
                    <div className={locationPopup.element("charging-info")}>
                        <Body size="s">
                            {t("remaining_charge_on_arrival")}:{" "}
                            {chargeInKWhToPercentage(remainingChargeAtArrivalInkWh, maxChargeInkWh)}%
                        </Body>
                        {!!totalChargingTimeInSeconds && (
                            <Body size="s">
                                {t("total_charging_time")}: {formatDuration(totalChargingTimeInSeconds)}
                            </Body>
                        )}
                    </div>
                )}

                <LocationActions
                    filledWaypointsLength={filledWaypointsLength}
                    activeRouteIndex={activeRouteIndex}
                    routeFeatures={plannedRouteInfo.responseFeatures}
                    waypoints={props.waypoints}
                    authenticated={props.authenticated}
                    handleRouteAction={(forcedIndex) =>
                        props.onRouteActionClick({
                            location: eligibleLocation,
                            waypointIndex,
                            actionSource:
                                popupLocation.location.context === TTMLocationContext.MAP_POINT
                                    ? "map_rightclick"
                                    : "map_contextual",
                            forcedIndex
                        })
                    }
                    handleOpenEditModal={onOpenEditModal}
                    handleSetRetryAction={onSetRetryAction}
                    onMouseEnter={handleActionsMouseEnter}
                    onMouseLeave={handleActionsMouseLeave}
                    shownLocation={eligibleLocation}
                    featureConfigs={props.featureConfigs}
                    mode="popup"
                />
            </div>
        </div>
    );
};

const LocationDetailsPopup = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <LocationDetailsPopupCmp {...props} />
    </IsomorphicSuspense>
);

export default LocationDetailsPopup;
